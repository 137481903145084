define('rp-common/models/contact-request', ['exports', 'ember-data', 'rp-common/utils/model-utils', 'ember-cp-validations'], function (exports, _emberData, _modelUtils, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_EXPLANATION_LENGTH = undefined;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  var MAX_EXPLANATION_LENGTH = exports.MAX_EXPLANATION_LENGTH = 140;

  var Validations = (0, _emberCpValidations.buildValidations)({
    explanation_text: (0, _emberCpValidations.validator)('length', {
      allowBlank: false,
      allowNone: false,
      min: 1,
      max: MAX_EXPLANATION_LENGTH,
      message: function message(type, _value, options) {
        if (type === 'tooLong') {
          return 'Message cannot exceed  ' + options.max + ' characters.';
        }
        return 'Please include a message if you choose "Other".';
      },

      disabled: computed('model.{other,explanation_text}', function () {
        return !get(this, 'model.other') && !get(this, 'model.explanation_text');
      })
    }),

    reasonChosen: (0, _emberCpValidations.validator)('inclusion', {
      in: [true],
      dependentKeys: ['model.friend', 'model.seeking_for_advice', 'model.offering_support', 'model.other'],
      messageKey: 'contactRequests.newRequest.error.needReason', value: function value(model) {
        return get(model, 'friend') || get(model, 'seeking_for_advice') || get(model, 'offering_support') || get(model, 'other');
      }
    })
  });

  var ContactRequest = Model.extend(Validations, {
    sender: belongsTo('profile', { async: false }),
    receiver: belongsTo('profile', { async: false }),
    receivers: hasMany('profile', { async: false }),
    excludes: hasMany('profile', { async: false }),

    status: attr('string'),
    sent_at: attr('date'),
    friend: attr('boolean'),
    seeking_for_advice: attr('boolean'),
    offering_support: attr('boolean'),
    other: attr('boolean'),
    explanation_text: attr('string'),
    initiated_request: attr('boolean'),
    from_premium: attr('boolean'),
    include_all_pymk: attr('boolean'), // Used during creation in network builder

    pending: equal('status', 'sent'),
    accepted: equal('status', 'accepted'),
    ignored: equal('status', 'ignored')
  });

  ContactRequest.reopen({
    accept: function accept() {
      var _this = this;

      (0, _modelUtils.setAndCleanProperties)(this, { status: 'accepted' });
      var adapter = this.store.adapterFor('contact-request');
      return adapter.accept(get(this, 'id')).catch(function (err) {
        (0, _modelUtils.setAndCleanProperties)(_this, { status: 'pending' });
        throw err;
      });
    },
    ignore: function ignore() {
      var _this2 = this;

      (0, _modelUtils.setAndCleanProperties)(this, { status: 'ignored' });
      var adapter = this.store.adapterFor('contact-request');
      return adapter.ignore(get(this, 'id')).catch(function (err) {
        (0, _modelUtils.setAndCleanProperties)(_this2, { status: 'pending' });
        throw err;
      });
    }
  });

  exports.default = ContactRequest;
});