define('rp-common/models/list-feed-content', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PROFILE_MENTIONED = exports.YOU_WERE_MENTIONED = exports.PROFILE_PARTICIPATED = exports.PROFILE_POSTED = exports.OWN_POST_AND_GROUP = exports.CONTACT_AUTHOR_AND_GROUP_REASON = exports.CONTACT_PARTICIPANT_REASON = exports.GROUP_REASON = exports.CONTACT_AUTHOR_REASON = exports.OWN_POST_REASON = undefined;
  var computed = Ember.computed;
  var or = Ember.computed.or;
  var OWN_POST_REASON = exports.OWN_POST_REASON = 'own_post';
  var CONTACT_AUTHOR_REASON = exports.CONTACT_AUTHOR_REASON = 'contact_author';
  var GROUP_REASON = exports.GROUP_REASON = 'group';
  var CONTACT_PARTICIPANT_REASON = exports.CONTACT_PARTICIPANT_REASON = 'contact_participant';
  var CONTACT_AUTHOR_AND_GROUP_REASON = exports.CONTACT_AUTHOR_AND_GROUP_REASON = 'contact_author_and_group';
  var OWN_POST_AND_GROUP = exports.OWN_POST_AND_GROUP = 'own_post_and_group';
  var PROFILE_POSTED = exports.PROFILE_POSTED = 'profile_posted';
  var PROFILE_PARTICIPATED = exports.PROFILE_PARTICIPATED = 'profile_participated';
  var YOU_WERE_MENTIONED = exports.YOU_WERE_MENTIONED = 'mentioned';
  var PROFILE_MENTIONED = exports.PROFILE_MENTIONED = 'profile_mentioned';

  exports.default = _emberData.default.Model.extend({
    /*
     * Must implement:
     *
     * actionMessage
     * author
     * authorReason
     * createdAt
     * hasReason
     * responses
     */

    resource_centers: _emberData.default.attr(),
    hide_key: _emberData.default.attr(),

    isQuestionLinkUpdate: or('is_question', 'is_status_update', 'is_shared_link'),

    actionMessage: computed(function () {
      (false && Ember.warn('Models inheriting from list-feed-content must implement actionMessage'));

      return '';
    }),

    author: computed(function () {
      (false && Ember.warn('Models inheriting from list-feed-content must implement author'));

      return null;
    }),

    createdAt: computed(function () {
      (false && Ember.warn('Models inheriting from list-feed-content must implement createdAt'));

      return new Date();
    }),

    hasReason: computed(function () {
      (false && Ember.warn('Models inheriting from list-feed-content must implement hasReason'));

      return false;
    }),

    responses: computed(function () {
      (false && Ember.warn('Models inheriting from list-feed-content must implement responses'));

      return [];
    })
  });
});