define('rp-common/models/shared-link', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    // relationships
    pictures: hasMany('picture', { async: true }),
    tags: hasMany('tag', { async: true }),

    // attributes
    body: attr('string'),
    url: attr('string')
  });
});