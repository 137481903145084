define('rp-common/models/survey-option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // relationships
    survey: belongsTo('survey', {
      async: false
    }),

    // properties
    name: attr('string'),
    votes: attr('number'),

    isInvalidName: empty('name')
  });
});