define('rp-common/models/list-group-search-result', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    list_group: belongsTo('list-group', { async: true }),

    full_name: attr('string'),
    image: attr('string')
  });
});