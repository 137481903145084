define('rp-common/models/contact-request-ticket', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.APPROVED = undefined;
  var belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var APPROVED = exports.APPROVED = 'approved';

  exports.default = Model.extend({
    sender: belongsTo('profile'),
    receiver: belongsTo('profile'),
    approved: attr(),
    reason: attr()
  });
});