define('rp-common/utils/group-route-resolver', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.groupType = groupType;
  var isEqual = Ember.isEqual;
  var isEmpty = Ember.isEmpty;
  var AVAILABLE_GROUP_TYPES = exports.AVAILABLE_GROUP_TYPES = ['locations', 'positions', 'deployments', 'military-schools', 'professional-military-education', 'schools', 'organizations', 'groups', 'topics', 'languages', 'concentrations', 'specialties', 'specialities', 'affiliations', 'branches', 'units', 'ranks', 'certificates', 'academic-degrees', 'commissioning-sources', 'industries'];

  var GROUPABLE_ROUTE_MAP = exports.GROUPABLE_ROUTE_MAP = {
    'AcademicDegree': 'academic-degrees',
    'Branch': 'branches',
    'Certificate': 'certificates',
    'CommissioningSource': 'commissioning-sources',
    'Concentration': 'concentrations',
    'CustomGroup': 'groups',
    'DutyAssignment': 'positions',
    'DutyStation': 'locations',
    'Industry': 'industries',
    'Language': 'languages',
    'Location': 'deployments',
    'Organization': 'organizations',
    'ProfessionalDevelopmentSchool': 'professional-military-education',
    'Rank': 'ranks',
    'Speciality': 'specialties',
    'Specialty': 'specialties',
    'Affiliation': 'affiliations',
    'TacticalSchool': 'military-schools',
    'Topic': 'topics',
    'Tag': 'topics',
    'Unit': 'units'

  };

  var ROUTE_TO_MODEL_MAP = exports.ROUTE_TO_MODEL_MAP = {
    'locations': 'duty-station',
    'positions': 'duty-assignment',
    'deployments': 'location',
    'military-schools': 'tactical-school',
    'professional-military-education': 'professional-development-school',
    'organizations': 'unit',
    'groups': 'custom-group',
    'topics': 'tag',
    'languages': 'language',
    'concentrations': 'concentration',
    'specialties': 'speciality',
    'specialities': 'speciality',
    'affiliations': 'speciality',
    'branches': 'branch',
    'units': 'unit',
    'ranks': 'rank',
    'certificates': 'certificate',
    'academic-degrees': 'academic-degree',
    'commissioning-sources': 'commissioning-source',
    'industries': 'industry'
  };

  var GROUPABLE_TO_MODEL_MAP = exports.GROUPABLE_TO_MODEL_MAP = Object.keys(GROUPABLE_ROUTE_MAP).reduce(function (hash, groupable) {
    hash[groupable] = ROUTE_TO_MODEL_MAP[GROUPABLE_ROUTE_MAP[groupable]];
    return hash;
  }, {});

  var MEMBERS_TYPE_MAP = {
    'locations': ['current', 'previous', 'future'],
    'positions': ['current', 'previous'],
    'deployments': ['servicemembers', 'veterans'],
    'military-schools': ['servicemembers', 'veterans'],
    'professional-military-education': ['servicemembers', 'veterans'],
    'schools': ['servicemembers', 'veterans'],
    'organizations': ['current', 'previous', 'future'],
    'groups': [],
    'topics': [],
    'languages': ['servicemembers', 'veterans'],
    'concentrations': ['servicemembers', 'veterans'],
    'specialties': ['primary', 'additional'],
    'specialities': ['primary', 'additional'],
    'affiliations': ['primary', 'additional'],
    'branches': ['servicemembers', 'veterans'],
    'civilian-branches': ['civilians', 'servicemembers', 'veterans'], // to build proper memberTypes dropdown for civilian branch
    'units': ['current', 'previous', 'future'],
    'ranks': ['servicemembers', 'veterans'],
    'certificates': ['servicemembers', 'veterans'],
    'academic-degrees': ['servicemembers', 'veterans'],
    'commissioning-sources': ['servicemembers', 'veterans'],
    'industries': ['current', 'previous']
  };

  var TYPE_MEMBERS_MAP = {
    'servicemembers': 'Servicemembers',
    'veterans': 'Veterans',
    'civilians': 'Civilians',
    'current': 'Current Members',
    'previous': 'Past Members',
    'future': 'Future Members',
    'primary': 'Primary Specialty',
    'additional': 'Additional Specialty',
    'followers': 'All Followers'
  };

  // All groups have followers
  Object.keys(MEMBERS_TYPE_MAP).forEach(function (groupType) {
    return MEMBERS_TYPE_MAP[groupType].unshift('followers');
  });

  var USER_TYPE_MAP = {
    'civilians': 'civilian'
  };

  var BRANCH_TYPE = 'branches';
  var CIVILIAN_ID = 'civilian';
  var CIVILIAN_BRANCH_TYPE = 'civilian-branches';

  function groupType(groupableType) {
    return GROUPABLE_ROUTE_MAP[groupableType];
  }

  exports.default = {
    userTypeForApi: function userTypeForApi(userType) {
      return USER_TYPE_MAP[userType] ? USER_TYPE_MAP[userType] : userType;
    },
    isCivilianBranch: function isCivilianBranch(groupType, groupId) {
      return isEqual(groupType, BRANCH_TYPE) && isEqual(groupId, CIVILIAN_ID);
    },
    isValidGroup: function isValidGroup(groupType) {
      return groupType && AVAILABLE_GROUP_TYPES.includes(groupType);
    },
    getModelTypeByRoute: function getModelTypeByRoute(groupType) {
      (false && !(!isEmpty(ROUTE_TO_MODEL_MAP[groupType])) && Ember.assert('You must define a groupType or add proper mapping to ROUTE_TO_MODEL_MAP', !isEmpty(ROUTE_TO_MODEL_MAP[groupType])));

      return ROUTE_TO_MODEL_MAP[groupType];
    },
    getDefaultMemberType: function getDefaultMemberType(groupType) {
      var groupId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (this.isCivilianBranch(groupType, groupId)) {
        groupType = CIVILIAN_BRANCH_TYPE;
      }
      return MEMBERS_TYPE_MAP[groupType][0];
    },
    buildMembersTypes: function buildMembersTypes(groupType, groupId) {
      var isCivilianBranch = this.isCivilianBranch(groupType, groupId);
      var memberTypes = isCivilianBranch ? MEMBERS_TYPE_MAP[CIVILIAN_BRANCH_TYPE] : MEMBERS_TYPE_MAP[groupType];
      return memberTypes.map(function (type) {
        return { name: TYPE_MEMBERS_MAP[type], type: type };
      });
    }
  };
});