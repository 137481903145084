define('rp-common/services/unloader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({

    store: service(),

    unloadRecordWithRelationships: function unloadRecordWithRelationships(record) {
      if (record !== null) {
        var relationships = {};
        var hasRelationships = false;

        record.eachRelationship(function (name, _ref) {
          var kind = _ref.kind;

          hasRelationships = true;
          relationships[name] = {
            data: kind === 'hasMany' ? [] : null
          };
        });

        var id = record.get('id');
        var type = record.get('_internalModel.modelName');

        if (hasRelationships && id && type) {
          get(this, 'store').push({
            data: {
              type: type,
              id: id,
              relationships: relationships
            }
          });
        }

        record.unloadRecord();
      }
    },
    unloadRecordsWithRelationships: function unloadRecordsWithRelationships(records) {
      var _this = this;

      records.forEach(function (record) {
        _this.unloadRecordWithRelationships(record);
      });
    }
  });
});