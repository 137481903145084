define('rp-common/components/resource-center-header/component', ['exports', 'rp-common/components/resource-center-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var notEmpty = computed.notEmpty,
      readOnly = computed.readOnly;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['resource-center-header'],
    resourceCenterName: null,
    groupTag: null,

    sponsorText: readOnly('resourceCenterTag.sponsor_identifier'),
    isVisible: notEmpty('sponsorText'),

    routing: service(),
    store: service(),

    resourceCenterTag: computed('resourceCenterName', function () {
      var resourceCenterName = get(this, 'resourceCenterName');
      return get(this, 'store').findRecord('tag', resourceCenterName);
    }),

    resourceCenterBadge: computed('resourceCenterName', function () {
      return '/assets/images/resource_centers/icon-' + get(this, 'resourceCenterName') + '.svg';
    }),

    click: function click() {
      this.attrs.goToResourceCenter();
    }
  });
});