define('rp-common/serializers/user', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // @TODO temporary, data returned from server should be correct
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var tmp = payload;
      if (!!tmp.data.session && !!tmp.data.user) {
        tmp.data.session.id = 'current';
        tmp.data.session.user_id = tmp.data.user.id;
      }
      if (!!tmp.data.profile && !!tmp.data.user) {
        tmp.data.user.session_id = 'current';
      }

      return this._super(store, primaryModelClass, tmp, id, requestType);
    }
  });
});