define('rp-common/mixins/discussion-tracking-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var get = Ember.get;
  var DiscussionTrackingMixin = exports.DiscussionTrackingMixin = Mixin.create({
    qrcTracker: service('trackers/qrc'),

    infinityModelUpdated: function infinityModelUpdated(_ref) {
      var newObjects = _ref.newObjects;

      this._super.apply(this, arguments);

      if (!newObjects) {
        return;
      }

      (false && !(get(this, 'impressionLocation')) && Ember.assert('impressionLocation must be set for discussion-tracking-route mixin', get(this, 'impressionLocation')));


      var model = this._infinityModel();
      var baseIndex = model ? get(model, 'length') - get(newObjects, 'length') : 0;
      var positions = [];
      newObjects.forEach(function (discussion, index) {
        positions.push(baseIndex + index);
      });

      get(this, 'qrcTracker').trackImpressions(newObjects, get(this, 'impressionLocation'), positions);
    }
  });

  var FeedDiscussionTrackingMixin = exports.FeedDiscussionTrackingMixin = Mixin.create({
    qrcTracker: service('trackers/qrc'),

    infinityModelUpdated: function infinityModelUpdated() {
      this._super.apply(this, arguments);
      this.trackDiscussionImpressions.apply(this, arguments);
    },
    trackDiscussionImpressions: function trackDiscussionImpressions(_ref2) {
      var newObjects = _ref2.newObjects;

      if (!newObjects) {
        return;
      }

      (false && !(get(this, 'impressionLocation')) && Ember.assert('impressionLocation must be set for discussion-tracking-route mixin', get(this, 'impressionLocation')));


      var model = this._infinityModel();
      var baseIndex = model ? get(model, 'length') - get(newObjects, 'length') : 0;
      var discussions = [];
      var positions = [];
      newObjects.mapBy('list_feed_content').forEach(function (lfc, index) {
        if (get(lfc, 'isQuestionLinkUpdate')) {
          positions.push(baseIndex + index);
          discussions.push(lfc);
        }
      });

      get(this, 'qrcTracker').trackImpressions(discussions, get(this, 'impressionLocation'), positions);
    }
  });
});