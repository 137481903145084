define('rp-common/services/pending-login-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;


  var ACTION_VOTE = 'vote';
  var ACTION_COMMENT = 'comment';

  // Note: use deferredTransition service unless you need to save some data after the transition

  exports.default = Service.extend({
    localStorage: service(),
    currentUser: service(),
    votingService: service('voting'),
    flashMessages: service(),
    i18n: service(),
    store: service(),
    routing: service('-routing'),
    router: service(),
    flashMethod: 'success',
    flashMessage: null,

    actionsPending: notEmpty('localStorage.data.pendingAction'),

    processPendingSubmissions: function processPendingSubmissions() {
      var _this = this;

      var actionType = get(this, 'localStorage.data.pendingAction');
      var saveMethod = 'save' + actionType.capitalize();
      this[saveMethod]().then(function () {
        return _this.handlePendingActionSuccess(actionType);
      }).catch(function (err) {
        return _this.handlePendingActionError(err, actionType);
      }).finally(function () {
        return _this.redirectToOriginalUrl(actionType);
      });
    },
    handlePendingActionSuccess: function handlePendingActionSuccess(itemType) {
      if (itemType === ACTION_VOTE) {
        return; // handled in saveVote
      }
      set(this, 'flashMessage', get(this, 'i18n').t('votingLoginPrompt.' + itemType + 'SuccessMessage'));
    },
    handlePendingActionError: function handlePendingActionError(err, itemType) {
      if (itemType === ACTION_VOTE) {
        return; // handled in saveVote
      }
      Ember.Logger.error(err);
      set(this, 'flashMethod', 'danger');
      set(this, 'flashMessage', get(this, 'i18n').t('votingLoginPrompt.pendingActionFailureMessage', { itemType: itemType }));
    },
    redirectToOriginalUrl: function redirectToOriginalUrl(itemType) {
      var _this2 = this;

      var returnUrl = get(this, 'localStorage.data.pendingActionReturnUrl');
      this.clearStorage();
      get(this, 'router').transitionTo(returnUrl).then(function () {
        if (itemType === ACTION_VOTE) {
          return; // handled in saveVote
        }
        get(_this2, 'flashMessages')[get(_this2, 'flashMethod')](get(_this2, 'flashMessage'));
      });
    },
    addResponse: function addResponse(responseModel) {
      var ls = get(this, 'localStorage');
      ls.setItem('pendingAction', 'response');
      ls.setItem('pendingActionReturnUrl', get(this, 'routing.router.url'));
      ls.setItem('pendingBodyText', responseModel.get('body'));
      ls.setItem('pendingParentId', responseModel.get('parent.id'));
      ls.setItem('pendingParentType', responseModel.get('parent.content.constructor.modelName'));
    },
    addComment: function addComment(commentModel) {
      var ls = get(this, 'localStorage');
      ls.setItem('pendingAction', ACTION_COMMENT);
      ls.setItem('pendingActionReturnUrl', get(this, 'routing.router.url'));
      ls.setItem('pendingBodyText', commentModel.get('body'));
      ls.setItem('pendingParentId', commentModel.get('list_response.id'));
      ls.setItem('pendingParentType', 'list-response');
    },
    addVote: function addVote(votableModel, voteDirection) {
      var ls = get(this, 'localStorage');
      ls.setItem('pendingAction', ACTION_VOTE);
      ls.setItem('pendingActionReturnUrl', get(this, 'routing.router.url'));
      ls.setItem('pendingParentType', votableModel.get('constructor.modelName'));
      ls.setItem('pendingParentId', votableModel.get('id'));
      ls.setItem('pendingVoteDirection', voteDirection);
    },
    parentRecord: function parentRecord() {
      var parentType = get(this, 'localStorage.data.pendingParentType');
      var parentId = get(this, 'localStorage.data.pendingParentId');
      return get(this, 'store').peekRecord(parentType, parentId);
    },
    saveResponse: function saveResponse() {
      return get(this, 'store').createRecord('response', {
        profile: get(this, 'currentUser.profile'),
        body: get(this, 'localStorage.data.pendingBodyText'),
        parent: this.parentRecord()
      }).save();
    },
    saveComment: function saveComment() {
      return get(this, 'store').createRecord('comment', {
        profile: get(this, 'currentUser.profile'),
        body: get(this, 'localStorage.data.pendingBodyText'),
        list_response: this.parentRecord()
      }).save();
    },
    saveVote: function saveVote() {
      var _this3 = this;

      var direction = get(this, 'localStorage.data.pendingVoteDirection');
      var model = this.parentRecord();
      var isUnvoteAttempt = model.get(direction + '_voted');
      var voteMethod = isUnvoteAttempt ? 'unvote' : 'vote';
      return get(this, 'votingService')[voteMethod](model, direction).then(function (voted) {
        if (voted) {
          return get(_this3, 'votingService').reportVoteActionSuccess(isUnvoteAttempt, direction);
        }
      }).catch(function (errorMessages) {
        return get(_this3, 'votingService').reportVoteFailure(errorMessages);
      });
    },
    clearStorage: function clearStorage() {
      var ls = get(this, 'localStorage');
      ls.removeItem('pendingAction');
      ls.removeItem('pendingActionReturnUrl');
      ls.removeItem('pendingBodyText');
      ls.removeItem('pendingParentId');
      ls.removeItem('pendingParentType');
      ls.removeItem('pendingVoteDirection');
    }
  });
});