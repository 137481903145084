define('rp-common/models/hide-feed-item-ticket', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    list_feed_content_type: attr(),
    hide_key: attr()
  });
});