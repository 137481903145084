define('rp-common/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    list_profile: _emberData.default.belongsTo('list_profile'),
    common_connections: _emberData.default.hasMany('list_profile')
  });
});