define('ember-islands/components/ember-islands', ['exports', 'ember', 'ember-islands/utils/reconciler', 'ember-islands/utils/query-island-components', 'ember-islands/utils/get-render-component'], function (exports, _ember, _emberIslandsUtilsReconciler, _emberIslandsUtilsQueryIslandComponents, _emberIslandsUtilsGetRenderComponent) {
  exports.getInstance = getInstance;

  var eiInstance = undefined;

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var renderComponent = (0, _emberIslandsUtilsGetRenderComponent['default'])(this);
      this.reconciler = new _emberIslandsUtilsReconciler['default']({
        initialRender: function initialRender(c) {
          return renderComponent(c);
        },

        update: function update(c) {
          delete c.attrs.innerContent;
          c.instance.setProperties(c.attrs);
        },

        destroy: function destroy(c) {
          c.destroy();
        }
      });

      eiInstance = this;
    },

    reconcile: function reconcile() {
      this.reconciler.reconcileAgainst((0, _emberIslandsUtilsQueryIslandComponents['default'])());
    },

    getRenderedComponents: function getRenderedComponents() {
      return this.reconciler.renderedComponentsAsArray();
    },

    didInsertElement: function didInsertElement() {
      this.reconcile();
    },

    willDestroyElement: function willDestroyElement() {
      this.reconciler.forEachRenderedComponent(function (c) {
        return c.destroy();
      });
    }
  });

  function getInstance() {
    return eiInstance;
  }
});