define('rp-common/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var makeArray = Ember.makeArray;
  var get = Ember.get;
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    // only save dirty attributes
    serialize: function serialize(record) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.readOnly) {
        return;
      }
      var json = {};
      if (options && options.includeId) {
        if (record.id) {
          json[this.get('primaryKey')] = record.id;
        }
      }

      record.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          return _this.serializeBelongsTo(record, json, relationship);
        } else if (relationship.kind === 'hasMany') {
          return _this.serializeHasMany(record, json, relationship);
        }
      });

      record.eachAttribute(function (name) {
        if (record.attr(name) !== null) {
          json[name] = record.attr(name);
        }
      });

      return json;
    },
    extractMeta: function extractMeta(store, type, payload) {
      if (get(payload, 'data.meta')) {
        var meta = payload.data.meta;

        delete payload.data.meta;
        return meta;
      } else if (get(payload, 'meta')) {
        var _meta = payload.meta;

        delete payload.meta;
        if (_meta.length) {
          return _meta[0];
        } else {
          return _meta;
        }
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var errors = void 0;
      if (payload && payload.data) {
        if (payload.data.errors) {
          errors = payload.data.errors;
        }

        payload = payload.data;

        // this is to properly sideload belongsTo. Ember requires arrays
        for (var prop in payload) {
          var hash = payload[prop];
          payload[prop] = makeArray(hash);
        }

        if (errors) {
          payload.errors = errors;
        }

        if (payload.notifications) {
          var ugly = [];
          payload.notifications.forEach(function (notification) {
            if (!notification.id) {
              ugly.pushObject(notification);
            }
          });
          payload.notifications.removeObjects(ugly);
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});