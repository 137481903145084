define('rp-common/serializers/experience', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(model, hash, prop) {
      if (prop === 'experiences' && hash.start_month === 0) {
        hash.start_month = null;
      }
      if (prop === 'experiences' && hash.end_month === 0) {
        hash.end_month = null;
      }
      return this._super.apply(this, arguments);
    }
  });
});