define('rp-common/models/registration', ['exports', 'ember-data', 'rp-common/models/registration-attempt'], function (exports, _emberData, _registrationAttempt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _registrationAttempt.default.extend({
    // relationships
    temp_profile_photo: _emberData.default.belongsTo('temp-profile-photo', {
      async: false
    }),
    speciality: _emberData.default.belongsTo('speciality', {
      async: true
    }),
    branch: _emberData.default.belongsTo('branch', {
      async: true
    }),
    rank: _emberData.default.belongsTo('rank', {
      async: true
    }),

    // properties
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),

    social_provider: _emberData.default.attr('string'),
    social_uid: _emberData.default.attr('string'),
    social_token: _emberData.default.attr('string'),
    referrer_id: _emberData.default.attr('string'),
    invitation_token: _emberData.default.attr('string'),
    google_token: _emberData.default.attr('string'),
    facebook_token: _emberData.default.attr('string'),
    yahoo_token: _emberData.default.attr('string'),
    social_invitation_token: _emberData.default.attr('string'),

    // computed
    isCivilian: computed('military_affiliation', function () {
      return this.get('military_affiliation') === 'civilian';
    }),

    showBranches: computed('military_affiliation', function () {
      return this.get('military_affiliation') !== 'civilian';
    }),

    showRanks: computed('military_affiliation', function () {
      return this.get('military_affiliation') !== 'civilian';
    }),

    showSpecialities: computed('military_affiliation', function () {
      return this.get('military_affiliation') !== 'pre_commission';
    })
  });
});