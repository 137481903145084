define('rp-common/components/link-preview/link/component', ['exports', 'rp-common/components/link-preview/link/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['link-preview-link'],
    classNameBindings: ['hasPicture::no-image'],
    hasPicture: false,
    useLargePictures: true,
    linkData: null,
    linkSource: null,
    picture: null
  });
});