define('rp-common/components/salute-button/component', ['exports', 'ember-concurrency', 'rp-common/components/salute-button/template'], function (exports, _emberConcurrency, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  var run = Ember.run;


  var TOO_MANY_SALUTES_ERROR = 'wait back salute';
  var NOT_CONNECTED_ERROR = ' must be contact';

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['salute-button'],
    sender: null,
    receiver: null,
    noSpinner: false,
    cardId: null,

    store: service(),
    ui: service(),
    flashMessages: service(),
    i18n: service(),
    starburst: service(),

    saluteTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee(sender, receiver, cardId) {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return get(this, 'store').createRecord('salute', { sender: sender, receiver: receiver, cardId: cardId }).save();

            case 3:
              get(this, 'flashMessages').success(get(this, 'i18n').t('salute.success'));
              this.sendAction('finishSalute');
              return _context.abrupt('return', true);

            case 8:
              _context.prev = 8;
              _context.t0 = _context['catch'](0);

              this.sendAction('finishSalute');
              if (_context.t0 && get(_context.t0, 'errors.firstObject') === TOO_MANY_SALUTES_ERROR) {
                run.next(function () {
                  get(_this, 'ui').nativeAlert(get(_this, 'i18n').t('salute.errors.tooMany'));
                });
              } else if (_context.t0 && get(_context.t0, 'errors.firstObject') && get(_context.t0, 'errors.firstObject').receiver_id && get(_context.t0, 'errors.firstObject').receiver_id[0] === NOT_CONNECTED_ERROR) {
                run.next(function () {
                  get(_this, 'ui').nativeAlert(get(_this, 'i18n').t('salute.errors.notConnected'));
                });
              }
              return _context.abrupt('return', false);

            case 13:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 8]]);
    })),

    saluteAndAnimateTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee2(sender, receiver, cardId, animationTarget) {
      var _get;

      var success,
          _args2 = arguments;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.sendAction('startSaluteAndAnimate');
              _context2.next = 3;
              return (_get = get(this, 'saluteTask')).perform.apply(_get, _args2);

            case 3:
              success = _context2.sent;

              if (!success) {
                _context2.next = 8;
                break;
              }

              _context2.next = 7;
              return get(this, 'starburst').animate(animationTarget);

            case 7:
              this.sendAction('afterSuccessfulSaluteAnimation');

            case 8:
              this.sendAction('finishSaluteAndAnimate');

            case 9:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    click: function click(e) {
      var profile = get(this, 'currentUser.profile') || get(this, 'currentSession.profile');
      get(this, 'saluteAndAnimateTask').perform(profile, get(this, 'receiver'), get(this, 'cardId'), e.currentTarget);
      return false;
    }
  });
});