define('rp-common/helpers/time-ago-short', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  exports.default = helper(function (params) {
    var _params = _slicedToArray(params, 1),
        date = _params[0];

    if (Array.isArray(date)) {
      date = date[0];
    }

    if (!date) {
      return null;
    }

    var time = new Date().getTime() / 1000 - date.getTime() / 1000;

    if (time) {
      var minutes = time / 60;
      var hours = minutes / 60;
      var days = hours / 24;
      var months = days / 30;
      var years = days / 365;

      if (years >= 1) {
        return Math.abs(Math.floor(years)) + '  y';
      } else if (months >= 1) {
        return Math.abs(Math.floor(months)) + ' mo';
      } else if (days >= 1) {
        return Math.abs(Math.floor(days)) + ' d';
      } else if (hours >= 1) {
        minutes -= Math.abs(Math.floor(hours)) * 60;
        var minuteDecimal = '';
        if (minutes > 45) {
          hours++;
        } else if (minutes > 15) {
          minuteDecimal = '.5';
        }

        return Math.abs(Math.floor(hours)) + minuteDecimal + ' h';
      } else if (minutes >= 1) {
        return Math.abs(Math.floor(minutes)) + ' m';
      } else {
        return Math.abs(Math.floor(minutes)) + ' s';
      }
    } else {
      return null;
    }
  });
});