define('rp-common/services/follow-group', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    apiService: service(),

    follow: function follow(group) {
      get(this, 'setFollow').perform(group, 'follow');
    },
    unfollow: function unfollow(group) {
      get(this, 'setFollow').perform(group, 'unfollow');
    },


    setFollow: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee(group, action) {
      var apiService, groupId;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              apiService = get(this, 'apiService');
              // group_id allows this to work for tags.

              groupId = get(group, 'group_id') || get(group, 'id');
              _context.prev = 2;
              _context.next = 5;
              return apiService.postRequest('groups/' + groupId + '/' + action);

            case 5:
              group.set('following', action === 'follow');
              _context.next = 11;
              break;

            case 8:
              _context.prev = 8;
              _context.t0 = _context['catch'](2);

              this.reportError(_context.t0);

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 8]]);
    }))
  });
});