define('rp-common/services/sponsored-topic-ad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),

    getSponsors: function getSponsors(tagIds) {
      return get(this, 'store').query('sponsored-topic-ad', { group_ids: tagIds.join(',') });
    }
  });
});