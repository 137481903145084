define('rp-common/utils/ui-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var $ = Ember.$;


  // DEV WARNING: the functions disableBodyScroll and enableBodyScroll may not work properly
  // in a chrome emulator, but work properly on a real mobile device. Test on a real phone.
  function disableBodyScroll() {
    var $body = $('body');
    $body.css({ top: 0 - $body.scrollTop() }).addClass('noscroll');
    $(window).on('scroll.disable-body-scroll', function () {
      return debounce($body, 'scrollTop', 0, 150);
    });
  }

  function enableBodyScroll() {
    var resumePosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    var $body = $('body');
    var scrollTop = 0 - $body.offset().top;
    $body.css({ top: '' }).removeClass('noscroll');
    $(window).off('scroll.disable-body-scroll');

    if (resumePosition) {
      $body.scrollTop(scrollTop);
    }
  }

  exports.disableBodyScroll = disableBodyScroll;
  exports.enableBodyScroll = enableBodyScroll;
});