define('rp-common/mixins/models/picturable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    mainPicture: alias('pictures.firstObject'),

    previewPictures: computed('pictures.[]', function () {
      return this.get('pictures').slice(1);
    })
  });
});