define('rp-common/components/user-type-badge/component', ['exports', 'rp-common/components/user-type-badge/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['user-type-badge'],
    classNameBindings: ['isCivilian', 'isServicemember', 'isRetired', 'isVeteran', 'isVerified', 'isLoaded::hide'],
    tagName: 'span',
    listProfile: null,

    isCivilian: readOnly('listProfile.isCivilian'),
    isLoaded: readOnly('listProfile.isLoaded'),
    isServicemember: readOnly('listProfile.isServicemember'),
    isRetired: readOnly('listProfile.is_retired'),
    isVeteran: readOnly('listProfile.isVeteran'),
    isVerified: readOnly('listProfile.verified')
  });
});