define('rp-common/components/gallery-image/component', ['exports', 'rp-common/components/gallery-image/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var htmlSafe = Ember.String.htmlSafe;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-image'],
    attributeBindings: ['style'],
    picture: null,
    large: false,

    src: computed('picture.{url_large,url}', 'large', function () {
      return get(this, 'large') ? get(this, 'picture.url_large') : get(this, 'picture.url');
    }),

    style: computed('src', function () {
      return htmlSafe('background-image: url(\'' + this.get('src') + '\');');
    })
  });
});