define('rp-common/services/experiment-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;

  var ENTRY_MISSMATCH_ASSERT = '# Entries in groups should match # entries in allotment';
  var TOTAL_NOT_100_ASSERT = 'Total of a weighted experiment allotment must always equal 100';
  var NOT_ENOUGH_GROUPS_ASSERT = 'There are not enough groups. You need at least 2';

  exports.default = Service.extend({
    weightedInteger: function weightedInteger(allotment, groups) {
      var allotmentKeys = Object.keys(allotment);
      (false && !(groups.length > 1) && Ember.assert(NOT_ENOUGH_GROUPS_ASSERT, groups.length > 1));
      (false && !(allotmentKeys.length === groups.length) && Ember.assert(ENTRY_MISSMATCH_ASSERT, allotmentKeys.length === groups.length));

      var allotmentTotal = allotmentKeys.map(function (k) {
        return allotment[k];
      }).reduce(function (acc, val) {
        return acc + val;
      });
      (false && !(allotmentTotal === 100) && Ember.assert(TOTAL_NOT_100_ASSERT, allotmentTotal === 100));

      var num = Math.floor(Math.random() * 100);
      var total = 0;
      for (var i = 0; i < groups.length; i++) {
        var allotmentName = allotmentKeys[i];
        total += allotment[allotmentName];
        if (total > num) {
          return i;
        }
      }
    },
    randInteger: function randInteger(min, max) {
      (false && !(max > 0) && Ember.assert(NOT_ENOUGH_GROUPS_ASSERT, max > 0));

      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  });
});