define('ember-lifeline/dom-event-listeners', ['exports', 'ember-lifeline/utils/disposable'], function (exports, _disposable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addEventListener = addEventListener;
  exports.removeEventListener = removeEventListener;
  var WeakMap = Ember.WeakMap;


  /**
   * A map of instances/listeners that allows us to
   * store listener references per instance.
   *
   * @private
   *
   */
  var eventListeners = new WeakMap();

  var PASSIVE_SUPPORTED = function () {
    var ret = false;

    try {
      var options = Object.defineProperty({}, 'passive', {
        get: function get() {
          ret = true;
        }
      });

      window.addEventListener('test', null, options);
    } catch (err) {
      // intentionally empty
    }
    return ret;
  }();

  var LISTENER_ITEM_LENGTH = 5;
  var INDEX = {
    ELEMENT: 0,
    EVENT_NAME: 1,
    CALLBACK: 2,
    ORIGINAL_CALLBACK: 3,
    OPTIONS: 4
  };

  /**
     Attaches an event listener that will automatically be removed when the host
     object is dropped from DOM.
  
     Example:
  
     ```js
     import Component from 'ember-component';
     import { addEventListener } from 'ember-lifeline';
  
     export default Component.extend({
       didInsertElement() {
         addEventListener(this, '.some-item', 'click', (e) => {
           console.log('.some-item was clicked');
         });
       }
     });
     ```
  
     This can also be used in other ember types like services and controllers. In
     order to use it there an html element reference must be used instead of a
     css selector. This way we can be sure the element actually exists when the
     listener is attached:
  
     ```js
     import Service from 'ember-service';
     import { addEventListener } from 'ember-lifeline';
  
     export default Service.extend({
       init() {
         this._super(...arguments);
         const el = document.querySelector('.foo');
         addEventListener(this, el, 'click')
       }
     });
     ```
  
     @method addEventListener
     @param { Object } obj the instance to attach the listener for
     @param { String } selector the DOM selector or element
     @param { String } eventName the event name to listen for
     @param { Function } callback the callback to run for that event
     @public
     */
  function addEventListener(obj, element, eventName, callback, options) {
    assertArguments(element, eventName, callback);

    var _callback = Ember.run.bind(obj, callback);
    var listeners = eventListeners.get(obj);

    if (listeners === undefined) {
      listeners = [];
      eventListeners.set(obj, listeners);
    }

    // Register a disposable every time we go from zero to one.
    if (listeners.length === 0) {
      (0, _disposable.registerDisposable)(obj, getEventListenersDisposable(listeners));
    }

    if (!PASSIVE_SUPPORTED) {
      options = undefined;
    }

    element.addEventListener(eventName, _callback, options);
    listeners.push(element, eventName, _callback, callback, options);
  }

  /**
     @param { Object } obj the instance to remove the listener for
     @param { String } selector the DOM selector or element
     @param { String } eventName the event name to listen for
     @param { Function } callback the callback to run for that event
     @public
     */
  function removeEventListener(obj, element, eventName, callback, options) {
    assertArguments(element, eventName, callback);

    var listeners = eventListeners.get(obj);

    if (listeners === undefined || listeners.length === 0) {
      return;
    }

    if (!PASSIVE_SUPPORTED) {
      options = undefined;
    }

    // We cannot use Array.findIndex as we cannot rely on babel/polyfill being present
    for (var i = 0; i < listeners.length; i += LISTENER_ITEM_LENGTH) {
      if (listeners[i + INDEX.ELEMENT] === element && listeners[i + INDEX.EVENT_NAME] === eventName && listeners[i + INDEX.ORIGINAL_CALLBACK] === callback) {
        /*
           * Drop the event listener and remove the listener object
           */
        var ownCallback = listeners[i + INDEX.CALLBACK];
        element.removeEventListener(eventName, ownCallback, options);
        listeners.splice(i, LISTENER_ITEM_LENGTH);
        break;
      }
    }
  }

  function assertArguments(element, eventName, callback) {
    (false && !(!!element) && Ember.assert('Must provide a DOM element', !!element));
    (false && !(typeof element !== 'string' && typeof element !== 'function' && element !== null && element !== undefined) && Ember.assert('Must provide an element (not a DOM selector)', typeof element !== 'string' && typeof element !== 'function' && element !== null && element !== undefined));
    (false && !(!!eventName) && Ember.assert('Must provide an eventName that specifies the event type', !!eventName));
    (false && !(!!callback) && Ember.assert('Must provide a callback to run for the given event name', !!callback));
  }

  function getEventListenersDisposable(listeners) {
    return function () {
      if (listeners !== undefined) {
        /* Drop non-passive event listeners */
        for (var i = 0; i < listeners.length; i += LISTENER_ITEM_LENGTH) {
          var element = listeners[i + INDEX.ELEMENT];
          var eventName = listeners[i + INDEX.EVENT_NAME];
          var callback = listeners[i + INDEX.CALLBACK];
          var options = listeners[i + INDEX.OPTIONS];

          element.removeEventListener(eventName, callback, options);
        }
        listeners.length = 0;
      }
    };
  }
});