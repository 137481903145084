define('rp-common/components/loading-indicator/component', ['exports', 'rp-common/components/loading-indicator/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['loading-indicator']
  });
});