define('rp-common/models/rank', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;


  var RANK_TYPES = ['Enlisted', 'Warrant', 'Officer', 'Pre-Commission', 'Civilian'];

  exports.default = _groupable.default.extend({
    branch: belongsTo('branch', { async: false }),
    specialities: hasMany('speciality', { async: false }),
    current_promotions: hasMany('current-promotion', { async: false }),

    pay_grade: attr('string'),
    rank_type: attr('number'),
    image_url: attr('string'),

    gradeAndName: computed('pay_grade', 'name', function () {
      var paygrade = get(this, 'pay_grade');
      var name = get(this, 'name');
      return paygrade && name ? paygrade + ' - ' + name : paygrade || name;
    }),

    rankTypeName: computed('rank_type', function () {
      return RANK_TYPES[get(this, 'rank_type')];
    })
  });
});