define('rp-common/mixins/voting', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var isEmpty = Ember.isEmpty;
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    votingDisabled: false,

    ui: service(),
    voting: service(),
    permission: service(),
    currentUser: service(),
    i18n: service(),
    singleActionButtonVisibility: service(),
    pendingLoginActions: service(),

    upVoted: alias('voteableModel.up_voted'),
    upVoteCount: alias('voteableModel.up_votes'),
    downVoted: alias('voteableModel.down_voted'),
    downVoteCount: alias('voteableModel.down_votes'),
    netVotes: alias('voteableModel.net_votes'),
    votesCount: alias('voteableModel.votes_count'),
    blocked: readOnly('voteableProfile.blocked'),

    netVotesValue: computed('netVotes', function () {
      return isEmpty(get(this, 'netVotes')) ? 0 : get(this, 'netVotes');
    }),

    updateVotesCount: function updateVotesCount(action) {
      var castingVote = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var isUp = action === 'up';
      var isDown = action === 'down';

      if (castingVote) {
        this.incrementProperty('votesCount');

        if (isUp) {
          this.incrementProperty('netVotes');
          this.incrementProperty('upVoteCount');
          set(this, 'downVoted', false);
        } else if (isDown) {
          this.decrementProperty('netVotes');
          this.incrementProperty('downVoteCount');
          set(this, 'upVoted', false);
        }
      } else {
        this.decrementProperty('votesCount');

        if (isUp) {
          this.decrementProperty('netVotes');
          this.decrementProperty('upVoteCount');
        } else if (isDown) {
          this.incrementProperty('netVotes');
          this.decrementProperty('downVoteCount');
        }
      }
    },


    voteTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee(action) {
      var votedProperty, isUnvoteAttempt, model, ui, needsConfirm, errorMessage, voted;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              votedProperty = action + 'Voted';
              isUnvoteAttempt = get(this, votedProperty);
              model = get(this, 'voteableModel');
              ui = get(this, 'ui');
              (false && !('You must pass in a `voteableModel` to your vote component') && Ember.assert(model === null, 'You must pass in a `voteableModel` to your vote component'));

              if (!get(this, 'votingDisabled')) {
                _context.next = 7;
                break;
              }

              return _context.abrupt('return');

            case 7:
              if (!get(this, 'blocked')) {
                _context.next = 10;
                break;
              }

              ui.nativeAlert(get(this, 'i18n').t('blocking.universal'));
              return _context.abrupt('return');

            case 10:
              needsConfirm = action === 'down' && !isUnvoteAttempt;
              _context.prev = 11;
              _context.next = 14;
              return RSVP.resolve(needsConfirm && get(this, 'voting').confirmDownvote());

            case 14:
              _context.next = 19;
              break;

            case 16:
              _context.prev = 16;
              _context.t0 = _context['catch'](11);
              return _context.abrupt('return');

            case 19:
              if (get(this, 'currentUser.isSignedIn')) {
                _context.next = 24;
                break;
              }

              get(this, 'singleActionVisibility').hide();
              get(this, 'pendingLoginActions').addVote(model, action);
              this.send('didAddVoteForLater');
              return _context.abrupt('return');

            case 24:
              _context.prev = 24;

              if (!(action === 'down' && !get(this, 'permission.toDownVote'))) {
                _context.next = 29;
                break;
              }

              errorMessage = get(this, 'permission.downVoteReputationError');

              ui.nativeAlert(errorMessage);
              return _context.abrupt('return');

            case 29:
              set(this, 'votingDisabled', true);
              // Change the vote icon immediatly
              this.toggleProperty(votedProperty);
              voted = void 0;

              if (!isUnvoteAttempt) {
                _context.next = 38;
                break;
              }

              this.updateVotesCount(action, false);
              _context.next = 36;
              return voted = get(this, 'voting').unvote(model);

            case 36:
              _context.next = 41;
              break;

            case 38:
              this.updateVotesCount(action);
              _context.next = 41;
              return voted = get(this, 'voting').vote(model, action);

            case 41:
              if (voted) {
                get(this, 'voting').reportVoteActionSuccess(isUnvoteAttempt, action);
              }
              _context.next = 49;
              break;

            case 44:
              _context.prev = 44;
              _context.t1 = _context['catch'](24);

              get(this, 'voting').reportVoteFailure(_context.t1);
              this.updateVotesCount(action, isUnvoteAttempt);
              this.toggleProperty(votedProperty);

            case 49:
              _context.prev = 49;

              set(this, 'votingDisabled', false);
              return _context.finish(49);

            case 52:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[11, 16], [24, 44, 49, 52]]);
    })),

    actions: {
      vote: function vote(action) {
        // The extra `then()` just to be extra type-safe (converts TaskInstance to a Promise).
        // VoteTracking consumes this and expects a promise.
        return get(this, 'voteTask').perform(action).then();
      }
    }
  });
});