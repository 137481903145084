define('rp-common/components/feed-author/component', ['exports', 'rp-common/components/feed-author/template', 'rp-common/utils/utils-base'], function (exports, _template, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-author', 'active-feedback', 'feedback-row'],
    classNameBindings: ['smallSize:small-image'],
    listProfile: null,
    reasonLastAt: null,
    actionMessage: '',
    size: 'large',
    views: 0,
    hideBranchSpeciality: false,
    showTimestamp: false,

    smallSize: equal('size', 'small'),

    branchSpecialityAbbr: computed('listProfile.{isCivilian,branchAbbr,civilianSpecialityAbbr}', function () {
      return this.get('listProfile.isCivilian') ? this.get('listProfile.civilianSpecialityAbbr') : this.get('listProfile.branchAbbr');
    }),

    viewsCountFormat: computed('views', function () {
      return _utilsBase.default.formatCount(this.get('views'));
    }),

    click: function click() {
      this.sendAction('goToAuthor');
    }
  });
});