define('rp-common/services/confetti-machine', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var set = Ember.set;
  var get = Ember.get;


  var CONFETTI_DURATION = Ember.testing ? 10 : 2000;
  var CONFETTI_FADE_DURATION = Ember.testing ? 10 : 2000;

  exports.default = Service.extend({
    isShootingConfetti: false,
    isConfettiFading: false,

    shootConfettiTask: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              set(this, 'isShootingConfetti', true);
              set(this, 'isConfettiFading', false);
              _context.next = 4;
              return (0, _emberConcurrency.timeout)(CONFETTI_DURATION);

            case 4:
              set(this, 'isConfettiFading', true);
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(CONFETTI_FADE_DURATION);

            case 7:
              set(this, 'isShootingConfetti', false);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    shootConfetti: function shootConfetti() {
      get(this, 'shootConfettiTask').perform();
    }
  });
});