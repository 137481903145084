define('rp-common/models/list-job', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    full_job: belongsTo('full-job', { async: true }),

    slug: attr('string'),
    position: attr('string'),
    company_name: attr('string'),
    company_slug: attr('string'),
    civilian_company_id: attr('number'),
    one_click_intro: attr('boolean'),
    location_name: attr('string'),
    destination_url: attr('string'),
    image: attr('string'),
    created_at: attr('date'),
    location_latitude: attr('number'),
    location_longitude: attr('number'),
    contact_type: attr('string'),
    new_job: attr('boolean'),
    military_employee_count: attr('number'),

    isEmail: equal('contact_type', 'email'),
    isLink: equal('contact_type', 'url'),

    jobUrl: computed('job.{company_slug,slug,destination_url,isEmail}', function () {
      if (get(this, 'isEmail')) {
        return '/organizations/' + get(this, 'company_slug') + '/jobs/' + get(this, 'slug');
      }
      return get(this, 'destination_url');
    }),

    membersUrl: computed('job.company_slug', function () {
      return '/organizations/' + get(this, 'company_slug') + '/followers';
    })
  });
});