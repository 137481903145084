define('rp-common/models/conversation', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;


  var Validations = (0, _emberCpValidations.buildValidations)({
    initialMessageBody: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      messageKey: 'contacts.messageUser.missingMessageBody' // ember-i18n-cp-validations
    }),
    subject: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      messageKey: 'contacts.messageUser.missingMessageSubject' // ember-i18n-cp-validations
    })
  });

  exports.default = Model.extend(Validations, {
    messages: hasMany('message', { async: true }),
    profile: belongsTo('profile', { async: false }),
    all_recipients: hasMany('profile', { async: true }),
    initialMessageBody: attr(),
    initialRecipients: attr(),
    subject: attr('string'),
    unread: attr('boolean'),
    updated_at: attr('date'),
    hash: attr('string'),

    recipientsExceptCurrent: computed('all_recipients.[]', function () {
      var filtered = get(this, 'all_recipients');
      if (filtered.get('length') > 0) {
        var currentProfileId = get(this, 'currentUser.profile.id');
        filtered = filtered.filter(function (record) {
          return record.get('id') !== currentProfileId;
        });
      }
      return filtered;
    }),

    withPremium: computed('recipientsExceptCurrent', function () {
      var rec = get(this, 'recipientsExceptCurrent');
      return get(rec, 'length') === 1 && get(rec, 'firstObject.user_premium');
    })
  });
});