define('rp-common/models/warning', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    comments: [(0, _emberCpValidations.validator)('presence', { presence: true, message: 'Please provide a comment' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 2000, message: 'Comment is too long (maximum is 2000 characters)' })],
    profile_id: (0, _emberCpValidations.validator)('presence', true),
    profile_name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = Model.extend(Validations, {
    profile_id: attr('number'),
    profile_name: attr('string'),
    misrepresenting: attr('boolean'),
    different_unit: attr('boolean'),
    non_eligible: attr('boolean'),
    bad_messages: attr('boolean'),
    comments: attr('string')
  });
});