define('rp-common/components/raise-a-glass-button/component', ['exports', 'rp-common/components/raise-a-glass-button/template', 'ember-concurrency'], function (exports, _template, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['raise-glass-button-wrapper'],
    layout: _template.default,
    profile: null,
    sender: null,
    isSignedIn: false,

    flashMessages: service(),
    confettiMachine: service(),
    i18n: service(),
    store: service(),

    raiseAGlass: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var unsavedPoke;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;

              if (get(this, 'isSignedIn')) {
                _context.next = 8;
                break;
              }

              if (!get(this, 'loginRescue')) {
                _context.next = 7;
                break;
              }

              this.sendAction('loginRescue');
              return _context.abrupt('return');

            case 7:
              throw new Error('Unauthenticated user tried to raise a glass with no loginRescue action provided');

            case 8:
              unsavedPoke = get(this, 'store').createRecord('poke', {
                sender: get(this, 'sender'),
                receiver: get(this, 'profile')
              });
              _context.next = 11;
              return unsavedPoke.save();

            case 11:
              get(this, 'flashMessages').success(get(this, 'i18n').t('raiseAGlass.button.success', {
                name: get(this, 'profile.fullNameAndRank')
              }));
              get(this, 'confettiMachine').shootConfetti();
              _context.next = 19;
              break;

            case 15:
              _context.prev = 15;
              _context.t0 = _context['catch'](0);

              this.logError(_context.t0);
              return _context.abrupt('return', get(this, 'flashMessages').danger(get(this, 'i18n').t('general.error.unknown')));

            case 19:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 15]]);
    })),

    click: function click() {
      get(this, 'raiseAGlass').perform();
      return false;
    }
  });
});