define('rp-common/models/list-feed-profile-birthday', ['exports', 'ember-data', 'rp-common/models/auto-update-root'], function (exports, _emberData, _autoUpdateRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _autoUpdateRoot.default.extend({
    feedComponentName: 'feed-card-happy-birthday',
    profile_ids: _emberData.default.hasMany('profile'), // array with one item
    profile: alias('profile_ids.firstObject')
  });
});