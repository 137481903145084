define('rp-common/models/list-qrc-root', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    sponsored_post: attr('boolean', { defaultValue: false }),
    sponsored_post_url: attr('string'),
    sponsored_post_cta: attr('string'),
    sponsored_post_group: belongsTo('list-group', { async: true }),
    sponsored_post_tracking_pixels: attr(),
    sponsored_post_show_display_ads: attr('boolean', { defaultValue: false }),
    sponsored_post_banner_code: attr('string'),
    sponsored_post_banner_code_type: attr('number'),
    engagement_allowed: attr('boolean'),

    hideDisplayAds: computed('sponsored_post', 'sponsored_post_show_display_ads', function () {
      return get(this, 'sponsored_post') ? !get(this, 'sponsored_post_show_display_ads') : false;
    })
  });
});