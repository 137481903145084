define('rp-common/experiments/base-experiment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  var EXPERIMENT_PREFIX = exports.EXPERIMENT_PREFIX = 'rp-exp';
  var EXPERIMENT_REGEX = exports.EXPERIMENT_REGEX = new RegExp('^' + EXPERIMENT_PREFIX);

  exports.default = EmberObject.extend({
    experimentId: null,
    allotment: null,
    localStorage: service(),
    experimentLookup: service(),
    experimentSelection: service(),

    init: function init() {
      this._super.apply(this, arguments);
      // Control is REQUIRED
      this.groups = ['control', 'variation'];
    },


    lsKey: computed('experimentId', function () {
      var experimentId = get(this, 'experimentId');
      return EXPERIMENT_PREFIX + '-' + experimentId;
    }),

    // Default splits between groups evenly
    group: computed('groups.[]', 'storedGroup', function () {
      (false && !(get(this, 'groups').includes('control')) && Ember.assert('Groups must have a control', get(this, 'groups').includes('control')));

      if (get(this, 'excludedFromExperiment')) {
        return 'control';
      }
      var storedGroup = get(this, 'storedGroup');
      return storedGroup || this.addToGroup();
    }),

    excludedFromExperiment: computed(function () {
      var config = getOwner(this).resolveRegistration('environment:config');
      return get(config, 'APP.APP_MODE') === 'app' || get(config, 'environment') === 'test';
    }),

    removeStaleExperimentsFromLocalStorage: function removeStaleExperimentsFromLocalStorage() {
      var _this = this;

      // Delete all existing experiments that don't exist in the container.
      var ls = get(this, 'localStorage');
      Object.keys(ls.get('localStore')).forEach(function (key) {
        if (EXPERIMENT_REGEX.test(key)) {
          if (!get(_this, 'experimentLookup').lookupFromLsKey(key)) {
            ls.removeItem(key);
          }
        }
      });
    },
    addToGroup: function addToGroup() {
      var ls = get(this, 'localStorage');
      var allotment = get(this, 'allotment');
      var groups = get(this, 'groups');
      var numGroups = get(this, 'groups.length');
      var selection = get(this, 'experimentSelection');

      (false && !(groups.includes('control')) && Ember.assert('One of the experiment groups has to be control', groups.includes('control')));
      (false && !(numGroups > 1) && Ember.assert('There are at least two groups', numGroups > 1));


      var key = allotment ? selection.weightedInteger(allotment, groups) : selection.randInteger(0, numGroups - 1);
      var group = get(this, 'groups')[key];
      var lsKey = get(this, 'lsKey');

      this.removeStaleExperimentsFromLocalStorage();
      ls.setItem(lsKey, group);
      return ls.getItem(lsKey);
    },


    storedGroup: computed('localStorage.data', function () {
      var ls = get(this, 'localStorage.data');
      var lsKey = get(this, 'lsKey');

      return ls.get(lsKey);
    })
  });
});