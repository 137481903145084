define('rp-common/models/full-comment', ['exports', 'ember-data', 'rp-common/mixins/models/picturable'], function (exports, _emberData, _picturable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FullComment = _emberData.default.Model.extend(_picturable.default, {
    list_response: _emberData.default.belongsTo('list-response', { async: true }),
    pictures: _emberData.default.hasMany('picture', { async: true }),

    body: _emberData.default.attr('string'),
    link_data: _emberData.default.attr(),
    up_voted: _emberData.default.attr('boolean'),
    down_voted: _emberData.default.attr('boolean'),
    net_votes: _emberData.default.attr('number'),
    up_votes: _emberData.default.attr('number'),
    down_votes: _emberData.default.attr('number'),
    high_risk: _emberData.default.attr('boolean'),
    author: _emberData.default.attr('boolean')
  });

  FullComment.reopenClass({
    clearEmpty: function clearEmpty(store) {
      store.peekAll('comment').forEach(function (comment) {
        if (comment.get('isNew')) {
          comment.unloadRecord();
        }
      });
    }
  });

  exports.default = FullComment;
});