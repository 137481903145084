define('rp-common/services/image-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Service = Ember.Service;
  var defer = Ember.RSVP.defer;
  var get = Ember.get;
  var service = Ember.inject.service;


  var possibleSizes = ['@1x', '@2x', '@3x'];

  exports.default = Service.extend({
    // Load an image url in the background. Returns promise for an HTMLImageElement.
    // Use cases include when image dimensions are needed for layout or a spinner
    // is needed while image is loading.
    assetMap: service('asset-map'),
    environment: service(),

    loadInBackground: function loadInBackground(url) {
      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var img = new Image();
      var imgLoadDeferred = defer();
      img.onload = function (e) {
        imgLoadDeferred.resolve(e.currentTarget);
      };
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(attributes)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          img.setAttribute(key, value);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      img.src = url;
      return imgLoadDeferred.promise;
    },
    loadSrcsetInBackground: function loadSrcsetInBackground(url) {
      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var img = new Image();
      var imgLoadDeferred = defer();
      img.onload = function (e) {
        imgLoadDeferred.resolve(e.currentTarget);
      };
      var srcset = this.srcset(url);
      img.srcset = srcset;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.entries(attributes)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _ref3 = _step2.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var value = _ref4[1];

          img.setAttribute(key, value);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      img.src = srcset[0].split(' 1x')[0]; // fallback for browsers that don't support srcset, like, you guessed it - IE
      return imgLoadDeferred.promise;
    },
    srcset: function srcset(url) {
      var _this = this;

      var ext = url.substr(url.lastIndexOf('.'));
      return possibleSizes.map(function (size) {
        if (get(_this, 'environment.isNativeApp') === 'app') {
          return url.replace(ext, '' + size + ext) + ' ' + size.substr(1);
        }
        return get(_this, 'assetMap').resolve(url.replace(ext, '' + size + ext)) + ' ' + size.substr(1);
      });
    }
  });
});