define('rp-common/mixins/seo-data-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var $ = Ember.$;
  exports.default = Mixin.create({
    seoDataBuilder: service(),
    headTagsService: service('head-tags'),

    setTitleTag: function setTitleTag(model) {
      return get(this, 'seoDataBuilder').getTitle(model).then(function (seoTitleTag) {
        return {
          type: 'title',
          content: seoTitleTag ? seoTitleTag : 'RallyPoint - The Military Network',
          tagId: 'title-tag'
        };
      }).catch(function () {});
    },
    setMetaDescription: function setMetaDescription(model) {
      return get(this, 'seoDataBuilder').getMetaDescription(model).then(function (metaDescription) {
        return {
          type: 'meta',
          tagId: 'meta-description-tag',
          attrs: {
            name: 'description',
            content: metaDescription
          }
        };
      }).catch(function () {});
    },
    setStructuredData: function setStructuredData(model) {
      return get(this, 'seoDataBuilder').getStructuredData(model).then(function (structuredData) {
        return {
          type: 'script',
          tagId: 'structured-data-tag',
          content: structuredData,
          attrs: {
            type: 'application/ld+json'
          }
        };
      }).catch(function () {});
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isSignedIn')) {
        // only bots need this
        return this.setSeoData(model);
      }
    },
    setSeoData: function setSeoData(model) {
      var _this = this;

      return RSVP.all([this.setTitleTag(model), this.setMetaDescription(model), this.setStructuredData(model)]).then(function (headTags) {
        // remove title manually for now since we don't want to change the titles on the rest of the app
        // eventually we will set headTags on the application route
        $('title').remove(); // existing default title, replace it with the generated one
        set(_this, 'headTags', headTags.filter(function (t) {
          return t !== undefined;
        })); // filter out any SEO data that is not defined in the model
        get(_this, 'headTagsService').collectHeadTags();
      });
    }
  });
});