define('rp-common/components/server-maintenance-splash/component', ['exports', 'rp-common/components/server-maintenance-splash/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['server-maintenance-splash'],

    serverMaintenance: service(),

    actions: {
      reloadApplication: function reloadApplication() {
        this.get('serverMaintenance').serverUp();
        this.sendAction('reload');
      }
    }
  });
});