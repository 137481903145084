define('rp-common/instance-initializers/error-handlers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var errorHandlers = instance.lookup('service:errorHandlers');
    var logError = errorHandlers.get('logError');
    var reportError = errorHandlers.get('reportError');
    var notifyUserOfError = errorHandlers.get('notifyUserOfError');

    instance.register('errorHandlers:logError', logError, { instantiate: false });
    instance.register('errorHandlers:reportError', reportError, { instantiate: false });
    instance.register('errorHandlers:notifyUserOfError', notifyUserOfError, { instantiate: false });

    ['component', 'controller', 'route', 'view', 'service'].forEach(function (type) {
      instance.inject(type, 'logError', 'errorHandlers:logError');
      instance.inject(type, 'reportError', 'errorHandlers:reportError');
      instance.inject(type, 'notifyUserOfError', 'errorHandlers:notifyUserOfError');
    });
  }

  exports.default = {
    name: 'error-handlers',
    initialize: initialize
  };
});