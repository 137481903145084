define('rp-common/adapters/status-update', ['exports', 'rp-common/adapters/application', 'rp-common/mixins/adapter/feed-item-options'], function (exports, _application, _feedItemOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_feedItemOptions.default, {
    namespace: 'api/v2',
    service: 'status_updates',
    modelName: 'StatusUpdate',

    handleResponse: function handleResponse() {
      return this._super.apply(this, arguments);
    }
  });
});