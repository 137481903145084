define('rp-common/adapters/application', ['exports', 'active-model-adapter', 'ember-data'], function (exports, _activeModelAdapter, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getDefaultHeaders = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var service = Ember.inject.service;
  var makeArray = Ember.makeArray;
  var getOwner = Ember.getOwner;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  function getDefaultHeaders(config) {
    var headers = {};
    var platform = 'desktop';
    headers['RP-Client-Id'] = get(config, 'APP.SECRET_KEY');
    if (get(config, 'APP.SESSION_TOKEN')) {
      headers['RP-Access-Token'] = get(config, 'APP.SESSION_TOKEN');
    }
    if (get(config, 'APP.PLATFORM') === 'mobile') {
      platform = get(config, 'APP.APP_MODE') === 'web' ? 'mobile' : 'mobile-app';
    }
    headers['RP-Version'] = get(config, 'APP.VERSION');
    headers['RP-Platform'] = platform;
    headers['RP-Git'] = config.currentRevision;
    return headers;
  }

  exports.default = _activeModelAdapter.default.extend({
    init: function init() {
      var config = getOwner(this).resolveRegistration('environment:config');
      set(this, 'config', config);
    },

    coalesceFindRequests: true,

    currentUser: service(),
    serverMaintenance: service(),

    host: computed('', function () {
      return this.get('config').APP.ENDPOINT || '';
    }),

    namespace: computed('config', function () {
      return get(this, 'config').APP.NAMESPACE;
    }),

    headers: computed('', function () {
      return getDefaultHeaders(get(this, 'config'));
    }),

    handleResponse: function handleResponse(status, _headers, payload) {
      var jsonErrors = void 0;
      if (payload && status === 401) {
        if (get(payload, 'messages.alerts.firstObject')) {
          jsonErrors = [{ detail: payload.messages.alerts[0] }];
        } else {
          jsonErrors = [{ detail: 'Invalid Email or Password' }];
        }
        this.get('currentUser').logout();
        return new _emberData.default.AdapterError(jsonErrors);
      } else if (payload && (status === 422 || status === 403)) {
        jsonErrors = payload.errors || payload.data.errors;
        return new _emberData.default.InvalidError(jsonErrors);
      } else if (status === 588) {
        this.get('serverMaintenance').serverDown();
      }

      // Sometimes in test, payload is not an object.
      if (get(this, 'config.environment') === 'test' && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object') {
        if ('list_status_update' in payload) {
          payload.list_status_updates = makeArray(payload.list_status_update);
          delete payload.list_status_update;
        }

        if ('full_status_update' in payload) {
          payload.full_status_updates = makeArray(payload.full_status_update);
          delete payload.full_status_update;
        }

        if ('list_shared_link' in payload) {
          payload.list_shared_links = makeArray(payload.list_shared_link);
          delete payload.list_shared_link;
        }

        if ('full_shared_link' in payload) {
          payload.full_shared_links = makeArray(payload.full_shared_link);
          delete payload.full_shared_link;
        }
      }

      return this._super.apply(this, arguments);
    },
    findMany: function findMany(_store, type, ids) {
      return this.ajax(this.buildURL(type.modelName), 'GET', { data: { ids: ids.join(',') } });
    }
  });
  exports.getDefaultHeaders = getDefaultHeaders;
});