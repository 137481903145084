define('rp-common/services/trackers/page-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var set = Ember.set;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var TRACKERS = {
    mobile: 'trackers/mobile-page-view',
    desktop: 'trackers/desktop-page-view'
  };

  exports.default = Service.extend({
    tracker: null,

    init: function init() {
      this._super.apply(this, arguments);
      var config = getOwner(this).resolveRegistration('config:environment');
      // This is a slight hack to make this easier to test. If a real
      // error is caught, the first call to trackPageView will fail loudly anyway.
      if (Ember.testing) {
        set(this, 'tracker', {
          trackPageView: function trackPageView() {}
        });
      } else {
        var PLATFORM = config.APP.PLATFORM;

        set(this, 'tracker', service(TRACKERS[PLATFORM]));
      }
    },
    trackPageView: function trackPageView(url) {
      var lastUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      get(this, 'tracker').trackPageView(url, lastUrl);
    },
    serializeInfiniteLoaderUrl: function serializeInfiniteLoaderUrl(pageNum, url) {
      return pageNum ? url + '.js?page=' + pageNum : url + '.js';
    },
    infinityLoaderPageView: function infinityLoaderPageView(pageNum, url) {
      var serializedUrl = this.serializeInfiniteLoaderUrl(pageNum, url);
      get(this, 'tracker').trackPageView(serializedUrl, url);
    }
  });
});