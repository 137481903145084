define('rp-common/components/feed-company/component', ['exports', 'rp-common/utils/group-route-resolver', 'rp-common/utils/utils-base', 'rp-common/components/feed-company/template'], function (exports, _groupRouteResolver, _utilsBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-company', 'active-feedback', 'feedback-row'],
    listQuestion: null,
    actionMessage: '',
    views: 0,

    routing: service(),

    formattedViewsCount: computed('views', function () {
      return _utilsBase.default.formatCount(this.get('views'));
    }),

    click: function click() {
      get(this, 'routing').transitionTo('groups.show', (0, _groupRouteResolver.groupType)(get(this, 'listQuestion.sponsored_post_group.groupable_type')), get(this, 'listQuestion.sponsored_post_group.slug'));
    }
  });
});