define('rp-common/components/truncate-string/component', ['exports', 'rp-common/components/truncate-string/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    content: null,
    length: null,
    _overRideTruncation: false,

    _showReadLess: readOnly('_overRideTruncation'),

    shouldNotOverride: not('_overRideTruncation'),
    shouldTruncate: and('shouldNotOverride', '_needsTruncation'),
    _showReadMore: and('readMore', '_needsTruncation'),
    _needsTruncation: and('shouldNotOverride', 'overflowExists'),

    overflowExists: computed('content.length', 'length', function () {
      return get(this, 'length') < get(this, 'content.length');
    }),

    str: computed('shouldTruncate', 'content', 'length', function () {
      var content = get(this, 'content');
      var length = get(this, 'length');
      return get(this, 'shouldTruncate') ? content.substring(0, length - 3) : content;
    }),

    actions: {
      readMore: function readMore() {
        set(this, '_overRideTruncation', true);
      },
      readLess: function readLess() {
        set(this, '_overRideTruncation', false);
      }
    }
  });
});