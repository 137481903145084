define('rp-common/components/twitter-share-button/component', ['exports', 'rp-common/components/twitter-share-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    listModel: null,
    fullModel: null,
    postClick: null,
    i18n: service(),
    ui: service(),
    shortLinkGenerator: service('short-link-generator'),

    shortLink: readOnly('fullModel.short_group_url'),
    title: readOnly('listModel.title'),

    href: computed('shortLink', 'title', function () {
      var shortLink = get(this, 'shortLink');
      if (!shortLink) {
        return;
      }
      var caption = get(this, 'i18n').t('sharing.latest') + ' ' + get(this, 'title') + ' ' + get(this, 'shortLink');
      return 'https://twitter.com/intent/tweet?text=' + encodeURI(caption);
    }),

    click: function click() {
      var _this = this;

      // For now, fire this regardless of whether user cancels
      tryInvoke(this, 'postClick', ['twitter']);

      this.get('shortLinkGenerator').generate(this.get('fullModel')).then(function () {
        get(_this, 'ui').centeredPopup(get(_this, 'href'), 'TwitterShareDialog');
      }).catch(get(this, 'ui.handleError'));

      return false;
    }
  });
});