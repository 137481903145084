define('rp-common/services/email-validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var EMAIL_REGEX = exports.EMAIL_REGEX = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?){2,}\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/i;

  exports.default = Service.extend({
    apiService: service(),

    emailRegexp: EMAIL_REGEX,

    isValid: function isValid(email) {
      return this.emailRegexp.test(email);
    },
    isUnique: function isUnique(email) {
      return get(this, 'apiService').getV2Request('reg_checker?email=' + email).then(function (response) {
        if (!response.status) {
          return false;
        }
        return response.status !== 'registered';
      });
    },
    isInvalid: function isInvalid(email) {
      return !this.isValid(email);
    }
  });
});