define('rp-common/models/full-status-update', ['exports', 'rp-common/models/full-qrc-root'], function (exports, _fullQrcRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FullStatusUpdate = _fullQrcRoot.default.extend({
    shortUrlApiVersion: 'api/v2'
  });

  FullStatusUpdate.reopen({
    writeModel: 'status-update'
  });

  exports.default = FullStatusUpdate;
});