define('ember-lazy-video/services/lazy-video-providers', ['exports', 'ember', 'ember-lazy-video/lazy-video-providers/youtube', 'ember-lazy-video/lazy-video-providers/vimeo', 'ember-lazy-video/lazy-video-providers/instagram'], function (exports, _ember, _emberLazyVideoLazyVideoProvidersYoutube, _emberLazyVideoLazyVideoProvidersVimeo, _emberLazyVideoLazyVideoProvidersInstagram) {

  var YOUTUBE_REGEX = /(https?:\/\/)?(www.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/watch\?feature=player_embedded&v=)([A-Za-z0-9_-]*)(\&\S+)?(\?\S+)?/;
  var VIMEO_REGEX = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
  var INSTAGRAM_REGEX = /(https?:\/\/)?(www.)?instagr(am\.com|\.am)\/p\/([A-Za-z0-9_-]*)/;

  var Service = _ember['default'].Service;
  var $ = _ember['default'].$;
  var assert = _ember['default'].assert;
  exports['default'] = Service.extend({
    getUrl: function getUrl(url, endpoint, opts) {
      var params = undefined;
      opts = typeof opts === 'undefined' ? {} : opts;
      params = $.param(opts);

      var provider = this._getProvider(url)[endpoint];
      var videoId = this._getVideoId(url);

      return provider(videoId) + '?' + params;
    },

    getThumbnailUrl: function getThumbnailUrl(url) {
      var videoId = this._getVideoId(url);
      return this._getProvider(url).thumbnailUrl(videoId);
    },

    youtube: _emberLazyVideoLazyVideoProvidersYoutube['default'],
    vimeo: _emberLazyVideoLazyVideoProvidersVimeo['default'],
    instagram: _emberLazyVideoLazyVideoProvidersInstagram['default'],

    _getVideoId: function _getVideoId(url) {
      var videoId = undefined,
          video = undefined;
      if (url) {
        if (VIMEO_REGEX.test(url)) {
          video = VIMEO_REGEX.exec(url);
          videoId = video[3];
        }

        if (YOUTUBE_REGEX.test(url)) {
          video = YOUTUBE_REGEX.exec(url);
          videoId = video[4];
        }

        if (INSTAGRAM_REGEX.test(url)) {
          video = INSTAGRAM_REGEX.exec(url);
          videoId = video[4];
        }
      }

      assert('Couldn\'t determine videoId from url: ' + url, videoId);

      return videoId;
    },

    _getProvider: function _getProvider(url) {
      var providerName = undefined,
          provider = undefined;

      if (url) {
        if (VIMEO_REGEX.test(url)) {
          providerName = 'vimeo';
        }

        if (YOUTUBE_REGEX.test(url)) {
          providerName = 'youtube';
        }

        if (INSTAGRAM_REGEX.test(url)) {
          providerName = 'instagram';
        }
      }

      provider = this.get(providerName);
      assert('Couldn\'t determine provider from url: ' + url, provider);

      return provider;
    }
  });
});