define('rp-common/models/list-discussion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    list_feed_content: belongsTo('list-feed-content', { async: false, polymorphic: true })
  });
});