define('rp-common/models/civilian-companies-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profile_id: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string'),
    job_id: _emberData.default.attr('string')
  });
});