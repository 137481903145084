define('rp-common/components/rp-validated-input/component', ['exports', 'rp-common/components/rp-validated-input/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var and = Ember.computed.and;
  var or = Ember.computed.or;
  var defineProperty = Ember.defineProperty;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['rp-validated-input'],
    classNameBindings: ['isValidating'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    validation: null,
    textarea: false,
    hideUntilBlur: true, // usually we only show errors after user blurs
    showError: false, // typically set to true after user attempts to submit

    showIfBlurred: and('hideUntilBlur', 'didBlur'),
    showMessage: or('showError', 'showIfBlurred'),

    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = get(this, 'valuePath');
      defineProperty(this, 'validation', readOnly('model.validations.attrs.' + valuePath));
      defineProperty(this, 'value', alias('model.' + valuePath));
    },


    isValidating: alias('validation.isValidating'),
    errorMessage: alias('validation.message'),

    actions: {
      blurInput: function blurInput() {
        set(this, 'didBlur', true);
      }
    }
  });
});