define('rp-common/initializers/numeral', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{
    // Use capital abbreviations
    var fmt = numeral.languageData('en');
    fmt.abbreviations.thousand = 'K';
    fmt.abbreviations.million = 'M';
    fmt.abbreviations.billion = 'B';
    fmt.abbreviations.trillion = 'T';
  }

  exports.default = {
    name: 'numeral',
    initialize: initialize
  };
});