define('rp-common/components/job-application-link/component', ['exports', 'rp-common/components/job-application-link/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'p',
    classNames: ['apply'],
    listJob: null,
    groupType: 'organizations',
    location: null,
    position: null,

    jobTracker: service('trackers/job'),
    groupId: alias('listJob.company_slug'),

    click: function click() {
      if (!get(this, 'customHandler')) {
        return;
      }
      this.sendAction('onAppliedCustom');
    },


    actions: {
      appliedExternalLink: function appliedExternalLink() {
        var location = get(this, 'location');
        var position = get(this, 'position');
        get(this, 'jobTracker').trackApplication(get(this, 'listJob'), location, position);
        this.sendAction('onAppliedExternalLink');
      }
    }
  });
});