define('rp-common/components/feed-event-info/component', ['exports', 'rp-common/components/feed-event-info/template', 'rp-common/services/trackers/aggregator'], function (exports, _template, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var TITLE_LINES_DESKTOP = 3;
  var TITLE_LINES_MOBILE = 2;

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['list-feed-card', 'event-info'],
    feedItem: null,
    position: null,

    environment: service(),
    tracker: service('trackers/generic'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'tracker').trackImpression(_aggregator.EVENT_INFO, get(this, 'feedItem.event.id'), _aggregator.HOME_FEED);
    },


    titleLines: computed('environment.isDesktop', function () {
      return get(this, 'environment.isDesktop') ? TITLE_LINES_DESKTOP : TITLE_LINES_MOBILE;
    }),

    actions: {
      trackClick: function trackClick() {
        return get(this, 'tracker').trackClick(_aggregator.EVENT_INFO, get(this, 'feedItem.event.id'), _aggregator.HOME_FEED, get(this, 'position'));
      }
    }
  });
});