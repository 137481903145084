define('rp-common/services/follow-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    apiService: service(),
    inFlight: false,

    follow: function follow(profile) {
      return this.setFollow(profile, true);
    },
    unfollow: function unfollow(profile) {
      return this.setFollow(profile, false);
    },
    setFollow: function setFollow(profile, follow) {
      var _this = this;

      if (get(this, 'inFlight')) {
        return;
      }
      set(this, 'inFlight', true);
      var apiService = get(this, 'apiService');
      var profileId = profile.get('id');
      var path = 'unfollowed_profiles/' + profileId;
      var apiPromise = null;

      if (follow) {
        apiPromise = apiService.destroyV2Request(path);
      } else {
        apiPromise = apiService.postV2Request(path);
      }

      return apiPromise.then(function () {
        return profile.set('followed', follow);
      }).finally(function () {
        if (_this.isDestroyed) {
          return;
        }
        set(_this, 'inFlight', false);
      });
    }
  });
});