define('rp-common/models/civilian-company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    wiki: _emberData.default.attr('string'),
    has_one_click_intro: _emberData.default.attr('boolean'),
    image: _emberData.default.attr('string'),
    jobs_count: _emberData.default.attr('number')
  });
});