define('rp-common/helpers/boldify-term', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.boldifyTerm = boldifyTerm;
  var htmlSafe = Ember.String.htmlSafe;
  var $ = Ember.$;
  var Helper = Ember.Helper;
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  function boldifyTerm(params, _ref) {
    var value = _ref.value,
        term = _ref.term;

    var words = term.split(/\b/).map(function (t) {
      t = $.trim(t.replace(/[^a-z0-9]/ig, ''));

      if (t.length <= 0 || t.toLowerCase() === 'b') {
        return null;
      }

      return '\\b' + t + '\\b';
    }).filter(function (t) {
      return t !== null;
    });

    var re = new RegExp('(' + words.join('|') + ')', 'gi');

    return htmlSafe(escapeExpression(value).replace(re, '<b>$1</b>'));
  }

  exports.default = Helper.helper(boldifyTerm);
});