define('rp-common/services/timezone-tracking', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Service.extend({
    tracked: false,

    apiService: service(),
    currentUser: service(),

    track: function track() {
      if (get(this, 'tracked') || !get(this, 'currentUser.isSignedIn')) {
        return;
      }

      get(this, 'trackTask').perform();
    },


    trackTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'apiService').postRequest('profile_timezones', { profile_timezone: { timezone_offset: -new Date().getTimezoneOffset() } }, 'api/v2').finally(function () {
                return set(_this, 'tracked', true);
              });

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  });
});