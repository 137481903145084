define('rp-common/services/group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({
    store: service(),

    getTag: function getTag(group) {
      var tag = get(this, 'store').peekAll('tag').findBy('group_id', get(group, 'id'));
      if (!tag) {
        tag = get(this, 'store').createRecord('tag', {
          sub_name: '',
          taggable: true,
          group_id: get(group, 'id')
        });
      }
      return tag;
    }
  });
});