define('rp-common/components/rp-tag/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['rp-tag', 'ios-clickable', 'active-feedback'],
    tagName: 'span',
    tag: null,
    position: null,
    location: null
  });
});