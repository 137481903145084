define('rp-common/mixins/pageview-tracking-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Mixin.create({
    pageViewTracker: service('trackers/page-view'),

    lastUrl: null,
    firstRequest: true,

    trackPageView: function () {
      get(this, 'pageViewTracker').trackPageView(get(this, 'url'), get(this, 'lastUrl') || get(this, 'url'));
    }.on('didTransition'),

    trackLastPage: function () {
      if (get(this, 'firstRequest')) {
        set(this, 'firstRequest', false);
        return;
      }

      set(this, 'lastUrl', get(this, 'url'));
    }.on('willTransition')
  });
});