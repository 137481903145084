define('rp-common/models/list-feed-profile-deployment', ['exports', 'ember-data', 'rp-common/models/auto-update-root'], function (exports, _emberData, _autoUpdateRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _autoUpdateRoot.default.extend({
    feedComponentName: 'feed-card-deployment-anniversary',
    deployment_ids: _emberData.default.hasMany('deployment'), // array with one item
    deployment: alias('deployment_ids.firstObject'),
    viewer_has_deployment_with_end_date: _emberData.default.attr('boolean')
  });
});