define('rp-common/components/profile-mention-result/component', ['exports', 'rp-common/components/profile-mention-result/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var and = Ember.computed.and;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'li',
    profile: null,
    showBranchAndSpecialty: false,
    classNameBindings: ['profile.active:active'],

    civilianBranch: equal('profile.branch_name', 'Civilian'),
    notCivilianBranch: not('civilianBranch'),
    showBranch: and('showBranchAndSpecialty', 'notCivilianBranch'),

    click: function click() {
      this.attrs.onselect(this.get('profile'));
    }
  });
});