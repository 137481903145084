define('rp-common/models/offer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    offer: attr('string'),
    cta: attr('string'),
    destination_url: attr('string'),
    logo: attr('string'),
    pixel: attr('string')
  });
});