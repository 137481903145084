define('rp-common/components/link-wrapper/component', ['exports', 'rp-common/components/link-wrapper/template', 'rp-common/utils/utils-base'], function (exports, _template, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    layout: _template.default,
    href: null,
    route: null,
    tagName: '',
    newTab: false,
    overrideLinkMode: false,

    linkMode: computed(function () {
      var config = getOwner(this).resolveRegistration('config:environment');

      return get(config, 'APP.PLATFORM') === 'desktop';
    }),

    actions: {
      clicked: function clicked() {
        var _this = this;

        if (!get(this, 'linkClicked')) {
          return true;
        }

        var response = get(this, 'linkClicked')();

        if (response.then) {
          response.then(function () {
            return _utilsBase.default.browserRedirect(get(_this, 'href'));
          });
        }
      }
    }
  });
});