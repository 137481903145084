define('rp-common/models/civilian-preference', ['exports', 'ember-data', 'rp-common/models/base-career-preference', 'ember-cp-validations'], function (exports, _emberData, _baseCareerPreference, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'job_interests.length': (0, _emberCpValidations.validator)('number', { gt: 0 })
  });

  exports.default = _baseCareerPreference.default.extend(Validations, {
    edited: attr('boolean'),
    job_interests: attr()
  });
});