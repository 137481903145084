define('rp-common/mixins/html-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    htmlEncode: function htmlEncode(str) {
      return $('<div/>').text(str).html();
    },
    htmlStrip: function htmlStrip(str) {
      return $('<div/>').html(str).get(0).textContent;
    },
    linkify: function linkify(txt, dataAttrs, attrs) {
      var href = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '#';

      var $a = $('<a href="' + href + '"/>').text(txt);

      for (var d in dataAttrs) {
        $a.attr('data-' + d, dataAttrs[d]);
      }

      for (var a in attrs) {
        $a.attr(a, attrs[a]);
      }

      return $('<div/>').append($a).html();
    }
  });
});