define('rp-common/experiments/sponsored-topic', ['exports', 'rp-common/experiments/base-experiment'], function (exports, _baseExperiment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseExperiment.default.extend({
    experimentId: 'sponsored-topic-9776879368',

    init: function init() {
      this._super.apply(this, arguments);
      // Control is REQUIRED
      this.groups = ['control', 'teal-bg', 'learn-more', 'inside-rtn'];
    }
  });
});