define('rp-common/models/full-question', ['exports', 'ember-data', 'rp-common/utils/model-utils', 'rp-common/models/full-qrc-root'], function (exports, _emberData, _modelUtils, _fullQrcRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;


  var FullQuestion = _fullQrcRoot.default.extend({
    question_category: _emberData.default.belongsTo('question-category', { async: true }),
    survey: _emberData.default.belongsTo('survey', { async: true }),
    // BEWARE, HERE BE DRAGONS: This seems to resolve to this.id until it actually resolves...
    original_question: _emberData.default.belongsTo('list-question', { async: true, inverse: null }),

    command_post: _emberData.default.attr('boolean'),

    following: _emberData.default.attr('boolean'),

    isDuplicate: computed('original_question.id', function () {
      return this.get('original_question.id') && this.get('id') !== this.get('original_question.id');
    }),

    combinedTags: computed('list_groups.@each.{name,groupable_type}', function () {
      var combinedTags = this.get('list_groups').map(function (group) {
        return EmberObject.create({
          id: group.get('slug'),
          type: 'group',
          name: group.get('name'),
          groupable_type: group.get('groupable_type')
        });
      });
      return combinedTags;
    })
  });

  FullQuestion.reopen({
    writeModel: 'question',

    follow: function follow() {
      var self = this;
      var adapter = self.store.adapterFor('question');
      self.set('following', true);
      (0, _modelUtils.cleanProperty)(self, 'following');
      return adapter.follow(self).catch(function (err) {
        self.set('following', false);
        (0, _modelUtils.cleanProperty)(self, 'following');
        throw err;
      });
    },
    unfollow: function unfollow() {
      var self = this;
      var adapter = self.store.adapterFor('question');
      self.set('following', false);
      (0, _modelUtils.cleanProperty)(self, 'following');
      return adapter.unfollow(self).catch(function (err) {
        self.set('following', true);
        (0, _modelUtils.cleanProperty)(self, 'following');
        throw err;
      });
    }
  });

  exports.default = FullQuestion;
});