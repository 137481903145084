define('rp-common/services/google-analytics-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    pageViewTracker: service('trackers/page-view'),

    infinityLoaderPageView: function infinityLoaderPageView(pageNum, url) {
      var gaString = get(this, 'pageViewTracker').serializeInfiniteLoaderUrl(pageNum, url);
      return this.sendPageView({ page: gaString, title: gaString });
    },
    hasGA: function hasGA() {
      var config = getOwner(this).resolveRegistration('environment:config');
      return config.googleAnalytics && window.ga;
    },
    sendPageView: function sendPageView() {
      var fieldsObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!(fieldsObject.page || fieldsObject.location)) {
        fieldsObject.location = window.location.pathname;
      }
      if (this.hasGA()) {
        window.ga('send', 'pageview', fieldsObject);
        return true;
      }
      return false;
    }
  });
});