define('rp-common/models/list-feed-profile-anniversary', ['exports', 'ember-data', 'rp-common/models/auto-update-root'], function (exports, _emberData, _autoUpdateRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _autoUpdateRoot.default.extend({
    feedComponentName: 'feed-card-rp-anniversary',
    anniversary_event_ids: _emberData.default.hasMany('anniversary-event'), // array with one item
    // this field may have more items someday, but for now we just have one
    anniversary: alias('anniversary_event_ids.firstObject')
  });
});