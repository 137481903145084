define('rp-common/services/trackers/sponsored-post', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    aggregator: service('trackers/aggregator'),

    trackClick: function trackClick(sponsoredPost) {
      get(this, 'aggregator').track(_aggregator.SPONSORED_POST, [this.serializePost(sponsoredPost)], true);
    },
    serializePost: function serializePost(sponsoredPost) {
      (false && !(get(sponsoredPost, 'id')) && Ember.assert('Must pass a valid sponsored post to track', get(sponsoredPost, 'id')));


      return {
        type: 'click',
        qrc_id: get(sponsoredPost, 'id')
      };
    }
  });
});