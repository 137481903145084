define('rp-common/serializers/potential-connection', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.potential_connections = [];
      payload.list_profiles.forEach(function (profile) {
        var pc = { id: profile.id, list_profile_id: profile.id };
        payload.potential_connections.push(pc);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});