define('rp-common/models/list-feed-job-preference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    isOtherFeedItemType: true,
    feedComponentName: 'feed-job-preferences',
    user_closest_duty_station: attr(),
    popular_job_interests: attr()
  });
});