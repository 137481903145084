define('ember-lifeline/utils/disposable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports._setRegisteredDisposables = _setRegisteredDisposables;
  exports.registerDisposable = registerDisposable;
  exports.runDisposables = runDisposables;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var WeakMap = Ember.WeakMap;


  /**
   * A map of instances/array of disposables. Only exported for
   * testing purposes.
   *
   * @public
   */
  var registeredDisposables = new WeakMap();

  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether disposables have all been called.
   *
   * @private
   * @param {*} mapForTesting A map used to ensure correctness when testing.
   */
  function _setRegisteredDisposables(mapForTesting) {
    registeredDisposables = mapForTesting;
  }

  /**
   * Registers a new disposable function to run for an instance. Will
   * handle lazily creating a new array to store the disposables per
   * instance if one does not exist. Will additionally patch an object's
   * `destroy` hook to ensure the destroyables are run/destroyed when
   * the object is destroyed.
   *
   * @public
   * @param {*} obj the instance to store the disposable for
   * @param {*} dispose a function that disposes of instance resources
   * @returns a disposable object, which wraps the passed in `dispose` function
   */
  function registerDisposable(obj, dispose) {
    (false && !((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object') && Ember.assert('Called `registerDisposable` where `obj` is not an object', (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object'));
    (false && !(typeof dispose === 'function') && Ember.assert('Called `registerDisposable` where `dispose` is not a function', typeof dispose === 'function'));
    (false && !(!obj.isDestroying) && Ember.assert('Called `registerDisposable` on a destroyed object', !obj.isDestroying));


    var disposables = getRegisteredDisposables(obj);

    disposables.push(dispose);
  }

  /**
   * Runs all disposables for a given instance.
   *
   * @public
   * @param {*} obj the instance to run the disposables for
   */
  function runDisposables(obj) {
    var disposables = registeredDisposables.get(obj);

    if (disposables === undefined) {
      return;
    }

    registeredDisposables.delete(obj);

    for (var i = 0; i < disposables.length; i++) {
      disposables[i]();
    }
  }

  function getRegisteredDisposables(obj) {
    var arr = registeredDisposables.get(obj);

    if (arr === undefined) {
      registeredDisposables.set(obj, arr = []);
    }

    return arr;
  }
});