define('rp-common/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    user: null,
    store: service(),
    isSignedIn: computed('user', 'currentSession.isSignedIn', function () {
      return this.get('user') || this.get('currentSession.isSignedIn');
    }),
    profile: alias('user.profile'),
    currentSession: service(),
    pendingLoginActions: service(),

    login: function login() {
      var _this = this;

      var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('session.isSignedIn')) {
        this.set('user', this.get('session.user'));
        return;
      }
      if (user === null) {
        user = this.get('store').find('session', 'current').then(function (session) {
          _this.set('user', session.get('user'));
        });
      }
      this.set('user', user);
      if (this.get('pendingLoginActions.actionsPending')) {
        RSVP.resolve(user).then(function () {
          _this.get('pendingLoginActions').processPendingSubmissions();
        });
      }
    },
    logout: function logout() {
      this.set('user', null);
      if (this.get('session.user')) {
        this.set('session.user', null);
      }
      this.set('currentSession.session', null);
    }
  });
});