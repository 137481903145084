define('rp-common/mixins/models/year-date', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var computed = Ember.computed;
  var attr = _emberData.default.attr;
  exports.default = Mixin.create({
    start_year: attr('number'),
    end_year: attr('number'),

    startEndYear: computed('start_year', 'end_year', function () {
      var sy = get(this, 'start_year');
      var ey = get(this, 'end_year');
      return sy && ey ? sy + ' - ' + ey : sy || ey;
    })
  });
});