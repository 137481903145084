define('rp-common/components/feed-featured-company-expert/component', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var htmlSafe = Ember.String.htmlSafe;
  var readOnly = computed.readOnly;
  exports.default = Component.extend({
    classNames: ['featured-company-expert'],
    feedItem: null,
    location: _aggregator.HOME_FEED,
    position: null,

    listProfile: readOnly('feedItem.featured_company_expert.list_profile'),
    sent: readOnly('feedItem.featured_company_expert.sent'),

    tracker: service('trackers/generic'),

    canvasImageStyle: computed('feedItem.featured_company_expert.list_group.canvas_image', function () {
      return htmlSafe('background-image: url(\'' + get(this, 'feedItem.featured_company_expert.list_group.canvas_image') + '\');');
    }),

    logoImageStyle: computed('feedItem.featured_company_expert.list_group.logo_image', function () {
      return htmlSafe('background-image: url(\'' + get(this, 'feedItem.featured_company_expert.list_group.logo_image') + '\');');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'tracker').trackImpression(_aggregator.FEATURED_COMPANY_EXPERT, get(this, 'feedItem.featured_company_expert.id'), _aggregator.HOME_FEED, get(this, 'position'));
    },


    actions: {
      sentConnection: function sentConnection() {
        set(this, 'feedItem.featured_company_expert.sent', true);

        get(this, 'tracker').trackClick(_aggregator.FEATURED_COMPANY_EXPERT, get(this, 'feedItem.featured_company_expert.id'), _aggregator.HOME_FEED, get(this, 'position'));
      }
    }
  });
});