define('rp-common/services/routing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Service.extend({
    routing: service('-routing'),
    router: alias('routing.router'),
    transitionTo: function transitionTo() {
      var _get;

      (_get = get(this, 'routing.router')).transitionTo.apply(_get, arguments);
    },
    hasRoute: function hasRoute(routeName) {
      get(this, 'routing.router.router').getHandler(routeName);
    }
  });
});