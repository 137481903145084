define('rp-common/services/ui', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var RSVP = Ember.RSVP;
  var Logger = Ember.Logger;
  var _x$y = { x: 626, y: 426 },
      defaultPopupWidth = _x$y.x,
      defaultPopupHeight = _x$y.y;
  exports.default = Service.extend({
    handleError: function handleError(err, msg, title) {
      Logger.error(err);
      this.nativeAlert(msg, title);
    },
    nativeAlert: function nativeAlert(msg, title) {
      if (navigator.notification) {
        // The .toString is due to Ember translations not returning a proper string, causing
        // iOS to crash.
        navigator.notification.alert((msg || '').toString(), null, (title || '').toString());
      } else {
        window.alert(msg);
      }
    },
    nativeConfirm: function nativeConfirm(msg, options) {
      // All of the .toString is due to Ember translations not returning a proper string, causing
      // iOS to crash.
      msg = msg.toString();
      options = options || {};
      options.buttonLabels = (options.buttonLabels || ['OK', 'Cancel']).map(function (lbl) {
        return lbl.toString();
      });
      options.confirmButtonText = (options.confirmButtonText || 'OK').toString();
      options.title = (options.title || 'Confirm').toString();
      return new RSVP.Promise(function (resolve, reject) {
        if (navigator.notification) {
          var handleResult = function handleResult(clickedButtonIndex) {
            var isDialogConfirmed = options.buttonLabels.indexOf(options.confirmButtonText) + 1 === clickedButtonIndex;
            if (isDialogConfirmed) {
              resolve(true);
            } else {
              reject();
            }
          };
          navigator.notification.confirm(msg, handleResult, options.title, options.buttonLabels);
        } else {
          if (window.confirm(msg)) {
            resolve(true);
          } else {
            reject();
          }
        }
      });
    },


    // Warning: might not work on mobile quite yet.
    centeredPopup: function centeredPopup(url, popupName) {
      var w = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultPopupWidth;
      var h = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultPopupHeight;

      var leftPos = screen.width ? (screen.width - w) / 2 : 0;
      var topPos = screen.height ? (screen.height - h) / 2 : 0;
      var settings = 'height=' + h + ',width=' + w + ',top=' + topPos + ',left=' + leftPos + ',scrollbars=yes,resizable';
      popupName = popupName.replace(/\s/, '');
      window.open(url, popupName, settings);
    }
  });
});