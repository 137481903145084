define('rp-common/services/similar-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    apiService: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('cache', {});
    },
    query: function query(term) {
      if (!term) {
        return new RSVP.Promise(function (resolve) {
          return resolve();
        });
      }

      var cache = this.get('cache');

      if (cache[term]) {
        return new RSVP.Promise(function (resolve) {
          return resolve(cache[term]);
        });
      }

      return get(this, 'apiService').getRequest('autocompletes/questions?term=' + encodeURIComponent(term)).then(function (results) {
        cache[term] = results;
        return results;
      });
    }
  });
});