define('rp-common/models/current-promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    rank: _emberData.default.belongsTo('rank', {
      async: false
    }),

    // attributes
    create_previous: _emberData.default.attr('boolean', { defaultValue: true }),
    current: _emberData.default.attr('boolean', { defaultValue: true })
  });
});