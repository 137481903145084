define('rp-common/services/local-storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var EmberObject = Ember.Object;
  var Service = Ember.Service;
  exports.default = Service.extend({
    localStore: EmberObject.create(),
    hydrated: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('whitelist', ['token', 'totalFound', 'invitationToken', '__RP_SESSION_TOKEN__', 'social_invitation_token', 'previousTransitionUrl', 'contactCheckerStatus', 'loginType', 'data', 'return-visitor']);
    },


    data: readOnly('localStore'),

    hydrate: function hydrate() {
      var whitelist = this.get('whitelist');

      if (!this.get('hydrated')) {
        var ls = window.localStorage;
        if (ls && ls.getItem) {
          for (var key in ls) {
            if (whitelist.includes(key) || /^rp-exp/.test(key)) {
              try {
                this.localStore.set(key, ls.getItem(key));
                Ember.Logger.info('Populated local storage value ->', key);
              } catch (err) {
                Ember.Logger.debug('Error reading in local storage data:', err);
              }
            }
          }
        }
        this.set('hydrated', true);
      }
    },
    getItem: function getItem(key) {
      this.hydrate();
      var ls = window.localStorage;
      if (ls && ls.getItem) {
        return ls.getItem(key);
      }
      return this.localStore.get(key) || null;
    },
    setItem: function setItem(key, value) {
      this.hydrate();
      Ember.Logger.info('Set local storage value ->', key, 'to', value);
      var ls = window.localStorage;
      if (ls && ls.setItem) {
        ls.setItem(key, value);
      }
      return this.localStore.set(key, value);
    },
    removeItem: function removeItem(key) {
      this.hydrate();
      var ls = window.localStorage;
      if (ls && ls.removeItem) {
        ls.removeItem(key);
      }
      this.localStore.set(key, undefined);
    }
  });
});