define('rp-common/components/connect-user-button/component', ['exports', 'rp-common/components/connect-user-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['connect-user-button'],
    classNameBindings: ['requestSent:secondary'],
    profile: null,
    requestSent: false,
    customLabel: false
  });
});