define('rp-common/models/list-feed-shared-link', ['exports', 'ember-data', 'rp-common/models/list-feed-content'], function (exports, _emberData, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _listFeedContent.default.extend({
    i18n: service(),
    is_shared_link: true,
    list_shared_link: _emberData.default.belongsTo('list-shared-link', { async: true, inverse: null }),
    contact_commenters: _emberData.default.hasMany('list-profile', { async: true, inverse: null }),
    contact_voters: _emberData.default.hasMany('list-profile', { async: true, inverse: null }),
    contact_responses: _emberData.default.hasMany('list-response', { async: true, inverse: null }),
    group: _emberData.default.belongsTo('list-group', { async: true, inverse: null }),

    total_commenter_participants: _emberData.default.attr('number'),
    total_voter_participants: _emberData.default.attr('number'),
    reason: _emberData.default.attr('string'),
    reason_last_at: _emberData.default.attr('date'),

    followed_groups: _emberData.default.hasMany('list-group', { async: true }),
    primary_contact_participants: _emberData.default.hasMany('list-profile', { async: true }),

    author: readOnly('list_shared_link.list_profile'),
    createdAt: readOnly('list_shared_link.full_shared_link.created_at'),
    responses: readOnly('contact_responses'),
    listModel: alias('list_shared_link'),

    hasReason: true,

    actionMessage: computed(function () {
      return this.get('i18n').t('feed.postedALink');
    })
  });
});