define('rp-common/components/external-link/component', ['exports', 'rp-common/components/external-link/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'a',
    attributeBindings: ['target', 'href'],
    target: '_blank',
    href: null,

    config: computed(function () {
      return getOwner(this).resolveRegistration('config:environment');
    }),

    click: function click() {
      if (get(this, 'clicked')) {
        this.sendAction('clicked');
      }

      if (get(this, 'config.APP.APP_MODE') === 'app') {
        window.open(this.get('href'), '_system');
        return false;
      }
    }
  });
});