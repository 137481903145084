define('rp-common/models/list-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var Inflector = Ember.Inflector;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    slug: attr('string'),
    name: attr('string'),
    groupable_type: attr('string'),
    groupable_type_mapped: attr('string'),
    canvas_image: attr('string'),
    logo_image: attr('string'),
    taggable: attr('boolean'),
    taggable_for_unverified_users: attr('boolean'),
    followers_count: attr('number'),
    is_private: attr('boolean'),
    groupable_route_path: attr('string'),
    following: attr('boolean'),

    groupableLink: alias('groupable_route_path'),

    // This is deprecated for desktop-fe. Use options in desktop-fe's list-group model instead.
    url: computed('slug', 'groupable_type_mapped', function () {
      var slug = get(this, 'slug');
      var groupableType = get(this, 'groupable_type_mapped');

      if (!slug || !groupableType) {
        return '';
      }

      var inflector = new Inflector(Inflector.defaultRules);
      return '/' + inflector.pluralize(groupableType.toLowerCase()) + '/' + slug;
    })
  });
});