define('rp-common/serializers/question', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _application.default.extend({
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      if (relationship.key === 'survey') {
        var survey = record.belongsTo('survey');
        if (survey) {
          var survey_options = survey.hasMany('survey_options');
          if (survey_options) {
            var survey_options_hash = survey_options.reduce(function (options, cur, index) {
              options[index] = { name: cur.attr('name') };
              return options;
            }, {});
            json.survey_attributes = { creating_survey: '1', survey_options_attributes: survey_options_hash };
          }
        }
      }
    },
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'pictures') {
        var pictures = record.hasMany('pictures');
        json.images_ids = isArray(pictures) ? pictures.mapBy('id') : [];
      } else if (relationship.key === 'tags') {
        var tag_names = [];
        var explicit_groups = [];
        var tags = record.hasMany('tags');
        if (isArray(tags)) {
          record.hasMany('tags').forEach(function (tag) {
            if (tag.attr('group_id')) {
              explicit_groups.push(tag.attr('group_id'));
            } else {
              tag_names.push(tag.attr('name'));
            }
          });
        }
        json.topics_list = tag_names.join(',');
        json.explicit_group_ids = explicit_groups;
      }
    }
  });
});