define('rp-common/models/list-status-update', ['exports', 'ember-data', 'rp-common/models/list-qrc-root'], function (exports, _emberData, _listQrcRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _listQrcRoot.default.extend({
    list_profile: _emberData.default.belongsTo('list-profile', { async: true }),
    full_status_update: _emberData.default.belongsTo('full-status-update', { async: true }),

    title: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),

    full_model: alias('full_status_update')
  });
});