define('rp-common/services/trackers/base-tracker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    category: '',
    aggregator: service('trackers/aggregator'),

    trackEvent: function trackEvent(targetId, source) {
      var eventType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'impression';
      var immediate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      var trackableType = get(this, 'category');
      get(this, 'aggregator').track(trackableType, [this.serializeEvent(targetId, source, trackableType, eventType)], immediate);
    },
    trackImpression: function trackImpression() {
      this.trackEvent.apply(this, arguments);
    },
    trackClick: function trackClick(targetId, source) {
      this.trackEvent(targetId, source, 'click', true);
    },
    serializeEvent: function serializeEvent(targetId, source, trackableType, type) {
      var result = { type: type, source: source };
      result[trackableType] = targetId;
      return result;
    }
  });
});