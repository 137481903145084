define('rp-common/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var gt = Ember.computed.gt;
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    isReferral: equal('general', 'referral'),
    // relationships
    list_profile: belongsTo('list-profile', { async: true }),
    list_discussion: belongsTo('list-qrc-root', { async: true, polymorphic: true }),
    list_comment: belongsTo('list-comment', { async: true }),
    list_response: belongsTo('list-response', { async: true }),
    list_response_discussion: belongsTo('list-qrc-root', { async: true, polymorphic: true }),
    list_comment_discussion: belongsTo('list-qrc-root', { async: true, polymorphic: true }),
    list_comment_response: belongsTo('list-response', { async: true }),

    // properties
    general: attr('string'),
    type: attr('string'),
    badge_type: attr('string'),
    points: attr('number'),
    skip_new_flag: attr('boolean'),
    time: attr('number'),
    group_image: attr('string'),
    groups: attr('array'),
    group_link_type: attr('string'),
    group_link_id: attr('string'),
    list_profile_anonymous: attr('string'), // fallback when true profile is hidden

    positivePoints: gt('points', 0),
    absPoints: computed('points', 'positivePoints', function () {
      return get(this, 'positivePoints') ? get(this, 'points') : Math.abs(get(this, 'points'));
    }),

    // computed
    timeAgo: computed('time', function () {
      var timeAgo = get(this, 'time');

      if (timeAgo) {
        var minutes = timeAgo / 60;
        var hours = minutes / 60;
        var days = hours / 24;
        if (days >= 1) {
          return Math.floor(days) + 'd';
        } else if (hours >= 1) {
          return Math.floor(hours) + 'h';
        } else {
          return Math.floor(minutes) + 'm';
        }
      } else {
        return null;
      }
    })
  });
});