define('rp-common/services/mentions-autocomplete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var $ = Ember.$;


  var autoCompleteCache = {};

  exports.default = Service.extend({
    apiService: service(),

    clearCache: function clearCache() {
      autoCompleteCache = {};
    },
    getAutoCompletes: function getAutoCompletes(partialProfile, qrcId) {
      var _this = this;

      var groupId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var cacheKey = partialProfile + (qrcId && !isNaN(qrcId) ? qrcId : '') + (groupId && !isNaN(groupId) ? groupId : '');

      return new RSVP.Promise(function (resolve) {
        if (autoCompleteCache[cacheKey]) {
          resolve(autoCompleteCache[cacheKey]);
        } else {
          var paramObj = { term: partialProfile, qrc_root_id: qrcId };
          if (groupId) {
            paramObj.group_id = groupId;
          }
          var params = $.param(paramObj);
          _this.get('apiService').getRequest('peek/profiles?' + params).then(function (json) {
            autoCompleteCache[cacheKey] = json;
            resolve(json);
          });
        }
      });
    }
  });
});