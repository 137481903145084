define('rp-common/models/list-feed-event', ['exports', 'ember-data', 'rp-common/models/list-feed-content'], function (exports, _emberData, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listFeedContent.default.extend({
    isOtherFeedItemType: true,
    feedComponentName: 'feed-event-info',

    event: _emberData.default.belongsTo('event')
  });
});