define('rp-common/services/trackers/job', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JOB_FEATURED_SHOW_PAGE = exports.JOB_ORGANIZATION_PAGE = exports.JOB_SEARCH_PAGE_SEARCH_RESULTS = exports.JOB_SEARCH_PAGE_RECOMMENDED = exports.JOB_SHOW_PAGE = exports.JOB_HOME_FEED = exports.JOB_SIDE_ANSWERS = exports.JOB_SIDE_COMMAND_POST = exports.JOB_SIDE_HOME = exports.JOB_BANNER_AD = undefined;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var JOB_BANNER_AD = exports.JOB_BANNER_AD = 'job_banner_ad';
  var JOB_SIDE_HOME = exports.JOB_SIDE_HOME = 'job_side_home';
  var JOB_SIDE_COMMAND_POST = exports.JOB_SIDE_COMMAND_POST = 'job_side_command_post';
  var JOB_SIDE_ANSWERS = exports.JOB_SIDE_ANSWERS = 'job_side_answers';
  var JOB_HOME_FEED = exports.JOB_HOME_FEED = 'job_home_feed';
  var JOB_SHOW_PAGE = exports.JOB_SHOW_PAGE = 'job_show_page';
  var JOB_SEARCH_PAGE_RECOMMENDED = exports.JOB_SEARCH_PAGE_RECOMMENDED = 'job_search_page_recommended';
  var JOB_SEARCH_PAGE_SEARCH_RESULTS = exports.JOB_SEARCH_PAGE_SEARCH_RESULTS = 'job_search_page_search_results';
  var JOB_ORGANIZATION_PAGE = exports.JOB_ORGANIZATION_PAGE = 'job_organization_page';
  var JOB_FEATURED_SHOW_PAGE = exports.JOB_FEATURED_SHOW_PAGE = 'job_featured_show_page';

  exports.default = Service.extend({
    aggregator: service('trackers/aggregator'),

    trackImpression: function trackImpression(job, location) {
      this.trackImpressions([job], location);
    },
    trackImpressions: function trackImpressions(jobs, location) {
      var _this = this;

      get(this, 'aggregator').track(_aggregator.JOB, jobs.map(function (job) {
        return _this.serializeJob(job, location);
      }));
    },
    trackView: function trackView(job, location) {
      this.trackViews([job], location);
    },
    trackViews: function trackViews(jobs, location) {
      var _this2 = this;

      get(this, 'aggregator').track(_aggregator.JOB, jobs.map(function (job) {
        return _this2.serializeJob(job, location, 'view');
      }));
    },
    trackApplication: function trackApplication(job) {
      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var position = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      return get(this, 'aggregator').track(_aggregator.JOB_APPLICATION_EXTERNAL, [this.serializeJob(job, location, 'click', position)], true);
    },
    trackClick: function trackClick(job, location, position) {
      return get(this, 'aggregator').track(_aggregator.JOB, [this.serializeJob(job, location, 'click', position)], true);
    },
    serializeJob: function serializeJob(job, location) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'impression';
      var position = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
      (false && !(get(job, 'id') && get(job, 'civilian_company_id')) && Ember.assert('Must pass a valid job to track', get(job, 'id') && get(job, 'civilian_company_id')));


      return {
        type: type,
        location: location,
        position: position,
        job_id: get(job, 'id'),
        civilian_company_id: get(job, 'civilian_company_id')
      };
    }
  });
});