define('rp-common/mixins/adapter/feed-item-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  exports.default = Mixin.create({
    feedHiddenItemBaseUrl: function feedHiddenItemBaseUrl() {
      return this.urlForUpdateRecord(null, 'feeds').replace('v1', 'v2');
    },
    reportAsSpam: function reportAsSpam(feedItem) {
      var id = get(feedItem, 'id');
      var model = get(this, 'service');
      var urlPart = [this.urlForUpdateRecord(id, model), 'spam_reports'].join('/');
      return this.ajax(urlPart, 'POST');
    },
    hideFromUpdates: function hideFromUpdates(feedItem, action) {
      return this.ajax([this.feedHiddenItemBaseUrl(), action ? 'hide' : 'unhide'].join('/') + '?content_id=' + get(feedItem, 'id'), 'POST');
    }
  });
});