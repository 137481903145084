define('rp-common/models/civilian-education', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    academic_degree_id: _emberData.default.attr('string'),
    degree: _emberData.default.attr('string'),
    // Name of facility
    unit_name: _emberData.default.attr('string'),
    unit_id: _emberData.default.attr('string'),
    concentration_id: _emberData.default.attr('string'),
    concentration_name: _emberData.default.attr('string'),
    start_year: _emberData.default.attr('number'),
    start_month: _emberData.default.attr('number'),
    end_year: _emberData.default.attr('number'),
    end_month: _emberData.default.attr('number')
  });
});