define('rp-common/components/spinner-icon/component', ['exports', 'rp-common/components/spinner-icon/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['rp-spinner-icon-component'],
    classNameBindings: ['isPureCSS', 'isDarkBG', 'isSmall', 'busy'],
    tagName: 'span'
  });
});