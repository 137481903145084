define('rp-common/components/feed-top-bar/component', ['exports', 'rp-common/components/feed-top-bar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-top-bar'],
    disableQuestion: false,
    minPoints: 150,
    showPostUpdate: false,
    showAskQuestion: false,
    showShareLink: false,
    showHelp: false,
    shareLinkDisabled: or('showPostUpdate', 'showAskQuestion'),
    postUpdateDisabled: or('showShareLink', 'showAskQuestion'),
    askQuestionDisabled: or('showPostUpdate', 'showShareLink'),

    actions: {
      askQuestion: function askQuestion() {
        this.attrs.askQuestion();
      },
      shareLink: function shareLink() {
        this.attrs.shareLink();
      },
      postUpdate: function postUpdate() {
        this.attrs.postUpdate();
      }
    }
  });
});