define('rp-common/models/certificate-education', ['exports', 'ember-data', 'rp-common/mixins/models/year-date'], function (exports, _emberData, _yearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_yearDate.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    certificate_id: _emberData.default.attr('string'),
    certificate_name: _emberData.default.attr('string')
  });
});