define('ember-islands/utils/query-island-components', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = queryIslandComponents;
  var $ = _ember['default'].$;

  /**
   * @typedef {object} IslandComponent
   * @property {object} element - The placeholder element for this component
   * @property {object} attrs - Attrs to for an Ember Component
   * @property {string} name - Name of a registered Ember component
   * @property {object} instance - Ember component instance
   */

  /**
   * @return {IslandComponent[]}
   */

  function queryIslandComponents() {
    var components = [];

    $('[data-component]').each(function () {
      var name = this.getAttribute('data-component');
      var attrs = componentAttributes(this);
      components.push({ attrs: attrs, name: name, element: this, instance: undefined });
    });

    return components;
  }

  function componentAttributes(element) {
    var attrs = undefined;
    var attrsJSON = element.getAttribute('data-attrs');

    if (attrsJSON) {
      attrs = JSON.parse(attrsJSON);
    } else {
      attrs = {};
    }

    attrs.innerContent = element.innerHTML;
    return attrs;
  }
});