define('ember-lazy-video/lazy-video-providers/vimeo', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = {
    apiUrl: function apiUrl(videoId) {
      return 'https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/' + videoId;
    },
    embedUrl: function embedUrl(videoId) {
      return 'https://player.vimeo.com/video/' + videoId;
    },
    thumbnailUrl: function thumbnailUrl(videoId) {
      var apiUrl = this.apiUrl(videoId);
      return new RSVP.Promise(function (resolve) {
        $.getJSON(apiUrl).then(function (res) {
          resolve(res.thumbnail_url);
        });
      });
    }
  };
});