define('rp-common/components/offer-tile/component', ['exports', 'rp-common/components/offer-tile/template', 'rp-common/services/trackers/aggregator'], function (exports, _template, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['offer-tile'],

    offer: null,
    position: 0,
    location: _aggregator.OFFER_PAGE,

    tracker: service('trackers/generic'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'tracker').trackImpression(_aggregator.OFFER, get(this, 'offer.id'), get(this, 'location'), get(this, 'position'));
    },


    actions: {
      trackClick: function trackClick() {
        get(this, 'tracker').trackClick(_aggregator.OFFER, get(this, 'offer.id'), get(this, 'location'), get(this, 'position'));
      }
    }
  });
});