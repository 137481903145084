define('rp-common/services/seo-data-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    getTitle: function getTitle(model) {
      if (get(model, 'seoTitle')) {
        return RSVP.resolve(get(model, 'seoTitle'));
      }
      return RSVP.reject();
    },
    getMetaDescription: function getMetaDescription(model) {
      if (get(model, 'seoMetaDescription')) {
        return RSVP.resolve(get(model, 'seoMetaDescription'));
      }
      return RSVP.reject();
    },
    getStructuredData: function getStructuredData(model) {
      if (get(model, 'seoStructuredData')) {
        return RSVP.resolve(get(model, 'seoStructuredData')).then(function (structuredDataHash) {
          return JSON.stringify(structuredDataHash);
        });
      }
      return RSVP.reject();
    }
  });
});