define('rp-common/components/link-preview/video/component', ['exports', 'rp-common/components/link-preview/video/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['link-video'],
    classNameBindings: ['iframeDisplayed:playing'],
    picture: null,
    iframeDisplayed: false,

    httpsVideoUrl: computed('linkData.url', function () {
      var url = get(this, 'linkData.url');
      return url.replace(/^http:\/\//i, 'https://');
    })
  });
});