define('rp-common/mixins/has-short-link', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    shortUrlApiVersion: 'api/v1',
    shortUrlAttribute: 'short_group_url',

    shortUrlApi: computed(function () {
      // Default implementation
      var modelName = this.get('constructor.modelName');
      return (0, _emberInflector.pluralize)(modelName.replace(/^(list-)|(full-)/, '').replace('-', '_')) + '/' + this.get('id') + '/' + this.get('shortUrlAttribute');
    })
  });
});