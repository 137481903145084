define('rp-common/components/list-feed-item/component', ['exports', 'rp-common/components/list-feed-item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  var not = Ember.computed.not;
  var or = Ember.computed.or;
  var bool = Ember.computed.bool;
  var and = Ember.computed.and;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['list-feed-item', 'content-card'],
    classNameBindings: ['isQuestion:list-feed-question', 'isSharedLink:list-feed-shared-link', 'isStatusUpdate:list-feed-status-update', 'isInvitation:list-feed-invitation', 'isJobPreferenceWidget:list-feed-job-preference-widget', 'isAutoUpdateCard:auto-update-root', 'isRallypointSurvey:list-feed-rallypoint-survey-widget', 'highlighted', 'isHidden'],

    feed: null,
    location: null,
    position: null,
    showReason: false,
    reasonProfile: null,
    isHidden: false,
    disableCommandPostHeader: false,

    store: service(),

    highlighted: and('isLoaded', 'shouldHighlight'),
    isQuestion: bool('feed.list_feed_content.is_question'),
    isRallypointSurvey: bool('feed.list_feed_content.is_rallypoint_survey'),
    isSharedLink: bool('feed.list_feed_content.is_shared_link'),
    isStatusUpdate: bool('feed.list_feed_content.is_status_update'),
    isOtherFeedItemType: bool('feed.list_feed_content.isOtherFeedItemType'),
    isSponsoredPost: and('feed.list_feed_content.list_question.sponsored_post', 'notExternalSponsoredPost'),
    isExternalSponsoredPost: notEmpty('feed.list_feed_content.list_question.sponsored_post_url'),
    isLoaded: or('feed.list_feed_content.list_question.isLoaded', 'feed.list_feed_content.list_status_update.isLoaded', 'feed.list_feed_content.list_shared_link.isLoaded'),
    isLoading: not('isLoaded'),
    isVisible: not('feed.isDeleted'),
    notExternalSponsoredPost: not('isExternalSponsoredPost'),
    isJobPreferenceWidget: equal('feedComponentName', 'feed-job-preferences'),
    isAutoUpdateCard: bool('feed.list_feed_content.isAutoUpdateCard'),
    feedComponentName: alias('feed.list_feed_content.feedComponentName'),

    listFeedComponent: computed('isQuestion', 'isRallypointSurvey', 'isSharedLink', 'isStatusUpdate', 'isSponsoredPost', 'isOtherFeedItemType', 'feedComponentName', function () {
      if (get(this, 'isSponsoredPost')) {
        return 'list-feed-sponsored-post';
      } else if (get(this, 'isExternalSponsoredPost')) {
        return 'list-feed-external-sponsored-post';
      } else if (get(this, 'isRallypointSurvey')) {
        return 'list-feed-rallypoint-survey';
      } else if (get(this, 'isQuestion')) {
        return 'list-feed-question';
      } else if (get(this, 'isSharedLink')) {
        return 'list-feed-shared-link';
      } else if (get(this, 'isStatusUpdate')) {
        return 'list-feed-status-update';
      } else if (get(this, 'isOtherFeedItemType')) {
        return get(this, 'feedComponentName');
      }

      (false && !(get(this, 'feed.isDeleted')) && Ember.assert('Unknown feed type.  Cannot map to component', get(this, 'feed.isDeleted')));

      return 'list-feed-question';
    }),

    isInvitation: computed('feedComponentName', function () {
      return get(this, 'feedComponentName') === 'invite-friends-cta';
    }),

    actions: {
      hideFeedItem: function hideFeedItem(listFeedContent) {
        this.toggleProperty('isHidden');
        var ticket = get(this, 'store').createRecord('hide-feed-item-ticket', {
          id: get(listFeedContent, 'id'),
          list_feed_content_type: get(listFeedContent, 'constructor.modelName'),
          hide_key: get(listFeedContent, 'hide_key')
        });
        ticket.save();
      }
    }
  });
});