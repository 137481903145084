define('rp-common/models/suggested-social-invite', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    list_profile_id: belongsTo('profile', {
      async: true
    }),

    first_name: attr('string'),
    last_name: attr('string'),
    image_file_name: attr('string'),
    email: attr('string')
  });
});