define('rp-common/services/short-link-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var resolve = Ember.RSVP.resolve;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({
    apiService: service(),
    ui: service('ui'),

    generate: function generate(fullModel) {
      var shortUrlAttr = fullModel.get('shortUrlAttribute');
      if (fullModel.get(shortUrlAttr)) {
        return resolve(fullModel.get(shortUrlAttr));
      }

      return this.getShortUrl(fullModel.get('shortUrlApi'), shortUrlAttr, fullModel.get('shortUrlApiVersion')).then(function (shortUrl) {
        fullModel.set(shortUrlAttr, shortUrl);
        return shortUrl;
      }).catch(get(this, 'ui.handleError'));
    },
    getShortUrl: function getShortUrl(shortUrlApi, shortUrlAttribute, shortUrlApiVersion) {
      return this.get('apiService').postRequest(shortUrlApi, {}, shortUrlApiVersion).then(function (response) {
        return response[shortUrlAttribute];
      }).catch(get(this, 'ui.handleError'));
    }
  });
});