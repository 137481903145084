define('rp-common/models/list-feed-invitation', ['exports', 'rp-common/models/list-feed-content'], function (exports, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listFeedContent.default.extend({
    isOtherFeedItemType: true,
    feedComponentName: 'invite-friends-cta'
  });
});