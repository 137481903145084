define('rp-common/components/confetti-time/component', ['exports', 'rp-common/components/confetti-time/template', 'ember-concurrency'], function (exports, _template, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;


  var CONFETTI_FREQUENCY = 3;
  var CONFETTI_LIFESPAN = 1500;
  var CONFETTI_COLORS = ['red', 'white', 'blue'];
  var CONFETTI_ANIMATIONS = ['slow', 'medium', 'fast'];
  var CONFETTI_SIZE_MULTIPLIER = 3;
  var CONFETTI_SIZE_OFFSET = 7;
  var CONFETTI_SIZE_OFFSET_MOBILE = 4;

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['confetti-time'],
    fading: false,

    environment: service(),

    didInsertElement: function didInsertElement() {
      get(this, 'loopConfettiTask').perform(this.$()[0]);
      this._super.apply(this, arguments);
    },


    renderConfettiTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(containerEl) {
      var confettiSizeOffset, confettiEl, confettiSize, confettiBackground, confettiLeft, confettiAnimation;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!get(this, 'fading')) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              confettiSizeOffset = get(this, 'environment.isDesktop') ? CONFETTI_SIZE_OFFSET : CONFETTI_SIZE_OFFSET_MOBILE;
              confettiEl = document.createElement('div');
              confettiSize = Math.floor(Math.random() * CONFETTI_SIZE_MULTIPLIER) + confettiSizeOffset + 'px';
              confettiBackground = CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)];
              confettiLeft = Math.floor(Math.random() * containerEl.offsetWidth) + 'px';
              confettiAnimation = CONFETTI_ANIMATIONS[Math.floor(Math.random() * CONFETTI_ANIMATIONS.length)];


              confettiEl.classList.add('confetti');
              confettiEl.classList.add('confetti--animation-' + confettiAnimation);
              confettiEl.style.left = confettiLeft;
              confettiEl.style.width = confettiSize;
              confettiEl.style.height = confettiSize;
              confettiEl.style.backgroundColor = confettiBackground;
              containerEl.appendChild(confettiEl);

              _context.next = 17;
              return (0, _emberConcurrency.timeout)(CONFETTI_LIFESPAN);

            case 17:
              containerEl.removeChild(confettiEl);
              confettiEl = null;

            case 19:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    loopConfettiTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(containerEl) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (get(this, 'environment').getConfig('confettiEnabled')) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return');

            case 2:
              get(this, 'renderConfettiTask').perform(containerEl);
              _context2.next = 5;
              return (0, _emberConcurrency.timeout)(CONFETTI_FREQUENCY);

            case 5:
              get(this, 'loopConfettiTask').perform(containerEl);

            case 6:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))
  });
});