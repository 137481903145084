define('ember-islands/utils/reconciler', ['exports', 'ember'], function (exports, _ember) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var guidFor = _ember['default'].guidFor;

  var Reconciler = (function () {
    /**
     * @param {{[elementGuid: string]: Function}} callbacks
     */

    function Reconciler(callbacks) {
      _classCallCheck(this, Reconciler);

      this.renderedComponents = {};
      this.callbacks = callbacks;
    }

    _createClass(Reconciler, [{
      key: 'reconcileAgainst',
      value: function reconcileAgainst(componentsToRender) {
        var _this = this;

        var nextRenderedComponents = {};

        componentsToRender.forEach(function (ic) {
          var elementGuid = guidFor(ic.element);

          var renderedIC = _this.renderedComponents[elementGuid];
          if (renderedIC) {
            if (renderedIC.name === ic.name) {
              ic.instance = renderedIC.instance;
              _this.callbacks.update(ic);
            } else {
              _this.callbacks.destroy(renderedIC.instance);
              ic.instance = _this.callbacks.initialRender(ic);
            }
          } else {
            ic.instance = _this.callbacks.initialRender(ic);
          }

          nextRenderedComponents[elementGuid] = ic;
        });

        for (var key in this.renderedComponents) {
          if (!(key in nextRenderedComponents)) {
            this.callbacks.destroy(this.renderedComponents[key].instance);
          }
        }

        this.renderedComponents = nextRenderedComponents;
      }
    }, {
      key: 'renderedComponentsAsArray',
      value: function renderedComponentsAsArray() {
        var result = [];
        for (var key in this.renderedComponents) {
          result.push(this.renderedComponents[key].instance);
        }

        return result;
      }
    }, {
      key: 'forEachRenderedComponent',
      value: function forEachRenderedComponent(callback) {
        for (var key in this.renderedComponents) {
          callback(this.renderedComponents[key].instance);
        }
      }
    }]);

    return Reconciler;
  })();

  exports['default'] = Reconciler;
});