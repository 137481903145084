define('rp-common/components/tracking-pixels/ad-impression/component', ['exports', 'rp-common/components/tracking-pixels/ad-impression/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;


  var TIMESTAMP = '[timestamp]';

  exports.default = Component.extend({
    layout: _template.default,
    tagName: '',
    pixelSrc: null,

    imgSrc: computed('pixelSrc', function () {
      return get(this, 'pixelSrc').replace(TIMESTAMP, Date.now());
    })
  });
});