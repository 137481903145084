define('rp-common/models/endorsement', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    endorser: _emberData.default.belongsTo('profile', { async: true }),
    endorsee: _emberData.default.belongsTo('profile', { async: true }),

    // attributes
    leadership_mark: _emberData.default.attr('boolean', { defaultValue: false }),
    performance_in_garrison_mark: _emberData.default.attr('boolean', { defaultValue: false }),
    performance_while_deployed_mark: _emberData.default.attr('boolean', { defaultValue: false }),
    review: _emberData.default.attr('string')
    // computed
  });
});