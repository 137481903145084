define('rp-common/models/list-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;


  var BRANCH_MAP = {
    'Army': 'USA',
    'Army National Guard': 'ARNG',
    'Army Reserve': 'USAR',
    'Navy': 'USN',
    'Navy Reserve': 'USNR',
    'Marine Corps': 'USMC',
    'Marine Corps Reserve': 'USMCR',
    'Air Force': 'USAF',
    'Air National Guard': 'ANG',
    'Air Force Reserve': 'AFRC',
    'Coast Guard': 'USCG',
    'Coast Guard Reserve': 'USCGR',
    'USMA': 'USMA',
    'USNA': 'USNA',
    'USAFA': 'USAFA',
    'Army ROTC': 'AROTC',
    'Navy ROTC': 'NROTC',
    'Air Force ROTC': 'AFROTC'
  };

  var CIV_SPEC_MAP = {
    'Military Family': 'Family',
    'DoD Civilian': 'DoD Civ',
    'US Gov (Non-DOD) Civ': 'Gov Civ',
    'Civilian Recruiter': 'Recruiter',
    'Civilian Supporter': 'Supporter',
    'Potential Recruit': 'Recruit'
  };

  exports.default = _emberData.default.Model.extend({
    profile: _emberData.default.belongsTo('profile', { async: true }),
    potential_connection: _emberData.default.belongsTo('potential_connection', { async: true }),

    image: _emberData.default.attr('string'),
    image_feed: alias('image'),
    image_medium: _emberData.default.attr('string'),
    rank_id: _emberData.default.attr('string'),
    rank: _emberData.default.attr('string'),
    full_name: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    user_type: _emberData.default.attr('string'),
    is_retired: _emberData.default.attr('boolean'),
    verified: _emberData.default.attr('boolean'),
    blocks_me: _emberData.default.attr('boolean'),
    blocked_by_me: _emberData.default.attr('boolean'),
    followed: _emberData.default.attr('boolean'),
    branch_name: _emberData.default.attr('string'),
    speciality_name: _emberData.default.attr('string'),

    is_intervention_group_member: _emberData.default.attr('boolean'),

    isCivilian: equal('user_type', 'Civilian'),
    isServicemember: equal('user_type', 'Servicemember'),
    isVeteran: equal('user_type', 'Veteran'),
    blocked: or('blocks_me', 'blocked_by_me'),

    branchAbbr: computed('branch_name', function () {
      return BRANCH_MAP[this.get('branch_name')];
    }),

    civilianSpecialityAbbr: computed('speciality_name', function () {
      return CIV_SPEC_MAP[this.get('speciality_name')];
    }),

    specialityNameWithoutCode: computed('speciality_name', function () {
      return this.get('speciality_name').replace(/^[^ :]+: /, '');
    }),

    fullNameAndRank: computed('full_name', 'rank', 'isCivilian', function () {
      var fullName = this.get('full_name');
      var rank = this.get('isCivilian') ? '' : this.get('rank');
      if (fullName && rank) {
        return rank + ' ' + fullName;
      }

      return rank || fullName;
    })
  });
});