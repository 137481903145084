define('rp-common/serializers/conversation', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    serialize: function serialize(record, options) {
      var json = this._super(record, options);
      json.message = { body: record.attr('initialMessageBody') };
      json.all_recipient_ids = record.attr('initialRecipients');
      return json;
    },
    extractMeta: function extractMeta(store, type, payload) {
      var meta = get(payload, 'meta');
      return meta && meta.length ? meta[0] : meta;
    }
  });
});