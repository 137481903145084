define('rp-common/components/image-srcset/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: 'img',
    attributeBindings: ['imgSrc:src', 'srcset'],
    src: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.possibleSizes = ['@1x', '@2x', '@3x'];
    },


    config: computed(function () {
      return getOwner(this).resolveRegistration('config:environment');
    }),

    assetMap: service('asset-map'),

    imgSrc: computed('src', 'extension', function () {
      var ext = get(this, 'extension');

      if (get(this, 'config.APP.APP_MODE') === 'app') {
        return get(this, 'src').replace(ext, '' + get(this, 'possibleSizes')[0] + ext);
      }

      return get(this, 'assetMap').resolve(get(this, 'src').replace(ext, '' + get(this, 'possibleSizes')[0] + ext));
    }),

    srcset: computed('src', 'extension', 'possibleSizes', function () {
      var _this = this;

      var ext = get(this, 'extension');
      var src = get(this, 'src');

      return get(this, 'possibleSizes').map(function (size) {
        if (get(_this, 'config.APP.APP_MODE') === 'app') {
          return src.replace(ext, '' + size + ext) + ' ' + size.substr(1);
        }

        return get(_this, 'assetMap').resolve(src.replace(ext, '' + size + ext)) + ' ' + size.substr(1);
      });
    }),

    extension: computed('src', function () {
      var src = get(this, 'src');
      return src.substr(src.lastIndexOf('.'));
    })
  });
});