define('rp-common/components/feed-reason/component', ['exports', 'rp-common/components/feed-reason/template', 'rp-common/models/list-feed-content'], function (exports, _template, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-reason'],
    feedItem: null, // a list-feed-content record of some sort
    listFeed: null, // a list-feed record
    profile: null,
    loading: false,

    listFeedReason: computed('listFeed.reason', 'feedItem.reason', function () {
      // let any reason attached to a list-feed take precedence over a feedItem, allowing
      // differing reasons for one post to be shown on different feed types.
      return get(this, 'listFeed.reason') || get(this, 'feedItem.reason');
    }),

    ownPostReason: equal('listFeedReason', _listFeedContent.OWN_POST_REASON),
    mentionedReason: equal('listFeedReason', _listFeedContent.YOU_WERE_MENTIONED),
    contactCreatedReason: equal('listFeedReason', _listFeedContent.CONTACT_AUTHOR_REASON),
    groupReason: equal('listFeedReason', _listFeedContent.GROUP_REASON),
    contactTouchedReason: equal('listFeedReason', _listFeedContent.CONTACT_PARTICIPANT_REASON),
    contactAndGroupReason: equal('listFeedReason', _listFeedContent.CONTACT_AUTHOR_AND_GROUP_REASON),
    ownPostAndGroupReason: equal('listFeedReason', _listFeedContent.OWN_POST_AND_GROUP),
    profilePostedReason: equal('listFeedReason', _listFeedContent.PROFILE_POSTED),
    profileParticipatedReason: equal('listFeedReason', _listFeedContent.PROFILE_PARTICIPATED),
    profileMentionedReason: equal('listFeedReason', _listFeedContent.PROFILE_MENTIONED),
    participants: alias('feedItem.primary_contact_participants'),
    groups: alias('feedItem.followed_groups')
  });
});