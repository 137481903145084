define('rp-common/models/experience', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', { async: true }),
    unit: _emberData.default.belongsTo('unit', { async: true }),
    duty_station: _emberData.default.belongsTo('duty-station', { async: true }),
    duty_assignment: _emberData.default.belongsTo('duty-assignment', { async: true }),

    // attributes
    experience_type: _emberData.default.attr('string'),
    current: _emberData.default.attr('boolean'),
    duty_assignment_name: _emberData.default.attr('string'),
    duty_station_name: _emberData.default.attr('string'),
    unit_name: _emberData.default.attr('string'),
    start_month: _emberData.default.attr('number'),
    start_year: _emberData.default.attr('number'),
    end_month: _emberData.default.attr('number'),
    end_year: _emberData.default.attr('number'),
    image_url: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    industry_name: _emberData.default.attr('string'),
    full_date: _emberData.default.attr('string'),

    // computed
    duty_station_id: readOnly('duty_station.id'),
    duty_assignment_id: readOnly('duty_assignment.id')
  });
});