define('rp-common/adapters/profile-additions/base', ['exports', 'rp-common/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // need to override it as ember data for hasMany asociation which has only one item in array use find instead of findMany,
    // and our backend not support show action
    // in future better to remove this, and all related adapters and fix it both here and server side
    find: function find(store, type, id) {
      return this.ajax(this.buildURL(type.modelName), 'GET', { data: { ids: id } });
    }
  });
});