define('rp-common/components/feed-more-menu/component', ['exports', 'rp-common/components/feed-more-menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default
  });
});