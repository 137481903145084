define('rp-common/utils/reputation-library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'contact_request_connected': 10,
    'contact_request_connected_over_250': 5,
    'contact_request_connected_over_500': 2,
    'contact_request_rejected': -30,
    'contact_request_ignored': -10,
    'contact_request_deleted': -10,
    'contact_with_new_specialty': 3,
    'contact_with_new_duty_station': 3,
    'created_invitation': 5,
    'created_invitation_over_500': 3,
    'created_invitation_over_1000': 1,
    'created_invitation_over_2500': 0,
    'created_mention_in_post': 2,
    'referral_converted': 300,
    'invitation_converted': 300,
    'invitation_verified': 700,
    'verified_invitation_conversion': 700,
    'verified_referral_conversion': 700,
    'created_endorsement': 20,
    'received_endorsement': 200,
    'contacts_in_all_branches': 200,
    'contacts_in_5_pay_grades_sm': 200,
    'contacts_in_10_pay_grades_sm': 500,
    'contacts_in_5_industries_vet': 200,
    'contacts_in_10_industries_vet': 500,
    'post_question': 15,
    'post_shared_link': 15,
    'post_status_update': 15,
    'post_response': 10,
    'post_comment': 3,
    'voted_on_QR': 3,
    'have_QR_voted_up': 50,
    'have_QR_voted_up_over_1000': 40,
    'have_QR_voted_up_over_2000': 30,
    'have_QR_voted_down': -30,
    'mentioned_in_post': 5,
    'share_response': 10,
    'share_question': 15,
    'received_response': 2,
    'received_comment': 2,
    'profile_complete_40': 100,
    'profile_complete_75': 500,
    'profile_complete_90': 1000,
    'profile_complete_100': 2000,
    'visited_pymk': 10,
    'visited_own_profile': 10,
    'visited_duty_station': 10,
    'visited_unit': 10,
    'visitied_QR': 10,
    'visited_RPU': 30,
    'visited_map': 30,
    'verified': 250,
    'blue_ribbon': 1000,
    'create_profile': 0,
    'visited_advanced_search': 10,
    'has_sent_message': 10,
    'nice_response': 250,
    'good_response': 1000,
    'great_response': 2500,
    'nice_question': 250,
    'good_question': 1000,
    'great_question': 2500,
    'trail_blazer': 100,
    'thought_leader': 2500,
    'command_post': 2500,
    'unvote': -3,
    'awarded_by_admin': 0,
    'hide_response': 0,
    'hide_comment': 0,
    'hide_question': 0,
    'unhide_response': 0,
    'unhide_comment': 0,
    'unhide_question': 0,
    'unclose_duplicate': -15,
    'close_duplicate': 15,
    'unmerge_duplicate': 0,
    'merge_duplicate': 0,
    'suspend_user': 5,
    'undo_suspend_user': -5,
    'award_blue_ribbon': 50,
    'undo_award_blue_ribbon': -50,
    'remove_profile_photo': 5,
    'edit_tags': 10,
    'undo_edit_tags': -10,
    'edit_question_title': 3,
    'undo_edit_question_title': -3,
    'edit_question_body': 3,
    'undo_edit_question_body': -3,
    'adjusted_by_admin': 0,
    'upload_group_canvas': 100,
    'upload_group_logo': 100,
    'verify_account': 250
  };
});