define('rp-common/services/trackers/mobile-page-view', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var MOBILE = 'mobile_page_view';

  exports.default = Service.extend({
    category: MOBILE,
    aggregator: service('trackers/aggregator'),

    // We want pageviews to fire immediately to be consistent with Google Analytics and to
    // prevent misses (particularly on desktop)
    trackPageView: function trackPageView(url) {
      var referrer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      get(this, 'aggregator').track(_aggregator.PAGE_VIEW, [{ type: get(this, 'category'), url: url, referrer: referrer }], immediate);
    }
  });
});