define('ember-islands/utils/get-render-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = getRenderComponent;
  var getOwner = _ember['default'].getOwner;
  var Component = _ember['default'].Component;
  var Logger = _ember['default'].Logger;
  var $ = _ember['default'].$;

  function getRenderComponent(emberObject) {
    var owner = getOwner(emberObject);

    return function renderComponent(_ref) {
      var name = _ref.name;
      var attrs = _ref.attrs;
      var element = _ref.element;

      var _lookupComponent = lookupComponent(owner, name);

      var component = _lookupComponent.component;
      var layout = _lookupComponent.layout;

      _ember['default'].assert(missingComponentMessage(name), component);

      // This can only be true in production mode where assert is a no-op.
      if (!component) {
        var _provideMissingComponentInProductionMode = provideMissingComponentInProductionMode(owner, name);

        component = _provideMissingComponentInProductionMode.component;
        layout = _provideMissingComponentInProductionMode.layout;
      }

      if (layout) {
        attrs.layout = layout;
      }

      $(element).empty();
      var componentInstance = component.create(attrs);
      componentInstance.appendTo(element);

      return componentInstance;
    };
  }

  function lookupComponent(owner, name) {
    var componentLookupKey = 'component:' + name;
    var layoutLookupKey = 'template:components/' + name;
    var layout = owner.lookup(layoutLookupKey);
    var component = owner.factoryFor(componentLookupKey);

    if (layout && !component) {
      owner.register(componentLookupKey, Component);
      component = owner.factoryFor(componentLookupKey);
    }

    return { component: component, layout: layout };
  }

  function missingComponentMessage(name) {
    return 'ember-islands could not find a component named "' + name + '" in your Ember application.';
  }

  function provideMissingComponentInProductionMode(owner, name) {
    Logger.error(missingComponentMessage(name));

    return lookupComponent(owner, 'ember-islands/missing-component');
  }
});