define('rp-common/services/invitation-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var $ = Ember.$;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    environment: service(),

    create: function create(profile) {
      return (get(this, 'environment').getConfig('RP_HOST') || window.location.host) + '/join/' + get(profile, 'slug');
    },
    createFacebookDialogLink: function createFacebookDialogLink(link) {
      var facebookAppId = get(this, 'environment').getConfig('torii.providers.facebook-connect.appId');
      return 'https://www.facebook.com/dialog/send?app_id=' + facebookAppId + '&link=' + link;
    },
    createEmailLink: function createEmailLink(message) {
      return 'mailto:?' + message;
    },
    createRegistrationLink: function createRegistrationLink(queryParams) {
      return (get(this, 'environment').getConfig('RP_HOST') || window.location.host) + '/registrations/step0?' + encodeURIComponent($.param(queryParams));
    }
  });
});