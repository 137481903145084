define('rp-common/services/starburst', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var $ = Ember.$;
  var Service = Ember.Service;


  var STAR_COLORS = ['silver', 'gold'];
  var ANIMATION_DURATION = 1000;
  var STAR_OFFSET = 10;
  var STARBURST_RADIUS = 90;
  var RADIAN = 2 * Math.PI;
  var MAX_STAR_ROTATION = 400;
  var RELEASE_TIME_RANGE_MS = 900;
  var NUM_STARS = 60;
  var TIME_BETWEEN_INSERT_AND_ANIMATION_MS = 10;
  var MAX_ANIMATION_DURATION = RELEASE_TIME_RANGE_MS + ANIMATION_DURATION;

  function removeButtonClone(buttonClone, targetButton) {
    buttonClone.remove();
    targetButton.style.visibility = 'visible';
  }

  function createButtonClone(targetButton) {
    var buttonClone = targetButton.cloneNode(true);
    var originCoords = targetButton.getBoundingClientRect();
    buttonClone.style.left = originCoords.left + 'px';
    buttonClone.style.top = originCoords.top + 'px';
    buttonClone.style.width = targetButton.offsetWidth + 'px';
    buttonClone.style.height = targetButton.offsetHeight + 'px';
    buttonClone.style.margin = '0px';
    buttonClone.style.position = 'absolute';
    return buttonClone;
  }

  exports.default = Service.extend({

    deferredCleanupTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(buttonClone, starCanvas, targetButton) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(MAX_ANIMATION_DURATION);

            case 2:
              if (buttonClone) {
                removeButtonClone(buttonClone, targetButton);
              }
              starCanvas.remove();

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    makeStarTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(targetButton, idx, delayMS) {
      var star, originCoords, starCanvas;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return (0, _emberConcurrency.timeout)(delayMS);

            case 2:
              star = document.createElement('i');
              // recalc coords for each star in case of scroll

              originCoords = targetButton.getBoundingClientRect();
              starCanvas = $('.star-field');

              if (starCanvas.length) {
                _context2.next = 7;
                break;
              }

              return _context2.abrupt('return');

            case 7:

              star.innerHTML = 'star';
              star.classList.add('material-icons');
              star.classList.add('star');
              star.style.color = STAR_COLORS[Math.floor(Math.random() * STAR_COLORS.length)];
              star.starIndex = idx;
              star.originCoords = originCoords;
              star.burstOffsetLeft = star.originCoords.left - STAR_OFFSET + Math.floor(targetButton.offsetWidth / 2);
              star.burstOffsetTop = star.originCoords.top - STAR_OFFSET + Math.floor(targetButton.offsetHeight / 2);
              star.style.left = star.burstOffsetLeft + 'px';
              star.style.top = star.burstOffsetTop + 'px';
              starCanvas[0].append(star);

              get(this, 'moveStarTask').perform(star);
              get(this, 'removeStarTask').perform(star);
              star = null;
              starCanvas = null;
              originCoords = null;

            case 23:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    moveStarTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(star) {
      var angle, left, top, rotation;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return (0, _emberConcurrency.timeout)(TIME_BETWEEN_INSERT_AND_ANIMATION_MS);

            case 2:
              angle = star.starIndex % RADIAN;
              left = star.burstOffsetLeft + STARBURST_RADIUS * Math.sin(angle);
              top = star.burstOffsetTop + STARBURST_RADIUS * Math.cos(angle);
              rotation = Math.random() * MAX_STAR_ROTATION;

              star.style.left = left + 'px';
              star.style.top = top + 'px';
              star.style.transform = 'rotate(' + rotation + 'deg)';
              star.style.opacity = 0;

            case 10:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    removeStarTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(star) {
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return (0, _emberConcurrency.timeout)(ANIMATION_DURATION);

            case 2:
              star.remove();

            case 3:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })),

    // This is a slightly complex animation - it
    // creates a canvas over the screen, and swaps
    // out the target button with a clone so z-index
    // can be used for layering. Coordinates for positioning
    // the stars and button clone are calculated based
    // on coordinates of the target button. If user scrolls
    // original button is immediately swapped back in since
    // the clone has position: fixed.
    //
    // One issue is making sure the button clone has
    // the same css styling applied, since it is removed
    // from its normal context. This is likely the problem
    // if target button's appearance changes  during the
    // animation.
    //
    // Another unsolved issue is the Blink rendering engine
    // delaying the animation if the page is still loading and user
    // scrolls
    animateTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(targetButton) {
      var starCanvas, buttonClone, i, delay, passive, once, remButton;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return (0, _emberConcurrency.timeout)(TIME_BETWEEN_INSERT_AND_ANIMATION_MS);

            case 2:
              starCanvas = document.createElement('div');

              starCanvas.classList.add('star-field');
              $('body').append(starCanvas);

              buttonClone = createButtonClone(targetButton);

              starCanvas.append(buttonClone);
              targetButton.style.visibility = 'hidden';

              for (i = 0; i < NUM_STARS; i++) {
                // Stars always move in a predictable way, but initiation time per star is random
                // This approach makes the animation more symmetrical.
                delay = Math.random() * RELEASE_TIME_RANGE_MS;

                get(this, 'makeStarTask').perform(targetButton, i, Math.floor(delay));
              }

              passive = true;
              once = true;
              remButton = removeButtonClone.bind(null, buttonClone, targetButton);

              window.addEventListener('scroll', remButton, { passive: passive, once: once });

              get(this, 'deferredCleanupTask').perform(buttonClone, starCanvas, targetButton);
              _context5.next = 16;
              return (0, _emberConcurrency.timeout)(MAX_ANIMATION_DURATION);

            case 16:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    animate: function animate(targetButton) {
      if (Ember.testing) {
        return;
      }
      return get(this, 'animateTask').perform(targetButton);
    }
  });
});