define('rp-common/adapters/question', ['exports', 'rp-common/adapters/application', 'rp-common/mixins/adapter/feed-item-options'], function (exports, _application, _feedItemOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_feedItemOptions.default, {
    modelName: 'Question',
    service: 'questions',

    follow: function follow(question) {
      var id = question.get('id');
      var urlParts = [this.urlForUpdateRecord(id, 'questions'), 'follow'];
      return this.ajax(urlParts.join('/'), 'POST');
    },
    unfollow: function unfollow(question) {
      var id = question.get('id');
      var urlParts = [this.urlForUpdateRecord(id, 'questions'), 'unfollow'];
      return this.ajax(urlParts.join('/'), 'DELETE');
    }
  });
});