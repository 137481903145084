define('rp-common/components/rp-button/component', ['exports', 'rp-common/components/rp-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['rp-button'],
    classNameBindings: ['primary', 'success', 'danger', 'primaryOutline', 'disabled', 'small', 'inverted'],
    attributeBindings: ['disabled', 'testSelector:data-test-selector', 'autoId'],
    disabled: false,
    tagName: 'button',
    // Possible types: primary, success, danger, primaryOutline
    type: null,
    // Possible sizes: small
    size: null,
    inverted: false,
    autoId: null,

    testSelector: null,

    primary: equal('type', 'primary'),
    primaryOutline: equal('type', 'primary-outline'),
    success: equal('type', 'success'),
    danger: equal('type', 'danger'),
    small: equal('size', 'small')
  });
});