define('rp-common/components/google-ad/component', ['exports', 'rp-common/components/google-ad/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BANNER_SLOT_SIZE = exports.SIDEBAR_WITH_LONG_SLOT_SIZE = undefined;
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var DEFAULT_AD_SLOT_SIZE = [300, 250];
  var SIDEBAR_WITH_LONG_SLOT_SIZE = exports.SIDEBAR_WITH_LONG_SLOT_SIZE = [[300, 600], [300, 250], [160, 600]];
  var BANNER_SLOT_SIZE = exports.BANNER_SLOT_SIZE = [728, 90];

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['google-ad'],
    attributeBindings: ['adId:id'],
    slot: null,
    adId: '',
    adUnit: '',
    adSlotSize: DEFAULT_AD_SLOT_SIZE,

    store: service(),

    branch: alias('currentProfile.branch.global_branch_name'),
    currentProfile: alias('currentUser.profile'),
    component: alias('currentProfile.component'),
    gender: alias('currentProfile.gender'),
    userType: alias('currentProfile.user_type'),
    jobSeeker: computed('currentProfile.looking_for_work', function () {
      return (get(this, 'currentProfile.looking_for_work') || false).toString();
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initAdSlot();
    },
    initAdSlot: function initAdSlot() {
      var _this = this;

      if (typeof googletag === 'undefined') {
        return;
      }
      var afterRefreshSlot = function afterRefreshSlot(adSlot) {
        if (get(_this, 'didInsertAdSlots')) {
          get(_this, 'didInsertAdSlots')([adSlot]);
        }
      };
      googletag.cmd.push(function () {
        var slot = googletag.defineSlot(get(_this, 'adUnit'), get(_this, 'adSlotSize'), get(_this, 'adId'));
        set(_this, 'slot', slot);
        if (get(_this, 'currentUser.isSignedIn')) {
          var targetings = get(_this, 'adTargetingParams');
          Object.keys(targetings).forEach(function (key) {
            return slot.setTargeting(key, targetings[key]);
          });
        }
        slot.addService(googletag.pubads());
        googletag.display(get(_this, 'adId'));
        googletag.pubads().refresh([slot], { changeCorrelator: false });
        afterRefreshSlot(slot);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.destroyAdSlot();
      this._super.apply(this, arguments);
    },
    destroyAdSlot: function destroyAdSlot() {
      var _this2 = this;

      var targetings = get(this, 'adTargetingParams');
      var slot = get(this, 'slot');

      if (!slot || typeof googletag === 'undefined') {
        return;
      }

      googletag.cmd.push(function () {
        Object.keys(targetings).forEach(function (key) {
          return slot.clearTargeting(key);
        });
        googletag.destroySlots([slot]);
        set(_this2, 'slot', null);
      });
    },


    adTargetingQueryString: computed('adTargetingParams', function () {
      var _this3 = this;

      var targetings = get(this, 'adTargetingParams');
      var qs = Object.keys(targetings).map(function (key) {
        return [encodeURIComponent(key), encodeURIComponent(_this3.stripIllegal(targetings[key]))].join('=');
      });

      // Yes, double encode per DFP docs (i.e., encode key, encode value, encode key=value&key=value)
      return encodeURIComponent(qs.join('&'));
    }),

    adTargetingParams: computed('specialty', 'payGrade', 'component', 'jobSeeker', 'userType', 'gender', 'branch', 'rankType', 'session', function () {
      var session = get(this, 'session');

      return {
        'score_caregiver': get(this, 'scoreCaregiver'),
        'score_future_1': get(this, 'scoreFuture1'),
        'score_future_2': get(this, 'scoreFuture2'),
        'score_future_3': get(this, 'scoreFuture3'),
        'score_future_4': get(this, 'scoreFuture4'),
        'score_future_5': get(this, 'scoreFuture5'),
        'Specialty': get(this, 'specialty'),
        'pay_grade': get(this, 'payGrade'),
        'component': get(this, 'component'),
        'job_seeker': get(this, 'jobSeeker'),
        'user_type': get(this, 'userType'),
        'branch': get(this, 'branch'),
        'rank_type': get(this, 'rankType'),
        'deployments': session ? JSON.parse(get(session, 'dfp_deployments') || '[]') : null
      };
    }),

    currentRank: computed('currentProfile.rank_id', function () {
      if (get(this, 'currentProfile.rank_id')) {
        return get(this, 'store').peekRecord('rank', get(this, 'currentProfile.rank_id'));
      }
    }),

    payGrade: computed('currentRank', function () {
      var rank = get(this, 'currentRank');

      if (!rank) {
        return '';
      }

      return get(rank, 'pay_grade');
    }),

    rankType: computed('currentRank', function () {
      var rank = get(this, 'currentRank');

      if (!rank) {
        return '';
      }

      return get(rank, 'rankTypeName');
    }),

    scoreCaregiver: computed('currentProfile', function () {
      var profile = get(this, 'currentProfile');

      if (profile) {
        var affinityScore = get(profile, 'affinityScore');
        if (affinityScore) {
          return get(affinityScore, 'formattedScoreCaregiver');
        } else {
          return '';
        }
      } else {
        return '';
      }
    }),

    specialty: computed('currentProfile', function () {
      var profile = get(this, 'currentProfile');

      if (!profile) {
        return '';
      } else if (get(profile, 'isROTC')) {
        return '(Pre-Commission)';
      } else if (!get(profile, 'speciality')) {
        return '';
      } else if (get(profile, 'isCivilian')) {
        return get(profile, 'speciality.name');
      }

      var specialtyName = get(profile, 'speciality.name');
      return specialtyName ? specialtyName.replace(/^[^\(\s]+\s/, '') : specialtyName;
    }),

    session: computed(function () {
      return get(this, 'store').peekRecord('session', 'current');
    }),

    stripIllegal: function stripIllegal(s) {
      s = s || '';
      return s.replace(/["'!+#*~;^()<>\[\]=]/g, '');
    }
  });
});