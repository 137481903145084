define('rp-common/services/trackers/generic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    aggregator: service('trackers/aggregator'),

    trackImpression: function trackImpression(objType, id, location) {
      get(this, 'aggregator').track(objType, [this.serializeEvent(id, location)]);
    },
    trackClick: function trackClick(objType, id, location, position) {
      return get(this, 'aggregator').track(objType, [this.serializeEvent(id, location, 'click', position)], true);
    },
    serializeEvent: function serializeEvent(id, location) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'impression';
      var position = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

      return {
        id: id,
        location: location,
        type: type,
        position: position
      };
    }
  });
});