define('rp-common/services/api-service', ['exports', 'ember-network/fetch', 'rp-common/adapters/application'], function (exports, _fetch, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var isEmpty = Ember.isEmpty;
  var typeOf = Ember.typeOf;
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    config: function config() {
      return getOwner(this).resolveRegistration('config:environment');
    },
    getHeaders: function getHeaders() {
      return (0, _application.getDefaultHeaders)(this.config());
    },
    requestSettings: function requestSettings() {
      return {
        method: 'GET',
        headers: this.getHeaders()
      };
    },
    postRequestSettings: function postRequestSettings() {
      var postHeaders = this.getHeaders();
      postHeaders['Content-Type'] = 'application/json';
      return {
        method: 'POST',
        headers: postHeaders
      };
    },
    destroyRequestSettings: function destroyRequestSettings() {
      var headers = this.getHeaders();
      headers['Content-Type'] = 'application/json';
      return {
        method: 'DELETE',
        headers: headers
      };
    },


    // Treat 404 as an error
    // Consider wrapping in one Ember.RSVP.Promise call
    handleResponse: function handleResponse(response) {
      if (response.status >= 400) {
        var errorMessage = response.statusText;
        return new EmberPromise(function (resolve, reject) {
          response.json().then(function (response) {
            // Error messages are conveyed differently throughout the system
            // we should fix this in API/V2
            if (response.message) {
              errorMessage = response.message;
            }

            if (response.data) {
              if (response.data.errors) {
                errorMessage = response.data.errors;
              }
            }

            if (response.errors && response.errors.length > 0) {
              errorMessage = response.errors[0];
            }
            reject(errorMessage);
          }, function () {
            reject(errorMessage);
          });
        });
      }
      return new EmberPromise(function (resolve) {
        response.json().then(function (response) {
          resolve(response);
        }, function () {
          resolve({});
        });
      });
    },
    getApiPath: function getApiPath(url, namespace) {
      var config = this.config();

      if (!/\/$/.test(namespace)) {
        namespace += '/';
      }
      if (namespace.indexOf('/') !== 0) {
        namespace = '/' + namespace;
      }
      if (config.APP) {
        if (config.APP.APP_MODE === 'app') {
          namespace = '' + config.APP.ENDPOINT + namespace;
        }
      }

      return '' + namespace + url;
    },
    sendRequest: function sendRequest(url, successCallback, errorCallback) {
      var _this = this;

      var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';
      (false && !(!isEmpty(successCallback) && typeOf(successCallback) === 'function') && Ember.assert('successCallback is required and should be a function', !isEmpty(successCallback) && typeOf(successCallback) === 'function'));

      var settings = this.requestSettings();
      settings.method = method;

      var fullPath = '/api/v1/' + url;
      return (0, _fetch.default)(fullPath, settings).then(this.handleResponse).then(this.successCallback).catch(function (error) {
        Ember.Logger.error(error);
        if (!isEmpty(errorCallback) && typeOf(errorCallback) === 'function') {
          errorCallback.call(_this, error);
        }
      });
    },


    // Basic post request with headers
    postRequest: function postRequest(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var namespace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'api/v1';

      var settings = $.extend({}, this.postRequestSettings(), {
        body: JSON.stringify(data)
      });
      var apiPath = this.getApiPath(url, namespace);
      return (0, _fetch.default)(apiPath, settings).then(this.handleResponse);
    },


    // Basic destroy request with headers
    /* eslint-disable no-unused-vars */
    destroyRequest: function destroyRequest(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var namespace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'api/v1';

      var settings = this.destroyRequestSettings();
      var apiPath = this.getApiPath(url, namespace);
      return (0, _fetch.default)(apiPath, settings).then(this.handleResponse);
    },

    /* eslint-enable no-unused-vars */

    postV2Request: function postV2Request(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.postRequest(url, data, 'api/v2');
    },
    destroyV2Request: function destroyV2Request(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.destroyRequest(url, data, 'api/v2');
    },


    // Basic get requset with headers
    getRequest: function getRequest(url) {
      var namespace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'api/v1';

      return (0, _fetch.default)(this.getApiPath(url, namespace), this.requestSettings()).then(this.handleResponse);
    },


    // V2 Get Request
    getV2Request: function getV2Request(url) {
      return this.getRequest(url, 'api/v2');
    }
  });
});