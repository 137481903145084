define('rp-common/models/duty-station', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getProperties = Ember.getProperties;
  var computed = Ember.computed;
  exports.default = _groupable.default.extend({
    state: _emberData.default.attr('string'),
    image_medium: _emberData.default.attr('string'),

    fullName: computed('name', 'state', function () {
      var _getProperties = getProperties(this, 'name', 'state'),
          name = _getProperties.name,
          state = _getProperties.state;

      return state ? name + ', ' + state : name;
    })
  });
});