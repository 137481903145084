define('rp-common/models/response', ['exports', 'ember-data', 'rp-common/mixins/models/picturable'], function (exports, _emberData, _picturable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var mapBy = Ember.computed.mapBy;
  var readOnly = Ember.computed.readOnly;
  exports.default = _emberData.default.Model.extend(_picturable.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', { async: true }),
    parent: _emberData.default.belongsTo('list-qrc-root', { polymorphic: true, async: true }),
    comments: _emberData.default.hasMany('comment', { async: true }),
    pictures: _emberData.default.hasMany('picture', { async: true }),

    // attributes
    slug: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    link_data: _emberData.default.attr(),
    comment_count: _emberData.default.attr('number'),
    up_voted: _emberData.default.attr('boolean'),
    down_voted: _emberData.default.attr('boolean'),
    net_votes: _emberData.default.attr('number'),

    // computed
    commentsCount: alias('comment_count'),
    commentIds: mapBy('comments', 'id'),
    rootId: readOnly('parent.id'),

    anonymous: _emberData.default.attr('boolean', { defaultValue: false })
  });
});