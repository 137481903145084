define('rp-common/experiments/base-profile-experiment', ['exports', 'rp-common/experiments/base-experiment'], function (exports, _baseExperiment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _baseExperiment.default.extend({
    store: service(),

    addToGroup: function addToGroup() {
      var ls = get(this, 'localStorage');
      var numGroups = get(this, 'groups.length');
      (false && !(numGroups === 2) && Ember.assert('There can only be two groups for profile experiments', numGroups === 2));


      var currentSession = get(this, 'store').peekRecord('session', 'current');
      var profileId = parseInt(get(currentSession, 'profile.id'), 10);
      var group = get(this, 'groups')[profileId % 2];
      var lsKey = get(this, 'lsKey');

      this.removeStaleExperimentsFromLocalStorage();
      ls.setItem(lsKey, group);
      return ls.getItem(lsKey);
    }
  });
});