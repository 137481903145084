define('rp-common/models/full-qrc-root', ['exports', 'ember-data', 'rp-common/utils/utils-base', 'rp-common/utils/model-utils', 'rp-common/mixins/models/picturable', 'rp-common/mixins/has-short-link'], function (exports, _emberData, _utilsBase, _modelUtils, _picturable, _hasShortLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var get = Ember.get;
  var computed = Ember.computed;


  // QRC: Question Response Content
  var BaseQRC = _emberData.default.Model.extend(_picturable.default, _hasShortLink.default, {
    pictures: _emberData.default.hasMany('picture', { async: true }),
    list_groups: _emberData.default.hasMany('list-group', { async: true }),
    mentions: _emberData.default.hasMany('list-profile', { async: true, inverse: null }),

    created_at: _emberData.default.attr('date'),
    body: _emberData.default.attr('string'),
    short_group_url: _emberData.default.attr('string'),
    engagement_locked: _emberData.default.attr('boolean'),

    views_count: _emberData.default.attr('number'),
    votes_count: _emberData.default.attr('number'),
    up_voted: _emberData.default.attr('boolean'),
    down_voted: _emberData.default.attr('boolean'),
    net_votes: _emberData.default.attr('number'),
    up_votes: _emberData.default.attr('number'),
    down_votes: _emberData.default.attr('number'),

    r_and_c_count: _emberData.default.attr('number'),

    has_responded: _emberData.default.attr('boolean'),

    link_data: _emberData.default.attr(),

    resource_centers: _emberData.default.attr(),

    high_risk: _emberData.default.attr('boolean'),

    formattedViewsCount: computed('views_count', function () {
      return _utilsBase.default.formatNumber(this.get('views_count'));
    }),

    formattedVotesCount: computed('votes_count', function () {
      return _utilsBase.default.formatNumber(this.get('votes_count'));
    }),

    formattedCommentsCount: computed('r_and_c_count', function () {
      return _utilsBase.default.formatNumber(this.get('r_and_c_count'));
    }),

    inEducationResourceCenter: computed('resource_centers.[]', function () {
      return this.inResourceCenter('education');
    }),

    firstPictureURL: readOnly('pictures.firstObject.url'),
    linkDataPresent: notEmpty('link_data.title'),

    inResourceCenter: function inResourceCenter(centerName) {
      return centerName && get(this, 'resource_centers').includes(centerName);
    }
  });

  BaseQRC.reopen({
    writeModel: '',
    ui: service(),

    reportAsSpam: function reportAsSpam() {
      var self = this;
      var adapter = self.store.adapterFor(this.get('writeModel'));
      var ui = this.get('ui');

      self.set('reported_as_spam', true);
      (0, _modelUtils.cleanProperty)(self, 'reported_as_spam');
      return adapter.reportAsSpam(self).catch(function (err) {
        self.set('reported_as_spam', false);
        (0, _modelUtils.cleanProperty)(self, 'reported_as_spam');
        ui.nativeAlert('Unable to report as spam.', 'Report as Spam Error');
        throw err;
      });
    },
    hideFromUpdates: function hideFromUpdates(hide) {
      var self = this;
      var adapter = self.store.adapterFor(this.get('writeModel'));
      var ui = this.get('ui');

      self.set('hidden_from_updates', hide);
      (0, _modelUtils.cleanProperty)(self, 'hidden_from_updates');
      return adapter.hideFromUpdates(self, hide).catch(function (err) {
        self.set('hidden_from_updates', !hide);
        (0, _modelUtils.cleanProperty)(self, 'hidden_from_updates');
        ui.nativeAlert('Unable to hide from updates.', 'Hide from Updates Error');
        throw err;
      });
    }
  });

  exports.default = BaseQRC;
});