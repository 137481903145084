define('rp-common/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;


  var ACTIVE_COMPONENTS = ['army', 'navy', 'air-force', 'marine-corps', 'coast-guard'];
  var RESERVE_COMPONENTS = ['army-national-guard', 'army-reserve', 'navy-reserve', 'air-national-guard', 'air-force-reserve', 'marine-corps-reserve', 'coast-guard-reserve'];
  var PRE_COMMISSION_COMPONENTS = ['usma', 'usna', 'usafa', 'army-rotc', 'navy-rotc', 'air-force-rotc'];
  var CIVILIAN_COMPONENTS = ['civilian'];
  var ADMIN_ROLES = ['admin', 'manager', 'business_development'];
  var RECRUITER_ROLES = ['trial_recruiter', 'employer_searcher', 'recruiter_searcher', 'super_recruiter', 'premier_recruiter'];
  var JOB_SEEKER_ROLES = ['employer_searcher', 'premier_recruiter', 'recruiter_searcher', 'super_recruiter', 'admin'];
  var OPEN_MESSAGING_ROLES = ['open_messaging'];

  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  var alias = computed.alias;
  exports.default = Model.extend({
    is_contacts_with: alias('connection1st'),

    // has one
    user: belongsTo('user', { async: true }),
    session: belongsTo('session', { async: false }),
    branch: belongsTo('branch', { async: true }),
    speciality: belongsTo('speciality', { async: true }),
    leaderboard: belongsTo('leaderboard', { async: false }),
    currentPromotion: belongsTo('current-promotion', { async: true }),
    currentExperience: belongsTo('current-experience', { async: true }),
    current_location_duty_station: belongsTo('duty-station', { async: true }),
    hometown_duty_station: belongsTo('duty-station', { async: true }),
    gainingUnit: belongsTo('unit', { async: true }),
    temp_profile_photo: belongsTo('temp-profile-photo', { async: false }),
    orig_w: attr('number'),
    orig_h: attr('number'),
    crop_x: attr('number'),
    crop_y: attr('number'),
    crop_w: attr('number'),
    crop_h: attr('number'),

    affinityScore: belongsTo('affinityScore', { async: true }),

    blocks_me: attr('boolean'),
    blocked_by_me: attr('boolean'),
    blocked: or('blocks_me', 'blocked_by_me'),

    // has many
    suggested_social_invites: hasMany('suggested-social-invite', { async: true }),
    experiences: hasMany('experience', { async: true }),
    endorsements: hasMany('endorsement', { async: true, inverse: 'endorsee' }),
    promotions: hasMany('promotion', { async: true }),
    deployments: hasMany('deployment', { async: true }),
    civilian_educations: hasMany('civilian-education', { async: true }),
    professional_development_educations: hasMany('professional-development-education', { async: true }),
    tactical_educations: hasMany('tactical-education', { async: true }),
    certificate_educations: hasMany('certificate-education', { async: true }),
    foreign_language_educations: hasMany('foreign-language-education', { async: true }),
    additional_specialities: hasMany('additional-speciality', { async: true }),
    reputation_activities: hasMany('reputation-activity', { async: true }),
    roles: attr('array'),
    tags: hasMany('profile-tag', {
      async: false
    }),

    // manual associations
    groups: attr(),
    all_group_ids: attr(),
    menu_groups: attr(),
    possible_popular_tags: attr(),
    followed_groups: attr(),

    // attributes
    created_at: attr('date'),
    is_full: attr('boolean'),
    full_name: attr('string'),
    first_name: attr('string'),
    last_name: attr('string'),
    image_small: attr('string'),
    image_feed: attr('string'),
    image_medium: attr('string'),
    is_retired: attr('boolean'),
    // Civilians can be verified. One use case is to verify recruiters that pay for a recruiter seat. Done by an admin.
    verified: attr('boolean'),
    user_type: attr('string'),
    user_premium: attr('boolean'),
    rank: attr('string'),
    rank_id: attr('string'),
    rank_type: attr('number'),
    connection_type: attr('string'),
    component_badge_type: attr('string'),
    has_ribbon: attr('boolean'),
    is_promo_for_conversions: attr('boolean'),
    moderator_level: attr('number'),
    basic_service_entry_date: attr('string'),
    pcs_date: attr('date'),
    pcs_date_month: attr('number'),
    pcs_date_year: attr('number'),
    gaining_unit_name: attr('string'),
    exit_date: attr('date'),
    completeness: attr('number'),
    reputation: attr('number'),
    reputation_percentile: attr('number'),
    reputation_rank: attr('number'),
    reputation_base: attr('number'),
    reputation_paygrade: attr('string'),
    reputation_activity_count: attr('number'),
    reputation_change: attr('number'),
    slug: attr('string'),
    professional_summary: attr('string'),
    security_clearance: attr('string'),
    civilian_experience: attr('string'),
    birthday: attr('date'),
    home_state: attr('string'),
    home_town: attr('string'),
    marital_status: attr('string'),
    interests: attr('string'),
    gender: attr('string'),
    current_location_duty_station_name: attr('string'),
    hometown_duty_station_name: attr('string'),
    contact_count: attr('number'),
    badges: attr(),
    contact_sample_images: attr(),
    endorsement_sample_images: attr(),
    is_endorsed_by_current: attr('boolean'),
    endorsements_privacy_blocked: attr('boolean'),
    has_uploaded_contacts: attr('boolean'),
    looking_for_work: attr('boolean'),
    low_activity: attr('boolean'),
    disability_rating: attr('number'),
    va_healthcare_enrolled: attr('boolean'),
    gi_bill_usage: attr('string'),
    state: attr('string'),
    environment: service(),

    isAnonymous: computed(function () {
      var config = get(this, 'environment').getConfig();
      return config.APP.ANONYMOUS_PROFILE_ID === Number(get(this, 'id'));
    }),
    // computed

    contacts_privacy_blocked: readOnly('endorsements_privacy_blocked'),

    fullNameAndRank: computed('full_name', 'rank', 'isCivilian', function () {
      var fullName = get(this, 'full_name');
      var rank = get(this, 'isCivilian') ? '' : get(this, 'rank');
      if (fullName && rank) {
        return rank + ' ' + fullName;
      } else {
        return rank || fullName;
      }
    }),

    groupsSampleImages: computed('groups', function () {
      var groups = get(this, 'groups');
      var groupsSampleImages = [];
      if (groups) {
        groups.forEach(function (group, ndx) {
          var si = group.group_image;
          if (ndx < 5 && si) {
            groupsSampleImages.pushObject({ id: ndx, src: si });
          }
        });
      }
      return groupsSampleImages;
    }),

    filtered_contact_sample_images: computed('contact_sample_images', function () {
      try {
        return get(this, 'contact_sample_images').filter(function (img) {
          return !~img.indexOf('default/avatar_small.png');
        });
      } catch (e) {
        return [];
      }
    }),

    filtered_endorsement_sample_images: computed('endorsement_sample_images', function () {
      try {
        return get(this, 'endorsement_sample_images').filter(function (img) {
          return !~img.indexOf('default/avatar_small.png');
        });
      } catch (e) {
        return [];
      }
    }),

    componentVU: equal('component_badge_type', 'vu'),
    componentV: equal('component_badge_type', 'v'),
    componentRU: equal('component_badge_type', 'ru'),
    componentR: equal('component_badge_type', 'r'),
    componentSMU: equal('component_badge_type', 'smu'),
    componentSM: equal('component_badge_type', 'sm'),
    componentC: equal('component_badge_type', 'c'),

    premierRecruiter: computed('roles', function () {
      var roles = get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.indexOf('premier_recruiter') !== -1;
    }),

    top10Badge: computed('reputation_percentile', function () {
      var p = get(this, 'reputation_percentile');
      return p >= 90 && p < 95;
    }),

    top5Badge: computed('reputation_percentile', function () {
      var p = get(this, 'reputation_percentile');
      return p >= 95 && p < 99;
    }),

    top1Badge: computed('reputation_percentile', function () {
      return get(this, 'reputation_percentile') >= 99;
    }),

    topXBadge: computed('top10Badge', 'top5Badge', 'top1Badge', function () {
      if (get(this, 'top10Badge')) {
        return 'top-10-icon';
      } else if (get(this, 'top5Badge')) {
        return 'top-5-icon';
      } else if (get(this, 'top1Badge')) {
        return 'top-1-icon';
      } else {
        return null;
      }
    }),

    moderatorStars: computed('moderator_level', function () {
      var level = get(this, 'moderator_level');
      var arr = [];
      if (level > 0) {
        for (var i = 0; i < level; i++) {
          arr.pushObject('star');
        }
        return arr;
      } else {
        return null;
      }
    }),

    reputationRank: computed('reputation_rank', 'reputation_base', 'reputation_paygrade', function () {
      return numeral(get(this, 'reputation_rank')).format('0,0') + ' of ' + numeral(get(this, 'reputation_base')).format('0,0') + ' ' + get(this, 'reputation_paygrade');
    }),

    reputationFormatted: computed('reputation', function () {
      return numeral(get(this, 'reputation')).format('0,0');
    }),

    reputationRankShort: computed('reputation_rank', 'reputation_base', 'reputation_paygrade', function () {
      var str = numeral(get(this, 'reputation_rank')).format('0,0') + ' of ';
      var base = get(this, 'reputation_base');
      if (base >= 10000) {
        str += numeral(base).format('1a');
      } else {
        str += numeral(base).format('0,0');
      }
      str += ' ' + get(this, 'reputation_paygrade');
      return str;
    }),

    connection1st: computed('connection_type', function () {
      return get(this, 'connection_type') === 'my_contact';
    }),

    connection2nd: computed('connection_type', function () {
      return get(this, 'connection_type') === 'my_second_order_contact';
    }),

    connection3rd: computed('connection_type', function () {
      return get(this, 'connection_type') === 'my_third_order_contact';
    }),

    connectionPending: computed('connection_type', function () {
      return get(this, 'connection_type') === 'pending';
    }),

    lookingAtSelfInMirror: computed('currentUser.profile.id', 'id', function () {
      return get(this, 'id') === get(this, 'currentUser.profile.id');
    }),

    homeTownAndState: computed('home_town', 'home_state', function () {
      var ht = get(this, 'home_town');
      var hs = get(this, 'home_state');
      return !!ht && !!hs ? ht + ', ' + hs : ht || hs;
    }),

    currentLocation: alias('current_location_duty_station_name'),

    homeTown: alias('hometown_duty_station_name'),

    interestsArr: computed('interests', function () {
      var interests = get(this, 'interests');
      var arr = [];
      if (interests) {
        arr = interests.split(',');
      }
      return arr;
    }),

    endorsementsTotal: computed(function () {
      return get(this, 'endorsements.length');
    }),

    unseenEndorsementsTotal: computed(function () {
      var l = get(this, 'endorsements.length') - 2;
      return l > 0 ? l : 0;
    }),

    firstTwoEndorsements: computed(function () {
      var endorsements = get(this, 'endorsements');
      if (endorsements) {
        return endorsements.slice(0, 2);
      } else {
        return [];
      }
    }),

    moreThanTwoEndorsements: computed('endorsementsTotal', function () {
      return get(this, 'endorsementsTotal') > 2;
    }),

    experiencesTotal: computed(function () {
      return get(this, '_data.experiences.length');
    }),

    formattedBadges: computed('badges', function () {
      var badges = get(this, 'badges');
      if (badges) {
        badges.forEach(function (badge) {
          badge.niceName = badge.name.humanize();
          badge.badgeClass = badge.name.dasherize() + '-badge02';
        });
      }
      return badges;
    }),

    isCivilian: equal('user_type', 'Civilian'),
    isVeteran: equal('user_type', 'Veteran'),
    isServicemember: equal('user_type', 'Servicemember'),
    isReserve: computed('branch.id', function () {
      var branchId = get(this, 'branch.id');
      return RESERVE_COMPONENTS.any(function (item) {
        return item === branchId;
      });
    }),

    isROTC: computed('branch.id', function () {
      var branchId = get(this, 'branch.id');
      return PRE_COMMISSION_COMPONENTS.any(function (item) {
        return item === branchId;
      });
    }),

    isAdmin: computed('roles.[]', function () {
      return $(get(this, 'roles')).is(ADMIN_ROLES);
    }),

    isRecruiter: computed('roles.[]', function () {
      return $(get(this, 'roles')).is(RECRUITER_ROLES);
    }),

    isJobSeeker: computed('roles.[]', function () {
      return $(get(this, 'roles')).is(JOB_SEEKER_ROLES);
    }),

    canMessageAnyone: computed('roles.[]', function () {
      return $(get(this, 'roles')).is(OPEN_MESSAGING_ROLES);
    }),

    component: computed('branch.id', function () {
      var branchId = get(this, 'branch.id');

      if (PRE_COMMISSION_COMPONENTS.any(function (item) {
        return item === branchId;
      })) {
        return 'Pre-Commission';
      } else if (ACTIVE_COMPONENTS.any(function (item) {
        return item === branchId;
      })) {
        return 'Active';
      } else if (RESERVE_COMPONENTS.any(function (item) {
        return item === branchId;
      })) {
        return 'Reserve';
      } else if (CIVILIAN_COMPONENTS.any(function (item) {
        return item === branchId;
      })) {
        return 'Civilian';
      }

      return '';
    }),

    currentCivilianExperience: computed('civilianExperiences', function () {
      return get(this, 'civilianExperiences').filterBy('current', true)[0];
    }),

    civilianExperiences: computed('experiences.[]', function () {
      return get(this, 'experiences').filter(function (e) {
        return e.get('experience_type') === 'Civilian';
      });
    }),

    militaryExperiences: computed('experiences.[]', function () {
      return get(this, 'experiences').filter(function (e) {
        return e.get('experience_type') === 'Military';
      });
    }),

    pcsDateLanguage: computed('branch.id', function () {
      switch (get(this, 'branch.id')) {
        case 'army-reserve':
        case 'air-force-reserve':
        case 'navy-reserve':
        case 'coast-guard-reserve':
        case 'army-national-guard':
        case 'air-national-guard':
        case 'marine-corps-reserve':
          return 'Re-Assignment Date';
        default:
          return 'PCS Date';
      }
    }),

    nameHidden: computed('full_name', function () {
      var fullName = get(this, 'full_name');
      return fullName.match(/anonymous/i) || fullName.match(/join to see/i);
    })
  });
});