define('rp-common/services/trackers/aggregator', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EMERGENCY_PAGE = exports.OFFER_PAGE = exports.OFFER = exports.FEED_CARD_TAG = exports.HOME_FEED = exports.FEATURED_COMPANY_EXPERT = exports.EVENT_INFO = exports.PAGE_VIEW = exports.SPONSORED_TOPIC_AD = exports.TAKEOVER = exports.SPONSORED_POST = exports.QRC = exports.JOB_APPLICATION_EXTERNAL = exports.JOB = exports.ANNOUNCEMENT = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var TIMEOUT_MS = 2000;
  var TRACK_URL = 'tracking';

  var ANNOUNCEMENT = exports.ANNOUNCEMENT = 'announcement';
  var JOB = exports.JOB = 'job';
  var JOB_APPLICATION_EXTERNAL = exports.JOB_APPLICATION_EXTERNAL = 'job_application_external';
  var QRC = exports.QRC = 'qrc';
  var SPONSORED_POST = exports.SPONSORED_POST = 'sponsored_post';
  var TAKEOVER = exports.TAKEOVER = 'takeover';
  var SPONSORED_TOPIC_AD = exports.SPONSORED_TOPIC_AD = 'sponsored_topic_ad';
  var PAGE_VIEW = exports.PAGE_VIEW = 'page_view';
  var EVENT_INFO = exports.EVENT_INFO = 'event_info';
  var FEATURED_COMPANY_EXPERT = exports.FEATURED_COMPANY_EXPERT = 'featured_company_expert';
  var HOME_FEED = exports.HOME_FEED = 'home_feed';
  var FEED_CARD_TAG = exports.FEED_CARD_TAG = 'feed_card_tag';
  var OFFER = exports.OFFER = 'offer';
  var OFFER_PAGE = exports.OFFER_PAGE = 'offers';
  var EMERGENCY_PAGE = exports.EMERGENCY_PAGE = 'emergency_support';

  exports.default = Service.extend({
    cache: computed(function () {
      return {};
    }),

    apiService: service(),
    errorHandlers: service(),

    testEnvironment: computed(function () {
      var config = getOwner(this).resolveRegistration('config:environment');
      return get(config, 'environment') === 'test';
    }),

    track: function track(type, data) {
      var _cache$type;

      var immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (!get(data, 'length')) {
        return;
      }

      var cache = get(this, 'cache');

      if (!cache[type]) {
        cache[type] = [];
      }

      (_cache$type = cache[type]).push.apply(_cache$type, _toConsumableArray(data));

      if (immediate) {
        return get(this, 'sync').perform();
      } else {
        get(this, 'queueSync').perform();
      }
    },


    queueSync: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(get(this, 'testEnvironment') ? 0 : TIMEOUT_MS);

            case 2:

              get(this, 'sync').perform();

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    sync: (0, _emberConcurrency.task)(_emberConcurrency.enqueue, regeneratorRuntime.mark(function _callee2() {
      var cache;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              cache = { data: get(this, 'cache') };

              if (!(Object.keys(cache.data).length === 0)) {
                _context2.next = 3;
                break;
              }

              return _context2.abrupt('return');

            case 3:

              set(this, 'cache', {});
              _context2.next = 6;
              return get(this, 'apiService').postV2Request(TRACK_URL, cache).catch(get(this, 'errorHandlers').reportError.bind(this));

            case 6:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))
  });
});