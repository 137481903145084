define('rp-common/services/session-tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var set = Ember.set;
  var get = Ember.get;


  var FB_PARAMS = {
    value: '0.00',
    currency: 'USD'
  };

  var FB_LOGGED_IN_MEMBER = 'LoggedInMemberVisit';
  var FB_LOGGED_OUT_MEMBER = 'LoggedOutMemberVisit';
  var FB_LEAD = 'Lead';
  var FB_REGISTRATION = 'CompleteRegistration';

  exports.default = Service.extend({
    config: null,

    init: function init() {
      set(this, 'config', getOwner(this).resolveRegistration('config:environment'));
    },
    trackCustomEvent: function trackCustomEvent(eventName) {
      if (!window.fbq) {
        return;
      }

      window.fbq('trackCustom', eventName);
    },
    trackEvent: function trackEvent(eventName) {
      if (!window.fbq) {
        return;
      }

      window.fbq('track', eventName, FB_PARAMS);
    },
    trackMember: function trackMember(loggedIn) {
      this.trackCustomEvent(loggedIn ? FB_LOGGED_IN_MEMBER : FB_LOGGED_OUT_MEMBER);
    },
    trackStep1: function trackStep1() {
      this.trackEvent(FB_LEAD);
    },
    trackRegistration: function trackRegistration() {
      var trackInFb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (get(this, 'config.environment') === 'production') {
        window.ga('send', 'event', 'button', 'click', 'Created an account', 2);
      }

      if (trackInFb && get(this, 'config.APP.APP_MODE') !== 'app') {
        this.trackEvent(FB_REGISTRATION);
      }
    }
  });
});