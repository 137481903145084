define('rp-common/services/deferred-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var set = Ember.set;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  exports.default = Service.extend({

    routing: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('deferredTransition', []);
    },


    // Call this with the same arguments you'd pass to a link-to helper or transitionTo.
    // Alternatively, pass in a Transition object (for use within route-hook contexts).
    setDeferredTransition: function setDeferredTransition() {
      set(this, 'deferredTransition', arguments.length ? arguments : [get(this, 'routing.router.url')]);
    },
    clearDeferredTransition: function clearDeferredTransition() {
      set(this, 'deferredTransition', []);
    },
    tryDeferredTransition: function tryDeferredTransition() {
      var deferred = get(this, 'deferredTransition');
      var hasDeferred = !isEmpty(deferred);
      if (hasDeferred) {
        // if it has a retry method, it is probably a Transition
        var _deferred = _slicedToArray(deferred, 1),
            arg1 = _deferred[0];

        if (arg1 && arg1.retry) {
          arg1.retry();
        } else {
          get(this, 'routing').transitionTo.apply(this, deferred);
        }
      }
      return hasDeferred;
    },


    // convenience function for a common use-case
    signUpThenReturn: function signUpThenReturn() {
      this.setDeferredTransition.apply(this, arguments);
      get(this, 'routing.router').transitionTo('registration.landing');
    },


    // convenience function for a common use-case
    loginThenReturn: function loginThenReturn() {
      this.setDeferredTransition.apply(this, arguments);
      get(this, 'routing.router').transitionTo('session.new');
    }
  });
});