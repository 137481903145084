define('rp-common/services/page-context', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Service.extend({
    groupId: null,

    store: service(),

    listGroup: computed('groupId', function () {
      if (get(this, 'groupId')) {
        return get(this, 'store').find('list-group', get(this, 'groupId'));
      } else {
        return null;
      }
    }),

    onGroupPage: notEmpty('groupId')
  });
});