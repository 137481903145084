define('rp-common/services/geocoder', ['exports', 'ember-network/fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    geocode: function geocode(loc) {
      var uri = 'https://maps.googleapis.com/maps/api/geocode/json?sensor=false&address=' + encodeURIComponent(loc);
      return (0, _fetch.default)(uri).then(function (response) {
        return response.json();
      });
    }
  });
});