define('ember-lazy-video/components/lazy-video', ['exports', 'ember'], function (exports, _ember) {
  var on = _ember['default'].on;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var String = _ember['default'].String;
  exports['default'] = Component.extend({
    isDisplayed: false,
    videoTitle: null,
    url: null,
    classNames: ['lazyLoad-container'],
    attributeBindings: ['style'],
    videoThumbnail: null,
    poster: null,
    providers: inject.service('lazy-video-providers'),

    click: function click() {
      set(this, 'isDisplayed', true);
      this.sendAction('showingVideo');
    },

    videoSrc: computed('url', function () {
      var providers = get(this, 'providers');
      var url = get(this, 'url');
      return providers.getUrl(url, 'embedUrl', { autoplay: 1 });
    }),

    _getVideoThumbnail: on('didInsertElement', function () {
      var _this = this;

      var providers = get(this, 'providers');
      var url = get(this, 'url');
      var poster = get(this, 'poster');

      if (poster) {
        return;
      }

      providers.getThumbnailUrl(url).then(function (res) {
        set(_this, 'videoThumbnail', res);
      });
    }),

    style: computed('videoThumbnail', 'poster', function () {
      var poster = get(this, 'poster');
      var thumbnail = poster || get(this, 'videoThumbnail');
      return String.htmlSafe('background-image: url(' + encodeURI(thumbnail) + ')');
    })
  });
});