define('rp-common/models/unit', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _groupable.default.extend({
    // relationships
    branch: belongsTo('branch', { async: true }),

    // attributes
    full_name: attr('string'),
    patch_path: attr('string'),
    symbol_path: attr('string'),
    image: attr('string'),
    parent_id: attr('number'),
    ancestry_data: attr(),
    group_id: attr('number'),
    location: attr('string'),
    state: attr('string'),

    // computed
    parents: computed('ancestry_data', function () {
      var current = this.get('ancestry_data');
      var parents = [];
      var failSafe = 15;
      if (current && current.unit_id) {
        while (current) {
          if (current.unit_shortname) {
            parents.pushObject({ id: current.url, name: current.unit_shortname });
          }
          current = current.parent;
          if (! --failSafe) {
            current = null;
          }
        }
        if (parents.get('lastObject')) {
          parents.set('lastObject.isLast', true);
        }
      }
      return parents;
    })
  });
});