define('rp-common/components/profile-item/component', ['exports', 'rp-common/components/profile-item/template', 'ember-concurrency'], function (exports, _template, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _template.default,
    listProfile: null,
    classNames: ['profile-item'],
    classNameBindings: ['isVerified', 'isRaiseAGlass'],
    sharedContactsOpen: false,
    showLoading: false,
    isContactsWith: false,
    isSignedIn: false,
    showSalute: false,

    profile: alias('listProfile.profile'),
    isVerified: alias('profile.verified'),
    currentProfile: readOnly('currentSession.profile'),

    i18n: service(),
    environment: service(),
    currentSession: service(),

    position: computed('profile.{isCivilian,speciality.name,branch.global_branch_name}', function () {
      var speciality = get(this, 'profile.speciality.name');
      var branch = get(this, 'profile.branch.global_branch_name');
      return get(this, 'profile.isCivilian') ? speciality : branch + ' | ' + speciality;
    }),

    userType: computed('isVerified', 'profile.user_type', function () {
      var verifyStatus = get(this, 'isVerified') ? 'verified' : 'unverified';
      return get(this, 'i18n').t('userType.' + verifyStatus + '.' + get(this, 'profile.user_type'));
    }),

    isRaiseAGlass: computed(function () {
      return get(this, 'environment').getConfig('raiseAGlassContacts_3256');
    }),

    deleteOrHide: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var isContactsWith;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              isContactsWith = get(this, 'isContactsWith');

              if (!isContactsWith) {
                _context.next = 8;
                break;
              }

              if (confirm(get(this, 'i18n').t('contacts.deleteConfirm'))) {
                _context.next = 4;
                break;
              }

              return _context.abrupt('return');

            case 4:
              _context.next = 6;
              return this.sendAction('onDelete');

            case 6:
              _context.next = 10;
              break;

            case 8:
              _context.next = 10;
              return this.sendAction('onDeletePYMK');

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    actions: {
      toggleSharedContactsModal: function toggleSharedContactsModal() {
        this.toggleProperty('sharedContactsOpen');
      }
    }
  });
});