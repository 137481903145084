define('rp-common/components/multiline-truncate/component', ['exports', 'rp-common/components/multiline-truncate/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var htmlSafe = Ember.String.htmlSafe;


  var OVERFLOW_OPTIONS = { ellipsis: true, clip: true };
  var WORD_WRAP_OPTIONS = { normal: true, 'break-word': true };
  var WHITE_SPACE_OPTIONS = { normal: true };

  // We can use this abstraction to change our implementation to js
  // or to improve the css everywhere if this ever gets standardized.
  // If using line-clamp inside a flexbox item, that flexbox item may need min-width: 0;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: '',
    lines: 2,
    overflow: 'ellipsis',
    wordWrap: 'break-word', // recommended for long words
    whiteSpace: 'normal', // just in case it is nowrap in a parent
    extraClasses: '',

    style: computed('lines', 'overflow', 'wordWrap', function () {
      var lines = parseInt(get(this, 'lines'));
      var textOverflow = get(this, 'overflow');
      var wordWrap = get(this, 'wordWrap');
      var whiteSpace = get(this, 'whiteSpace');
      // Use a whitelist to protect against xss
      textOverflow = OVERFLOW_OPTIONS[textOverflow] ? textOverflow : 'clip';
      wordWrap = WORD_WRAP_OPTIONS[wordWrap] ? wordWrap : 'normal';
      whiteSpace = WHITE_SPACE_OPTIONS[whiteSpace] ? whiteSpace : 'normal';
      return htmlSafe('-webkit-line-clamp: ' + lines + '; text-overflow: ' + textOverflow + '; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-wrap: ' + wordWrap + '; white-space: ' + whiteSpace);
    })
  });
});