define('rp-common/utils/utils-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;


  var UtilsBase = EmberObject.extend({
    // nothing here
  });

  UtilsBase.formatNumber = function () {
    var number = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var fmt = opts.format || '0,0';
    return numeral(number).format(fmt);
  };

  UtilsBase.getCurrentURL = function () {
    return window.location.href;
  };

  UtilsBase.browserRedirect = function (href) {
    location.href = href;
  };

  UtilsBase.browserReload = function () {
    location.reload();
  };

  UtilsBase.formatCount = function (count) {
    if (count > 999) {
      return '0.0a';
    }

    return '0';
  };

  UtilsBase.objectValuesContain = function (obj, needle) {
    return needle && Object.keys(obj).map(function (k) {
      return obj[k];
    }).includes(needle);
  };

  UtilsBase.numberWithOrdinal = function (n) {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][n > 3 && n < 21 || n % 10 > 3 ? 0 : n % 10] : '');
  };

  // MDN polyfill from String.repeat
  UtilsBase.stringRepeat = function (value, count) {
    if (String.prototype.repeat) {
      return value.repeat(count);
    } else {
      if (value === null) {
        throw new TypeError('can\'t convert ' + value + ' to object');
      }
      var str = ''.concat(value);
      count = +count;
      if (count !== count) {
        count = 0;
      }
      if (count < 0) {
        throw new RangeError('repeat count must be non-negative');
      }
      if (count === Infinity) {
        throw new RangeError('repeat count must be less than infinity');
      }
      count = Math.floor(count);
      if (str.length === 0 || count === 0) {
        return '';
      }
      // Ensuring count is a 31-bit integer allows us to heavily optimize the
      // main part. But anyway, most current (August 2014) browsers can't handle
      // strings 1 << 28 chars or longer, so:
      if (str.length * count >= 1 << 28) {
        throw new RangeError('repeat count must not overflow maximum string size');
      }
      var rpt = '';
      for (;;) {
        if ((count & 1) === 1) {
          rpt += str;
        }
        count >>>= 1;
        if (count === 0) {
          break;
        }
        str += str;
      }
      return rpt;
    }
  };

  // MDN Polyfill from Number.isInteger
  UtilsBase.isInteger = function (value) {
    if (Number.isInteger) {
      return Number.isInteger(value);
    } else {
      return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    }
  };

  exports.default = UtilsBase;
});