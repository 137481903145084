define('rp-common/components/link-preview/component', ['exports', 'rp-common/utils/link-helper', 'rp-common/components/link-preview/template'], function (exports, _linkHelper, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VIMEO_REGEX = exports.YOUTUBE_REGEX = undefined;
  var and = Ember.computed.and;
  var gt = Ember.computed.gt;
  var not = Ember.computed.not;
  var computed = Ember.computed;
  var get = Ember.get;
  var Component = Ember.Component;


  /* eslint-disable no-useless-escape */
  var YOUTUBE_REGEX = exports.YOUTUBE_REGEX = /(https?:\/\/)?(www.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/watch\?feature=player_embedded&v=)([A-Za-z0-9_-]*)(\&\S+)?(\?\S+)?/;
  var VIMEO_REGEX = exports.VIMEO_REGEX = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
  /* eslint-enable no-useless-escape */

  exports.default = Component.extend({
    layout: _template.default,

    classNames: ['link-image'],
    classNameBindings: ['hasPicture::no-image', 'isVideo:video'],

    linkData: null,
    slug: null,
    useAnchorForLinks: false,
    useLargePictures: true,
    useAnchor: and('notVideo', 'useAnchorForLinks'),
    iframeDisplayed: false,

    hasPicture: gt('picture.length', 0),

    picture: computed('useLargePictures', 'linkData.{picture_large,picture}', function () {
      return get(this, 'useLargePictures') ? get(this, 'linkData.picture_large') : get(this, 'linkData.picture');
    }),

    linkSource: computed('linkData.url', function () {
      return _linkHelper.default.hostFromUrl(this.get('linkData.url'));
    }),

    href: computed('slug', function () {
      return '/shared-links/' + get(this, 'slug');
    }),

    isVideo: computed('linkData.url', function () {
      var url = get(this, 'linkData.url');
      return url && (url.match(YOUTUBE_REGEX) || url.match(VIMEO_REGEX));
    }),

    notVideo: not('isVideo'),

    click: function click() {
      if (get(this, 'notVideo')) {
        this.sendAction('goToArticle');
      }
    }
  });
});