define('rp-common/models/tag', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _groupable.default.extend({
    // relationships
    survey: belongsTo('question', { async: false }),

    // properties
    imagePath: attr('string'),

    // Resource Center related
    rss_header_image: attr('string'),
    rss_src: attr('string'),
    logo_image: attr('string'),
    logo_link: attr('string'),
    sponsor_identifier: attr('string'),
    promo_header_image: attr('string'),
    resource_links: attr(),
    testimonials: attr()
  });
});