define('rp-common/models/list-feed-question', ['exports', 'ember-data', 'rp-common/models/list-feed-content'], function (exports, _emberData, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var match = Ember.computed.match;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  exports.default = _listFeedContent.default.extend({
    i18n: service(),
    is_question: true,
    is_rallypoint_survey: false,
    list_question: _emberData.default.belongsTo('list-question', { async: true, inverse: null }),
    contact_participants: _emberData.default.hasMany('list-profile', { async: true, inverse: null }),
    contact_responses: _emberData.default.hasMany('list-response', { async: true, inverse: null }),
    group: _emberData.default.belongsTo('list-group', { async: true, inverse: null }),

    total_participants: _emberData.default.attr('number'),
    reason: _emberData.default.attr('string'),
    reason_last_at: _emberData.default.attr('date'),

    followed_groups: _emberData.default.hasMany('list-group', { async: true }),
    primary_contact_participants: _emberData.default.hasMany('list-profile', { async: true }),

    author: readOnly('list_question.list_profile'),
    createdAt: readOnly('list_question.full_question.created_at'),
    responses: readOnly('contact_responses'),
    isGroupDiscussion: match('reason', /^groups?$/i),
    listModel: alias('list_question'),

    hasReason: true,

    actionMessage: computed('list_question.{sponsored_post,commandPostTypeName}', function () {
      var cpTypeName = get(this, 'list_question.commandPostTypeName');
      if (!isEmpty(cpTypeName)) {
        return get(this, 'i18n').t('feed.commandPostAction.' + cpTypeName);
      }
      var key = get(this, 'list_question.sponsored_post') ? 'feed.postedASponsoredAd' : 'feed.postedAQuestion';
      return get(this, 'i18n').t(key);
    })
  });
});