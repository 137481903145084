define('rp-common/services/tags-autocomplete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var autoCompleteCache = {};

  exports.default = Service.extend({
    apiService: service(),

    clearCache: function clearCache() {
      autoCompleteCache = {};
    },
    getAutoCompletes: function getAutoCompletes(term) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {
        var cacheKey = term;
        if (autoCompleteCache[cacheKey]) {
          resolve(autoCompleteCache[cacheKey]);
        } else {
          _this.get('apiService').getRequest('autocompletes/groups_with_separate_group_type?term=' + encodeURIComponent(term)).then(function (json) {
            autoCompleteCache[cacheKey] = json;
            resolve(json);
          });
        }
      });
    }
  });
});