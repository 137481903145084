define('rp-common/mixins/models/month-year-date', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var computed = Ember.computed;
  var attr = _emberData.default.attr;
  exports.default = Mixin.create({
    start_year: attr('number'),
    start_month: attr('number'),
    end_year: attr('number'),
    end_month: attr('number'),

    startMonthYear: computed('start_month', 'start_year', function () {
      var sm = get(this, 'start_month');
      var sy = get(this, 'start_year');
      return sm && sy ? sm + ' / ' + sy : sm || sy;
    }),

    endMonthYear: computed('end_month', 'end_year', 'current', function () {
      var c = get(this, 'current');
      var em = get(this, 'end_month');
      var ey = get(this, 'end_year');
      var pastOrFuture = em && ey ? em + ' / ' + ey : em || ey;
      return c ? 'Present' : pastOrFuture;
    }),

    startEndMonthYear: computed('startMonthYear', 'endMonthYear', function () {
      var sd = get(this, 'startMonthYear');
      var ed = get(this, 'endMonthYear');
      return sd && ed ? sd + ' - ' + ed : sd || ed;
    })
  });
});