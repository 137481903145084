define('rp-common/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    pictures: hasMany('picture', { async: true }),
    tags: hasMany('tag', { async: true }),

    survey: belongsTo('survey', { async: true }),

    // attributes
    body: attr('string'),
    title: attr('string'),
    command_post: attr('string'),
    anonymous: attr('boolean', { defaultValue: false })
  });
});