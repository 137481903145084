define('rp-common/services/blocking-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    apiService: service(),

    unblock: function unblock(profileId) {
      return this.setBlocked(profileId, false);
    },
    block: function block(profileId) {
      return this.setBlocked(profileId, true);
    },
    setBlocked: function setBlocked(profileId) {
      var blocked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var apiService = this.get('apiService');
      var path = blocked ? 'profile/block' : 'profile/unblock';
      return apiService.postV2Request(path, { profile_id: profileId });
    }
  });
});