define('rp-common/models/academic-degree', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _groupable.default.extend({
    position: attr('number')
  });
});