define('rp-common/serializers/registration', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(record, options) {
      options = options || {};
      return this._super(record, options);
    },


    // @TODO temporary, data returned from server should be correct
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!!payload.data.session && !!payload.data.user) {
        payload.data.session.id = 'current';
        payload.data.session.user_id = payload.data.user.id;
      }
      if (!!payload.data.profile && !!payload.data.user) {
        payload.data.user.session_id = 'current';
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});