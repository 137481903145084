define('rp-common/components/feed-reason-participant/component', ['exports', 'rp-common/components/feed-reason-participant/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNameBindings: ['ignoreFeedback'],
    layout: _template.default,
    tagName: 'a',
    participant: null
  });
});