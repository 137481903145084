define('rp-common/models/base-career-preference', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var empty = Ember.computed.empty;
  var and = Ember.computed.and;
  var _set = Ember.set;
  var _get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var Validations = (0, _emberCpValidations.buildValidations)({
    profile: (0, _emberCpValidations.validator)('presence', true),
    'geo_locations.length': (0, _emberCpValidations.validator)('number', { gt: 0 }),
    'availability_date_month': (0, _emberCpValidations.validator)('number', { gte: 1, lte: 12 }),
    'availability_date_year': (0, _emberCpValidations.validator)('number', {
      allowString: true, gte: new Date().getFullYear(), lte: new Date().getFullYear() + 20,
      message: 'Enter 4-digit year' })
  });

  exports.default = Model.extend(Validations, {
    profile: belongsTo('profile', { async: true }),

    updated_at: attr('date'),
    availability_date_month: attr('number'),
    availability_date_year: attr('number'),
    not_decided: attr('boolean'),
    open_to_other_locations: attr('boolean'),

    geo_locations: attr(),

    emptyMonth: equal('availability_date_month', 0),
    emptyYear: empty('availability_date_year'),
    isNotDecided: and('emptyMonth', 'emptyYear'),

    availabilityDateMonth: computed({
      get: function get() {
        return _get(this, 'availability_date_month');
      },
      set: function set(key, value) {
        value = +value;
        _set(this, 'availability_date_month', value);
        var year = _get(this, 'availability_date_year');
        if (value > 0 || year > 0) {
          _set(this, 'not_decided', false);
        }

        return value;
      }
    }),

    availabilityDateYear: computed({
      get: function get() {
        return _get(this, 'availability_date_year');
      },
      set: function set(key, value) {
        value = +value;
        _set(this, 'availability_date_year', value);
        var month = _get(this, 'availability_date_month');
        if (value > 0 || month > 0) {
          _set(this, 'not_decided', false);
        }

        return value;
      }
    }),

    notDecided: computed('availability_date_month', 'availability_date_year', 'not_decided', {
      get: function get() {
        return _get(this, 'not_decided');
      },
      set: function set(key, value) {
        _set(this, 'not_decided', value);
        if (value) {
          _set(this, 'availability_date_month', 0);
          _set(this, 'availability_date_year', '');
        }

        return value;
      }
    }),

    availableNow: computed('availability_date_month', 'availability_date_year', {
      get: function get() {
        var today = new Date();
        return _get(this, 'availability_date_month') === today.getMonth() + 1 && _get(this, 'availability_date_year') === today.getFullYear();
      },
      set: function set(key, value) {
        if (value) {
          var today = new Date();
          _set(this, 'availability_date_month', today.getMonth() + 1);
          _set(this, 'availability_date_year', today.getFullYear());
          _set(this, 'not_decided', false);
        }
        return value;
      }
    })
  });
});