define('rp-common/services/truncation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var $ = Ember.$;

  var BUFFER = 15; // If truncating only saves us 15 chars, don't truncate

  exports.default = Service.extend({
    truncate: function truncate(str, len) {
      if (!str) {
        return '';
      }

      if (len === 0) {
        return { value: str, truncated: false };
      }

      var el = $('<div/>').html(str).get(0);
      var children = el.childNodes;
      var truncStr = '';
      var wasTruncated = false;

      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        var truncLen = $('<div/>').html(truncStr).text().length;

        if (child.nodeType === 3) {
          // Text
          if (truncLen + child.length <= len + BUFFER) {
            truncStr += $('<div/>').text(child.nodeValue).html();
          } else {
            truncStr += $('<div/>').text(child.nodeValue.substr(0, len - truncLen)).html();
            wasTruncated = true;
            break;
          }
        } else {
          // Node
          if (truncLen + child.textContent.length <= len + BUFFER) {
            truncStr += child.outerHTML;
          } else {
            child.innerHTML = this.truncate(child.innerHTML, len - truncLen).value;
            truncStr += child.outerHTML;
            wasTruncated = true;
            break;
          }
        }
      }

      return { value: truncStr, truncated: wasTruncated };
    }
  });
});