define('rp-common/adapters/contact-request', ['exports', 'rp-common/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    accept: function accept(id) {
      var urlParts = [this.urlForUpdateRecord(id, 'contact_requests'), 'accept'];
      return this.ajax(urlParts.join('/'), 'POST');
    },
    ignore: function ignore(id) {
      var urlParts = [this.urlForUpdateRecord(id, 'contact_requests'), 'ignore'];
      return this.ajax(urlParts.join('/'), 'POST');
    }
  });
});