define('rp-common/components/profile-mention/component', ['exports', 'rp-common/components/profile-mention/template', 'rp-common/utils/get-caret', 'ember-concurrency'], function (exports, _template, _getCaret, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var deprecate = Ember.deprecate;
  var run = Ember.run;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var set = Ember.set;
  var $ = Ember.$;


  var RE_NAME = /\S/i;
  var MIN_TERM_LEN = 0;
  var DEBOUNCE_MS = 150;
  var FONT_HEIGHT = 20;
  var KEY_UP = 38;
  var KEY_DOWN = 40;
  var KEY_ESC = 27;
  var KEY_ENTER = 13;

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['profile-mention'],
    autoCompleteLock: false,
    lastAutoComplete: 0,
    autoCompleteTimeoutId: 0,
    showPopup: false,
    showFloating: true,
    showLoading: false,
    showFullProfile: false,
    inputField: null,
    privateGroup: false,
    groupId: null,
    qrcRootId: null,

    // Input Container and selector are deprecated.
    // Just use multi-line of the component, and put the textarea inside.
    inputContainer: null,
    inputSelector: 'textarea',

    autoCompleteService: service('mentions-autocomplete'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      deprecate('Using the inputContainer attr is deprecated. Instead wrap the text area with the profile-mention component.', !get(this, 'inputContainer'), { id: 'rp-profile-mention', until: '10000' });
      var $container = get(this, 'inputContainer');
      var $target = $container ? $container.$(get(this, 'inputSelector')) : this.$('textarea');

      if ($target.length) {
        $target.on('keyup.mentions', this.onTargetKeyUp.bind(this));
        $target.on('focusout.mentions', this.onTargetFocusOut.bind(this));
        $target.on('focusin.mentions', function (evt) {
          return run.next(_this, _this.onTargetKeyUp, evt);
        });
        $target.on('mentions:toggle', this.onTargetToggle.bind(this));
        this.set('inputField', $target);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var $target = this.get('inputField');
      if (!$target) {
        return;
      }

      $target.off('keyup.mentions');
      $target.off('focusout.mentions');
      $target.off('focusin.mentions');
      $target.off('mentions:toggle');
      this._super.apply(this, arguments);
    },
    fireAutoComplete: function fireAutoComplete() {
      var _get;

      (_get = get(this, 'fireAutoCompleteTask')).perform.apply(_get, arguments);
    },


    fireAutoCompleteTask: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee(str, tokenInfo) {
      var autoCompleteService, groupId, json;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

            case 2:
              autoCompleteService = get(this, 'autoCompleteService');
              groupId = get(this, 'privateGroup') ? get(this, 'groupId') : null;


              if (get(this, 'showLoading')) {
                set(this, 'showPopup', true);
              }

              _context.next = 7;
              return autoCompleteService.getAutoCompletes(str, get(this, 'qrcRootId'), groupId);

            case 7:
              json = _context.sent;

              this.onAutocompleteResults(json, tokenInfo);

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    mouseMove: function mouseMove(evt) {
      var $target = $(evt.target);
      var $profile = $target.is('.search-result') ? $target : $target.parents('.search-result:first');

      if ($profile.length) {
        this.get('profiles').setEach('active', false);
        this.get('profiles')[this.$('.search-result').index($profile)].set('active', true);
      }
    },
    onTargetFocusOut: function onTargetFocusOut(e) {
      var _this2 = this;

      var timeoutId = run.later(function () {
        if (_this2.get('isDestroying')) {
          return;
        }

        _this2.sendAction('hidingPopup', e);
        _this2.set('showPopup', false);
      }, 250);

      this.set('hideTimeoutId', timeoutId);
    },
    onTargetKeyUp: function onTargetKeyUp(evt) {
      var $target = this.get('inputField');
      var tokenInfo = this.checkForToken($target);

      if (evt.which === KEY_ENTER) {
        var activeIdx = this.$('.search-result.active').index();

        if (activeIdx >= 0) {
          evt.preventDefault();
          this.send('selectResult', this.get('profiles')[activeIdx]);
        }
      }

      if (this.get('showPopup') && (evt.which === KEY_UP || evt.which === KEY_DOWN)) {
        this.handleArrows(evt.which === KEY_DOWN);
        evt.preventDefault();
        return;
      }

      if (!tokenInfo || evt.which === KEY_ESC || evt.which === KEY_ENTER) {
        if (this.get('showPopup')) {
          this.sendAction('hidingPopup', evt);
          this.set('showPopup', false);
        }

        return;
      }

      var token = $target.val().substring(tokenInfo.start + 1, tokenInfo.end);

      if (token.length >= MIN_TERM_LEN) {
        this.fireAutoComplete(token, tokenInfo);
      }
    },
    handleArrows: function handleArrows() {
      var downArrow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (!this.get('profiles')) {
        return;
      }

      var idx = this.get('profiles').findIndex(function (p) {
        return p.get('active');
      });
      var maxLen = this.get('profiles.length');

      if (idx >= 0) {
        this.get('profiles')[idx].set('active', false);
      }

      idx = idx + (downArrow ? 1 : -1);

      if (idx < 0) {
        idx = maxLen - 1;
      } else if (idx >= maxLen) {
        idx = 0;
      }

      this.get('profiles')[idx].set('active', true);
    },
    onTargetToggle: function onTargetToggle() {
      run.cancel(this.get('hideTimeoutId'));
      if (this.get('showPopup')) {
        this.sendAction('hidingPopup', null);
        this.set('showPopup', false);
        return;
      }

      var $target = this.get('inputField');
      var tokenInfo = this.checkForToken($target);

      if (!tokenInfo) {
        var cpos = this.getCaret($target.get(0));
        tokenInfo = { start: cpos, end: cpos };
      }

      this.fireAutoComplete('', tokenInfo);
    },
    onAutocompleteResults: function onAutocompleteResults(json, tokenInfo) {
      if (!json.data.has_profile_results || !json.data.profile_results || !json.data.profile_results.length) {
        this.sendAction('hidingPopup', null);
        this.set('showPopup', false);
        return;
      }

      this.set('profiles', json.data.profile_results.map(function (p) {
        return EmberObject.create(p);
      }));
      this.get('profiles')[0].set('active', true);

      var $target = this.get('inputField');
      var offset = $target.position();
      var scrollOffset = $target.scrollTop();
      var caretOffset = (0, _getCaret.default)($target.get(0), tokenInfo.end);

      this.set('showPopup', true);
      this.sendAction('showingPopup');
      if (this.get('showFloating')) {
        run.scheduleOnce('afterRender', this, function () {
          this.$('.js-profiles-popup-list').css({ top: Math.floor(offset.top + caretOffset.top + FONT_HEIGHT - scrollOffset), left: Math.floor(offset.left) }).width($target.width());
        });
      }
    },
    getCaret: function getCaret(ele) {
      if (ele.selectionStart) {
        return ele.selectionStart;
      } else if (document.selection) {
        var r = document.selection.createRange();
        if (!r) {
          return 0;
        }
        var re = ele.createTextRange();
        var rc = re.duplicate();
        re.moveToBookmark(r.getBookmark());
        rc.setEndPoint('EndToStart', re);
        return rc.text.length;
      }
      return 0;
    },
    setCaret: function setCaret(ele, idx) {
      if (ele.selectionStart) {
        ele.setSelectionRange(idx, idx);
      } else if (document.selection) {
        var range = ele.createTextRange();
        range.move('character', idx);
        range.select();
      }
    },
    checkForToken: function checkForToken($ele) {
      var cpos = this.getCaret($ele.get(0));
      var str = $ele.val();
      var start = this.getStartTag(str, cpos - 1);

      if (start < 0) {
        return null;
      }
      var end = cpos;
      return { start: start, end: end };
    },
    getStartTag: function getStartTag(str, idx) {
      var stopCount = 0;
      while (idx >= 0) {
        if (str[idx] === '@') {
          // Check char before @
          if (idx - 1 <= 0 || !RE_NAME.test(str[idx - 1])) {
            return idx;
          }
          return -1;
        }
        if (!RE_NAME.test(str[idx]) && stopCount++ > 1) {
          break;
        }
        idx--;
      }
      return -1;
    },


    actions: {
      selectResult: function selectResult(profile) {
        var $target = this.get('inputField');
        var tokenInfo = this.checkForToken($target);

        if (!tokenInfo) {
          var cpos = this.getCaret($target.get(0));
          tokenInfo = { start: cpos, end: cpos };
        }

        if (tokenInfo) {
          var _profile$id$split = profile.id.split('-'),
              _profile$id$split2 = _slicedToArray(_profile$id$split, 1),
              profileId = _profile$id$split2[0];

          var str = $target.val();
          // TODO: use profileTagFormat util
          var newStr = str.slice(0, tokenInfo.start) + '[~' + profileId + ':' + profile.full_name + '] ';
          $target.val(newStr + str.substr(tokenInfo.end));
          run($target, 'trigger', 'keyup.mentions', { keyCode: 32, which: 32 });
          run($target, 'trigger', 'change');
          this.setCaret($target.get(0), newStr.length);
          $target.focus();

          this.sendAction('hidingPopup', null);
          this.set('showPopup', false);
        }

        if (this.attrs.afterSelect) {
          this.attrs.afterSelect();
        }
      }
    }
  });
});