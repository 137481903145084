define('rp-common/models/educational-preference', ['exports', 'ember-data', 'rp-common/models/base-career-preference', 'ember-cp-validations'], function (exports, _emberData, _baseCareerPreference, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _emberData.default.hasMany;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'academic_degrees.length': (0, _emberCpValidations.validator)('number', { gt: 0 })
  });

  exports.default = _baseCareerPreference.default.extend(Validations, {
    academic_degrees: hasMany('academic-degree', { async: true })
  });
});