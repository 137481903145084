define('rp-common/models/close-account-reason', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.REASONS = exports.DONT_KNOW_ANYONE = exports.DONT_LIKE_CONTENT = exports.OTHER = exports.NOT_USEFUL = exports.PRIVACY_CONCERNS = exports.TOO_MANY_EMAILS = undefined;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var TOO_MANY_EMAILS = exports.TOO_MANY_EMAILS = 1;
  var PRIVACY_CONCERNS = exports.PRIVACY_CONCERNS = 2;
  var NOT_USEFUL = exports.NOT_USEFUL = 3; // Currently not displayed
  var OTHER = exports.OTHER = 4;
  var DONT_LIKE_CONTENT = exports.DONT_LIKE_CONTENT = 5;
  var DONT_KNOW_ANYONE = exports.DONT_KNOW_ANYONE = 6;
  var REASONS = exports.REASONS = [DONT_LIKE_CONTENT, DONT_KNOW_ANYONE, TOO_MANY_EMAILS, PRIVACY_CONCERNS, OTHER];

  var Validations = (0, _emberCpValidations.buildValidations)({
    reason: (0, _emberCpValidations.validator)('number', {
      integer: true,
      gt: 0,
      lt: 7
    }),
    other: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: not('model.reasonIsOther')
    })
  });

  exports.default = Model.extend(Validations, {
    reason: attr('number'),
    other: attr('string'),

    reasonIsOther: equal('reason', OTHER)
  });
});