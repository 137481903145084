define('rp-common/models/list-conversation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    all_recipients: hasMany('list-profile', { async: true }),

    hash: attr('string'),
    latest_message_receipt_hash: attr('string'),
    subject: attr('string'),
    unread: attr('boolean'),
    updated_at: attr('date'),
    rp_mail: attr('boolean'),

    currentUser: service(),

    recipientsExceptCurrent: computed('all_recipients', 'currentUser.profile.id', 'currentSession.listProfile.id', function () {
      var profile = get(this, 'currentUser.profile') || get(this, 'currentSession.listProfile');
      return get(this, 'all_recipients').filter(function (r) {
        return get(r, 'id') !== get(profile, 'id');
      });
    }),

    withPremium: readOnly('rp_mail')
  });
});