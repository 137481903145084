define('rp-common/services/base-control-center', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;


  var FLASHES = { notice: 'success', alert: 'success', error: 'danger' };
  var FLASH_TYPES = Object.keys(FLASHES);

  var gteZero = function gteZero(key, value) {
    return Math.max(value, 0);
  };

  exports.default = Service.extend({
    apiService: service(),
    flashMessages: service(),
    store: service(),

    unreadMessages: computed({ set: gteZero }),
    unreadNotifications: computed({ set: gteZero }),
    unreadCommandPosts: computed({ set: gteZero }),
    pendingContactRequests: computed({ set: gteZero }),

    init: function init() {
      set(this, 'unreadMessages', 0);
      set(this, 'unreadNotifications', 0);
      set(this, 'unreadCommandPosts', 0);
      set(this, 'pendingContactRequests', 0);
      set(this, 'announcements', []);
      set(this, 'reputationMessages', []);
    },


    // Used when we want to make an API request and update the control center
    update: function update() {
      return get(this, 'performCCUpdate').perform();
    },


    performCCUpdate: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'apiService').getV2Request('status').then(function (json) {
                _this.handleNewStatus(json);
              });

            case 2:
              return _context.abrupt('return', _context.sent);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    // Takes JSON and updates the control center
    handleNewStatus: function handleNewStatus(json) {
      var status = json.status;


      if (!status) {
        return;
      }

      if (status.new_contacts_count && status.new_contacts_count !== null) {
        set(this, 'pendingContactRequests', status.new_contacts_count);
      }
      if (status.mailbox_info && status.mailbox_info !== null) {
        set(this, 'unreadMessages', status.mailbox_info.new_messages);
      }
      if (status.notification_center_info && status.notification_center_info !== null) {
        set(this, 'unreadNotifications', status.notification_center_info.unread);
      }
      if (status.notification_center_info && status.notification_center_info !== null) {
        set(this, 'unreadCommandPosts', status.notification_center_info.command_posts);
      }

      if (status.announcements && status.announcements.length) {
        get(this, 'store').push({ data: status.announcements.map(function (a) {
            return {
              id: a.id,
              type: 'announcement',
              attributes: a
            };
          }) });
        set(this, 'announcements', get(this, 'store').peekAll('announcement').toArray());
      }

      if (status.reputation_messages && status.reputation_messages.length) {
        get(this, 'store').push({ data: status.reputation_messages.map(function (message) {
            return {
              id: guidFor('reputation-message'),
              type: 'reputation-message',
              attributes: { message: message }
            };
          }) });
        set(this, 'reputationMessages', get(this, 'store').peekAll('reputation-message').toArray());
      }

      this.handleFlash(status.flash);
    },
    handleFlash: function handleFlash(flash) {
      if (!flash) {
        return;
      }

      for (var i = 0; i < FLASH_TYPES.length; i++) {
        var flashType = FLASH_TYPES[i];
        if (flash[flashType]) {
          this.flashMessageWrapper(FLASHES[flashType], flash[flashType]);
          return;
        }
      }
    },


    // Should be overwritten with custom options.
    flashMessageWrapper: function flashMessageWrapper(method, message) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      get(this, 'flashMessages')[method](message, options);
    }
  });
});