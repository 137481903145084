define('rp-common/serializers/status-update', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (relationship.key === 'pictures') {
        var pictures = snapshot.hasMany('pictures');
        json.picture_ids = isArray(pictures) ? pictures.mapBy('id') : [];
      }
      if (relationship.key === 'tags') {
        var tags = snapshot.hasMany('tags');
        if (isArray(tags)) {
          json.explicit_group_ids = tags.filter(function (t) {
            return t.attr('group_id');
          }).map(function (t) {
            return t.attr('group_id');
          });
          json.topics_list = tags.filter(function (t) {
            return !t.attr('group_id');
          }).map(function (t) {
            return t.attr('name');
          }).join(',');
        } else {
          json.explicit_group_ids = [];
          json.topics_list = [];
        }
      }
    }
  });
});