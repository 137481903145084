define('ember-lazy-video/lazy-video-providers/youtube', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = {
    apiUrl: function apiUrl(videoId) {
      return 'https://gdata.youtube.com/feeds/api/videos/' + videoId;
    },
    embedUrl: function embedUrl(videoId) {
      return 'https://www.youtube.com/embed/' + videoId;
    },
    thumbnailUrl: function thumbnailUrl(videoId) {
      return RSVP.resolve('https://img.youtube.com/vi/' + videoId + '/maxresdefault.jpg');
    }
  };
});