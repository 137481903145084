define('rp-common/components/character-length-counter/component', ['exports', 'rp-common/components/character-length-counter/template', 'rp-common/utils/utils-base'], function (exports, _template, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var lt = computed.lt;
  var isInteger = _utilsBase.default.isInteger;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['characters-left-counter'],
    charLimit: 0,
    charBody: '',
    charsLeft: computed('charBody', 'charLimit', function () {
      (false && !(isInteger(get(this, 'charLimit'))) && Ember.assert('charLimit should be an int', isInteger(get(this, 'charLimit'))));

      var result = get(this, 'charLimit') - get(this, 'charBody.length');
      return !isNaN(result) ? result : get(this, 'charLimit');
    }),
    ltZeroLeft: lt('charsLeft', 0)
  });
});