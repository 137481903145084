define('rp-common/components/feed-jobs-list/job-item/component', ['exports', 'rp-common/components/feed-jobs-list/job-item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['job-item', 'ios-clickable', 'active-feedback', 'feedback-row'],
    job: null,

    actions: {
      jobClicked: function jobClicked() {
        this.get('view')();
      }
    }
  });
});