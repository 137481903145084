define('rp-common/services/experiment-lookup', ['exports', 'rp-common/experiments/base-experiment'], function (exports, _baseExperiment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var get = Ember.get;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    lookup: function lookup(experimentName) {
      var resolved = getOwner(this).lookup('experiment:' + experimentName);
      return resolved;
    },
    lookupFromLsKey: function lookupFromLsKey(key) {
      var parts = key.replace(_baseExperiment.EXPERIMENT_REGEX, '').split('-');
      var experimentName = parts.slice(1, parts.length - 1).join('-');
      var exp = this.lookup(experimentName);
      return exp && _baseExperiment.EXPERIMENT_PREFIX + '-' + get(exp, 'experimentId') === key ? exp : null;
    },
    inExperimentGroup: function inExperimentGroup(experimentName, group) {
      return this.lookup(experimentName).get('group') === group;
    },
    experimentGroup: function experimentGroup(experimentName) {
      return this.lookup(experimentName).get('group');
    }
  });
});