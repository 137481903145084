define('rp-common/models/list-shared-link', ['exports', 'ember-data', 'rp-common/models/list-qrc-root'], function (exports, _emberData, _listQrcRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _listQrcRoot.default.extend({
    list_profile: _emberData.default.belongsTo('list-profile', { async: true }),
    full_shared_link: _emberData.default.belongsTo('full-shared-link', { async: true }),

    title: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),

    full_model: alias('full_shared_link'),

    seoTitle: computed('title', function () {
      return get(this, 'title') + ' | RallyPoint';
    }),

    seoMetaDescription: computed('title', 'full_model.body', function () {
      var title = get(this, 'title');
      var body = void 0;
      return get(this, 'full_model').then(function (fullModel) {
        body = get(fullModel, 'body');
        if (body) {
          return title + ': ' + body;
        }
        return title;
      });
    })
  });
});