define('rp-common/components/email-share-button/component', ['exports', 'rp-common/components/email-share-button/template', 'rp-common/utils/utils-base'], function (exports, _template, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var get = Ember.get;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    listModel: null,
    fullModel: null,
    postClick: null,
    i18n: service(),
    shortLinkGenerator: service('short-link-generator'),
    ui: service('ui'),

    title: readOnly('listModel.title'),

    click: function click() {
      var _this = this;

      // For now, fire this regardless of whether user cancels
      tryInvoke(this, 'postClick', ['email']);

      get(this, 'shortLinkGenerator').generate(get(this, 'fullModel')).then(function (shortLink) {
        var sharingSubject = get(_this, 'i18n').t('sharing.subject');
        var sharingInterest = get(_this, 'i18n').t('sharing.interest');
        var subject = sharingSubject + ' ' + get(_this, 'title');
        var body = sharingInterest + ' ' + get(_this, 'title') + ' ' + shortLink;
        var url = 'mailto:?subject=' + encodeURI(subject) + '&body=' + encodeURI(body);
        _utilsBase.default.browserRedirect(url);
      }).catch(get(this, 'ui.handleError'));
    }
  });
});