define('rp-common/models/sponsored-topic-ad', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    list_groups: hasMany('list-group', { async: true }),

    title: attr('string'),
    description: attr('string'),
    destination_url: attr('string'),
    destination_cta: attr('string'),
    image1x: attr('string'),
    image2x: attr('string'),
    image3x: attr('string'),
    tracking_pixels: attr()
  });
});