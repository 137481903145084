define('rp-common/models/groupable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    group_id: attr('number'),
    image: attr('string'),
    group_image: attr('string'),
    canvas_image: attr('string'),
    image_path: attr('string'),
    followers_count: attr('number'),
    following: attr('boolean'),
    private_group: false,
    visibleToCurrentUser: true
  });
});