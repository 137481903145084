define('rp-common/mixins/infinite-scroll-analytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    googleAnalyticsHelper: service(),
    pageViewTracker: service('trackers/page-view'),

    infinityModelUpdated: function infinityModelUpdated(page) {
      this._super.apply(this, arguments);

      if (!page.lastPageLoaded || page.lastPageLoaded < 2) {
        return;
      }

      var url = get(this, 'router.url') || window.location.pathname;
      get(this, 'googleAnalyticsHelper').infinityLoaderPageView(page.lastPageLoaded, url);
      get(this, 'pageViewTracker').infinityLoaderPageView(page.lastPageLoaded, url);
    },


    actions: {
      infinityModelUpdated: function infinityModelUpdated(page) {
        this._super.apply(this, arguments);
        this.infinityModelUpdated(page);
      }
    }
  });
});