define('rp-common/models/picture', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    thumb: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    url_large: _emberData.default.attr('string'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number')
  });
});