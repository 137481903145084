define('rp-common/services/permission', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var not = Ember.computed.not;
  var gt = Ember.computed.gt;
  var readOnly = Ember.computed.readOnly;
  var RSVP = Ember.RSVP;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var ReputationRequirements = {
    question_start: 'start_discussion_min',
    vote_down: 'vote_down_min',
    group_discussion_start: 'start_group_discussion_min'
  };

  exports.default = Service.extend({
    currentUser: service(),
    store: service(),
    requirements: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'requirements', EmberObject.create());
    },


    newQuestionRecord: readOnly('requirements.' + ReputationRequirements.question_start),
    downVoteRecord: readOnly('requirements.' + ReputationRequirements.vote_down),
    newGroupDiscussionRecord: readOnly('requirements.' + ReputationRequirements.group_discussion_start),
    requiredQuestionPoints: readOnly('newQuestionRecord.points'),
    newQuestionReputationError: readOnly('newQuestionRecord.message'),
    downVotePoints: readOnly('downVoteRecord.points'),
    downVoteReputationError: readOnly('downVoteRecord.message'),
    groupDiscussionPoints: readOnly('newGroupDiscussionRecord.points'),
    groupDiscussionError: readOnly('newGroupDiscussionRecord.message'),

    hasRequirements: gt('requirements.length', 0),
    needsRequirements: not('hasRequirements'),

    // TODO: mpape we should not be performing the check here, but rather on the server and sending it here for the following
    // three permissions checks.  Case in point, we were checking > rather than >= like the server

    toPostQuestions: computed('currentUser.profile.reputation', 'requiredQuestionPoints', function () {
      return get(this, 'currentUser.profile.reputation') >= get(this, 'requiredQuestionPoints');
    }),

    toDownVote: computed('currentUser.profile.reputation', 'downVotePoints', function () {
      return get(this, 'currentUser.profile.reputation') >= get(this, 'downVotePoints');
    }),

    toPostToGroup: computed('currentUser.profile.reputation', 'groupDiscussionPoints', function () {
      return get(this, 'currentUser.profile.reputation') >= get(this, 'groupDiscussionPoints');
    }),

    loadRequirements: function loadRequirements() {
      var requirementHash = get(this, 'requirements');
      if (get(this, 'store')) {
        return get(this, 'store').findAll('reputation-requirement', { reload: true }).then(function (requirements) {
          requirements.forEach(function (r) {
            requirementHash.set(r.get('id'), {
              message: r.get('message'),
              points: r.get('points')
            });
          });
        });
      }
      return new RSVP.Promise(function (resolve, reject) {
        return reject();
      });
    }
  });
});