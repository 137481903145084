define('rp-common/components/feed-image-gallery/component', ['exports', 'rp-common/components/feed-image-gallery/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var gt = Ember.computed.gt;
  var equal = Ember.computed.equal;
  var Component = Ember.Component;


  var DISPLAY_IMAGES = 3;

  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-image-gallery', 'ios-clickable'],
    classNameBindings: ['onlyOneImage:only-one', 'onlyTwoImages:only-two'],

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.pictures) {
        this.pictures = [];
      }
    },


    showXImages: DISPLAY_IMAGES,
    showMoreImages: gt('pictures.length', DISPLAY_IMAGES),
    moreImageCount: computed.difference('pictures.length', 'showXImages'),
    onlyOneImage: equal('pictures.length', 1),
    onlyTwoImages: equal('pictures.length', 2),
    useLargePictures: false,

    firstThreePictures: computed('pictures.[]', 'showXImages', function () {
      if (!this.get('pictures.length')) {
        return [];
      }

      return this.get('pictures').slice(0, this.get('showXImages'));
    }),

    click: function click() {
      this.get('goToItem')();
    }
  });
});