define('rp-common/components/qrc-body-text/component', ['exports', 'rp-common/mixins/html-utils', 'rp-common/utils/link-helper', 'rp-common/components/qrc-body-text/template'], function (exports, _htmlUtils, _linkHelper, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TAGGED_RE = undefined;
  var service = Ember.inject.service;
  var htmlSafe = Ember.String.htmlSafe;
  var getOwner = Ember.getOwner;
  var Component = Ember.Component;
  var on = Ember.on;
  var get = Ember.get;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var TAGGED_RE = exports.TAGGED_RE = /\[~(\d+):([^\]]*)\]/g;
  var LINK_RE = /((?:(?:http)s?:\/\/)\S+)|(www\.\S+)/gi;
  var ESCAPE_RE = /[-[\]{}()*+?.,\\^$|#\s]/g;

  exports.default = Component.extend(_htmlUtils.default, {
    layout: _template.default,
    allowBodyClick: false,
    allowLinks: true,
    bodyClickRoute: 'answers.show',
    ellipsize: true,
    ellipsis: '...',
    expandable: true,
    more: '[Read More]',
    showLinkText: false,
    linkText: 'See all responses',
    truncateAt: 150,
    convertLineBreaks: true,
    store: service(),
    truncation: service(),

    truncateAtObserver: on('init', observer('truncateAt', function () {
      this.set('_truncateAt', this.get('truncateAt'));
    })),

    htmlBody: computed('qrc.body', 'truncateAt', 'expandable', 'ellipsize', function () {
      var body = get(this, 'qrc.body');
      return this.htmlEncode(body || '');
    }),

    bodyWithTags: computed('htmlBody', function () {
      var htmlBody = get(this, 'htmlBody');
      return htmlBody ? this._tag(htmlBody) : '';
    }),

    truncationResult: computed('bodyWithTags', '_truncateAt', function () {
      var bodyWithTags = get(this, 'bodyWithTags');
      if (bodyWithTags.length > 0) {
        return get(this, 'truncation').truncate(bodyWithTags, get(this, '_truncateAt'));
      } else {
        return {};
      }
    }),

    truncated: computed('truncationResult', '_truncateAt', function () {
      var truncationResult = get(this, 'truncationResult');
      if (get(this, '_truncateAt')) {
        return truncationResult.truncated;
      } else {
        return false;
      }
    }),

    couldBeTruncated: computed('truncationResult', 'truncated', function () {
      return get(this, 'truncationResult').truncated && !get(this, 'truncated');
    }),

    truncatedBody: computed('truncationResult', function () {
      return get(this, 'truncationResult').value || '';
    }),

    taggedBody: computed('truncatedBody', function () {
      var truncatedBody = get(this, 'truncatedBody');
      return truncatedBody ? htmlSafe(truncatedBody) : '';
    }),

    linkify: function linkify(txt) {
      if (get(this, 'allowLinks')) {
        return this._super.apply(this, arguments);
      }

      return '<span class="tagged-profile">' + txt + '</span>';
    },
    _handleLink: function _handleLink(evt) {
      var $el = this.$(evt.target);
      var type = $el.data('link-to');
      var objectId = $el.data('link-id');
      var groupType = $el.data('group-type');

      if (type && objectId && groupType) {
        getOwner(this).lookup('route:application').send('goToLink', type, groupType, objectId);
      } else if (type && objectId) {
        getOwner(this).lookup('route:application').send('goToLink', type, objectId);
      }
      return false;
    },
    _tag: function _tag(htmlBody) {
      htmlBody = this._tagProfiles(htmlBody);
      if (get(this, 'allowLinks')) {
        htmlBody = this._tagLinks(htmlBody);
      }

      if (this.get('convertLineBreaks')) {
        htmlBody = this._tagLineBreaks(htmlBody);
      }

      return htmlBody;
    },
    _tagLineBreaks: function _tagLineBreaks(htmlBody) {
      return htmlBody.replace(/(\r\n|\n|\r){2}/gm, '<p>&nbsp;</p>').replace(/(\r\n|\n|\r)/gm, '<br />');
    },
    _tagLinks: function _tagLinks(htmlBody) {
      var _this = this;

      var matches = htmlBody.match(LINK_RE);
      var config = getOwner(this).resolveRegistration('config:environment');

      if (!matches) {
        return htmlBody;
      }

      matches = matches.uniq();

      return htmlBody.replace(new RegExp(matches.map(function (m) {
        return m.replace(ESCAPE_RE, '\\$&');
      }).join('|'), 'g'), function (link) {
        if (!/^http/i.test(link)) {
          link = 'http://' + link;
        }

        return _linkHelper.default.generateExternalLink(_this.htmlEncode(link), _this.htmlEncode(link), config);
      });
    },


    // I am not sure if this function is safe to use (see eslint rule disable)
    _tagProfiles: function _tagProfiles(htmlBody) {
      var match = void 0;
      var store = this.get('store');

      /* eslint-disable no-cond-assign */
      while (match = TAGGED_RE.exec(htmlBody)) {
        if (match.length !== 3) {
          continue;
        }

        var profile = store.peekRecord('profile', parseInt(match[1], 10));

        if (profile) {
          var rank = '';

          if (!profile.get('isCivilian')) {
            rank = this.linkify(profile.get('rank'), { 'link-to': 'groups.show', 'group-type': 'ranks', 'link-id': profile.get('rank_id'),
              'action': 'link' });
          }

          var name = this.linkify(profile.get('full_name'), { 'link-to': 'profile.index', 'link-id': profile.get('id'), 'action': 'link' });

          htmlBody = htmlBody.replace(match[0], rank + ' ' + name);
        } else {
          htmlBody = htmlBody.replace(match[0], this.linkify(match[2], { 'link-to': 'profile.index', 'link-id': parseInt(match[1], 10), 'action': 'link' }));
        }
      }
      /* eslint-enable no-cond-assign */

      return htmlBody;
    },
    click: function click(evt) {
      var $el = this.$(evt.target);
      switch ($el.data('action')) {
        case 'link':
          return this._handleLink(evt);

        default:
          if (this.get('allowBodyClick')) {
            // Don't go to question if user clicked link of some kind
            if (!$el.is('a')) {
              if (this.get('bodyClickAction')) {
                this.sendAction('bodyClickAction', this.get('qrc'));
              } else {
                getOwner(this).lookup('route:application').send('goToLink', this.get('bodyClickRoute'), this.get('qrc.id'));
              }
            }
          }

          break;
      }
    },


    actions: {
      expand: function expand() {
        if (this.attrs.expand) {
          this.attrs.expand();
          return;
        }

        this.set('_truncateAt', 0);
      }
    }
  });
});