define('rp-common/models/foreign-language-education', ['exports', 'ember-data', 'rp-common/mixins/models/year-date'], function (exports, _emberData, _yearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend(_yearDate.default, {
    profile: belongsTo('profile', { async: false }),

    language_id: attr('string'),
    language_name: attr('string'),
    language_proficiency_verbal: attr('string'),
    language_proficiency_written: attr('string'),

    language_details: computed('language_name', 'language_proficiency_verbal', 'language_proficiency_written', function () {
      var languageName = get(this, 'language_name');
      var verbalProficiency = get(this, 'language_proficiency_verbal');
      var writtenProficiency = get(this, 'language_proficiency_written');
      var verbalAndWritten = verbalProficiency && writtenProficiency ? verbalProficiency + '/' + writtenProficiency : verbalProficiency || writtenProficiency;
      return languageName && verbalAndWritten ? languageName + ' (' + verbalAndWritten + ')' : languageName;
    })
  });
});