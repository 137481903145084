define('rp-common/models/auto-update-root', ['exports', 'ember-data', 'rp-common/models/list-feed-content'], function (exports, _emberData, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listFeedContent.default.extend({
    isOtherFeedItemType: true,
    isAutoUpdateCard: true,
    card_id: _emberData.default.attr()
  });
});