define('rp-common/models/deployment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // relationships
    profile: _emberData.default.belongsTo('profile', { async: true }),

    // properties
    location_id: _emberData.default.attr('string'),
    location_name: _emberData.default.attr('string'),
    current: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    start_year: _emberData.default.attr('number'),
    start_month: _emberData.default.attr('number'),
    end_year: _emberData.default.attr('number'),
    end_month: _emberData.default.attr('number')
  });
});