define('rp-common/services/current-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var notEmpty = Ember.computed.notEmpty;
  var alias = Ember.computed.alias;
  exports.default = Service.extend({
    listProfile: alias('session.list_profile'),
    isSignedIn: notEmpty('listProfile'),
    profile: alias('session.profile'),
    user: alias('session.user'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('session', {
        list_profile: null,
        profile: null,
        user: null
      });
    }
  });
});