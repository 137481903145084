define('rp-common/services/trackers/qrc', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MY_TOPICS_FEED = exports.ANSWERS_FEED = exports.GROUP_PHOTOS_FEED = exports.PROFILE_COMMENTS_FEED = exports.PROFILE_POSTS_FEED = exports.SEARCH_FEED = exports.NOTIFICATION_FEED = exports.QRC_SHOW_PAGE = exports.TRENDING_FEED = exports.GROUP_DISCUSSIONS_FEED = exports.COMMAND_POST_FEED = exports.HOME_FEED = undefined;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var HOME_FEED = exports.HOME_FEED = 'home_feed';
  var COMMAND_POST_FEED = exports.COMMAND_POST_FEED = 'command_post_feed';
  var GROUP_DISCUSSIONS_FEED = exports.GROUP_DISCUSSIONS_FEED = 'group_discussions_feed';
  var TRENDING_FEED = exports.TRENDING_FEED = 'trending_feed';
  var QRC_SHOW_PAGE = exports.QRC_SHOW_PAGE = 'qrc_show_page';
  var NOTIFICATION_FEED = exports.NOTIFICATION_FEED = 'notification_feed';
  var SEARCH_FEED = exports.SEARCH_FEED = 'search_feed';
  var PROFILE_POSTS_FEED = exports.PROFILE_POSTS_FEED = 'profile_posts_feed';
  var PROFILE_COMMENTS_FEED = exports.PROFILE_COMMENTS_FEED = 'profile_comments_feed';
  var GROUP_PHOTOS_FEED = exports.GROUP_PHOTOS_FEED = 'group_photos_feed';
  var ANSWERS_FEED = exports.ANSWERS_FEED = 'answers_feed';
  var MY_TOPICS_FEED = exports.MY_TOPICS_FEED = 'my_topics_feed';

  exports.default = Service.extend({
    aggregator: service('trackers/aggregator'),

    trackImpression: function trackImpression(qrc, location, position) {
      this.trackImpressions([qrc], location, [position]);
    },
    trackImpressions: function trackImpressions(qrcs, location) {
      var _this = this;

      var positions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      get(this, 'aggregator').track(_aggregator.QRC, qrcs.map(function (qrc, index) {
        return _this.serializeQrc(qrc, location, 'impression', positions ? positions[index] : index);
      }));
    },
    trackView: function trackView(qrc, location) {
      this.trackViews([qrc], location);
    },
    trackViews: function trackViews(qrcs, location) {
      var _this2 = this;

      get(this, 'aggregator').track(_aggregator.QRC, qrcs.map(function (qrc) {
        return _this2.serializeQrc(qrc, location, 'view');
      }));
    },
    trackClick: function trackClick(qrc, location, position) {
      var immediate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      return get(this, 'aggregator').track(_aggregator.QRC, [this.serializeQrc(qrc, location, 'click', position)], immediate);
    },
    serializeQrc: function serializeQrc(qrc, location) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'impression';
      var position = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
      (false && !(get(qrc, 'id')) && Ember.assert('Must pass a valid qrc to track', get(qrc, 'id')));


      return {
        type: type,
        location: location,
        position: position,
        qrc_id: get(qrc, 'id')
      };
    }
  });
});