define('rp-common/models/list-search-preview', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    list_profile_search_results: hasMany('list-profile-search-result', { async: true }),
    list_group_search_results: hasMany('list-group-search-result', { async: true }),
    list_questions: hasMany('list-question', { async: true })
  });
});