define('rp-common/components/list-feed-bottom-bar/component', ['exports', 'rp-common/mixins/voting', 'ember-awesome-macros', 'rp-common/components/list-feed-bottom-bar/template'], function (exports, _voting, _emberAwesomeMacros, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var not = Ember.computed.not;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend(_voting.default, {
    layout: _template.default,
    classNames: ['list-feed-bottom-bar'],
    listModel: null,
    fullModel: null,
    listProfile: null,
    showShare: false,
    showMore: true,
    showInlineShareButtons: false,
    sharingUrl: null,
    inlineShareButtonsPostClick: null,
    commentFormVisible: false,
    moreMenuVisible: false,
    shareDialogVisible: false,

    shortLinkGenerator: service('short-link-generator'),
    currentUser: service(),
    ui: service(),
    i18n: service(),
    environment: service(),

    voteableModel: alias('fullModel'),
    voteableProfile: alias('listProfile'),
    isMyPost: (0, _emberAwesomeMacros.equal)('listProfile.id', 'currentProfile.id'),
    isNotMyPost: not('isMyPost'),
    commentsCount: alias('fullModel.r_and_c_count'),
    engagementAllowed: readOnly('listModel.engagement_allowed'),

    currentProfile: computed('currentUser.profile', 'currentSession.listProfile', function () {
      return get(this, 'currentUser.profile') || get(this, 'currentSession.listProfile');
    }),

    showShareButton: computed('showShare', 'fullModel.list_groups.@each.is_private', function () {
      var isPrivate = (get(this, 'fullModel.list_groups') || []).any(function (lg) {
        return lg.get('is_private');
      });
      var showShare = get(this, 'showShare');
      return !isPrivate && showShare && !get(this, 'environment.isNativeApp');
    }),

    actions: {
      afterPostResponse: function afterPostResponse(responseId) {
        this.sendAction('afterPostResponse', responseId);
        this.send('toggleComment');
      },
      toggleComment: function toggleComment() {
        if (get(this, 'commentFormVisible')) {
          set(this, 'commentFormVisible', false);
          return;
        }

        this.$('.comment-form textarea').trigger('focus');

        if (get(this, 'blocked')) {
          var ui = get(this, 'ui');
          ui.nativeAlert(get(this, 'i18n').t('blocking.universal'), 'Response Error');
          return;
        }

        this.toggleProperty('commentFormVisible');
      },
      toggleMoreMenu: function toggleMoreMenu() {
        this.toggleProperty('moreMenuVisible');
      },
      toggleShare: function toggleShare() {
        this.toggleProperty('shareDialogVisible');
        get(this, 'shortLinkGenerator').generate(get(this, 'fullModel'));
        if (get(this, 'shareDialogVisible')) {
          tryInvoke(this, 'shareButtonClicked');
        }
      }
    }
  });
});