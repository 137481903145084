define('rp-common/models/list-response', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    list_parent: _emberData.default.belongsTo('list-qrc-root', { polymorphic: true, async: true }),
    list_profile: _emberData.default.belongsTo('list-profile', { async: true }),
    full_response: _emberData.default.belongsTo('full-response', { async: true }),
    engagement_allowed: _emberData.default.attr('boolean')
  });
});