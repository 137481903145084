define('rp-common/utils/model-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * This is to ignore properties we know are clean. Like following/unfollowing a question
   * when we use ajax that goes outside of ember data
   *
   * @public
   * @param {object} model
   * @param {array} properties
   */
  function cleanProperties(model, properties) {
    model.send('willCommit');
    properties.forEach(function (property) {
      try {
        delete model._attributes[property];
      } catch (e) {}
    });
    model.send('didCommit');
  }

  /**
   * For ease of cleaning 1
   *
   * @public
   * @param {object} model
   * @param {string} property
   */
  function cleanProperty(model, property) {
    cleanProperties(model, [property]);
  }

  /**
   * Set each property to new value and assume unchanged
   *
   * @public
   * @param {object} model
   * @param {object} properties
   */
  function setAndCleanProperties(model, properties) {
    model.setProperties(properties);
    var _properties = [];
    for (var property in properties) {
      _properties.push(property);
    }
    cleanProperties(model, _properties);
  }

  exports.cleanProperty = cleanProperty;
  exports.cleanProperties = cleanProperties;
  exports.setAndCleanProperties = setAndCleanProperties;
});