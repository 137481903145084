define('rp-common/components/feed-jobs-list/component', ['exports', 'rp-common/components/feed-jobs-list/template', 'rp-common/services/trackers/job'], function (exports, _template, _job) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-jobs-list'],
    showViewAll: true,
    hasApplyButtons: false,
    feedItem: null,

    jobTracker: service('trackers/job'),

    isVisible: gt('jobs.length', 0),
    jobs: alias('feedItem.list_jobs'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var jobs = get(this, 'jobs');
      if (get(jobs, 'length')) {
        jobs.then(function () {
          return get(_this, 'jobTracker').trackImpressions(jobs, _job.JOB_HOME_FEED);
        });
      }
    },


    actions: {
      goToAllJobs: function goToAllJobs() {
        // Implement in child class
      },
      goToJob: function goToJob() {
        // Implement in child class
      },
      goToPreferences: function goToPreferences() {
        // Implement in child class
      },
      goToResourceCenter: function goToResourceCenter() {
        // Implement in child class
      }
    }
  });
});