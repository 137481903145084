define('rp-common/mixins/mention-limit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var gt = Ember.computed.gt;
  var lt = Ember.computed.lt;
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var computed = Ember.computed;
  var MAX_MENTIONS = exports.MAX_MENTIONS = 20;

  var TAGGED_RE = exports.TAGGED_RE = /\[~\d+:[^\]]+\]/ig;

  exports.default = Mixin.create({
    maxMentions: MAX_MENTIONS,
    mentionCount: readOnly('matchedMentions.length'),
    hasMentions: gt('mentionCount', 0),
    hasMentionsRemaining: lt('mentionCount', MAX_MENTIONS),

    matchedMentions: computed('mentionBody', function () {
      var body = get(this, 'mentionBody');

      if (!body) {
        return [];
      }

      return this.parseMentions(body);
    }),

    parseMentions: function parseMentions(source) {
      var matches = source.match(TAGGED_RE);
      if (!matches) {
        return [];
      }
      return matches;
    }
  });
});