define('rp-common/models/survey', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Survey = _emberData.default.Model.extend({
    // relationships
    survey_options: _emberData.default.hasMany('survey-option', {
      async: false
    }),

    // attributes
    already_voted_on_survey: _emberData.default.attr('boolean'),

    rallpoint_survey: _emberData.default.attr('boolean'),

    totalVotes: computed('survey_options.@each.votes', function () {
      var votes = 0;
      this.get('survey_options').forEach(function (opt) {
        votes += opt.get('votes');
      });
      return votes;
    })
  });
  exports.default = Survey;
});