define('rp-common/group-follow/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({
    apiService: service(),
    errorHandlers: service(),

    follow: function follow(groupId) {
      return get(this, 'apiService').postRequest('/groups/' + groupId + '/follow').catch(get(this, 'errorHandlers').logError);
    },
    unfollow: function unfollow(groupId) {
      return get(this, 'apiService').postRequest('/groups/' + groupId + '/unfollow').catch(get(this, 'errorHandlers').logError);
    }
  });
});