define('rp-common/services/single-action-button-visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var set = Ember.set;
  exports.default = Service.extend({
    currentlyVisible: true,

    hide: function hide() {
      set(this, 'currentlyVisible', false);
    },
    show: function show() {
      set(this, 'currentlyVisible', true);
    }
  });
});