define('rp-common/models/branch', ['exports', 'ember-data', 'rp-common/models/groupable'], function (exports, _emberData, _groupable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _groupable.default.extend({
    // relationships
    profiles: _emberData.default.hasMany('profile', { async: true }),
    ranks: _emberData.default.hasMany('rank', { async: true }),
    units: _emberData.default.hasMany('unit', { async: true }),

    // attributes
    global_branch_name: _emberData.default.attr('string'),
    image: _emberData.default.attr('string')
  });
});