define('rp-common/serializers/profile', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data && payload.data.profile && payload.data.user) {
        payload.data.profile.session_id = payload.data.user.session_id = 'current';
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      if (relationship.key === 'temp_profile_photo' || relationship.key === 'current_location_duty_station' || relationship.key === 'hometown_duty_station') {
        this._super(record, json, relationship);
      }
    },
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'tags') {
        if (record.hasMany('tags') && !!record.hasMany('tags').length) {
          json.tag_ids = record.hasMany('tags').mapBy('id');
        }
      }
    },
    serializeAttribute: function serializeAttribute(record, json, key, attrs) {
      if (key === 'birthday') {
        var bday = window.moment(record.get(key));
        json[key] = bday.format('YYYY-MM-DD');
        return;
      }

      this._super(record, json, key, attrs);
    },
    normalize: function normalize(model, hash, prop) {
      if (prop === 'profile' && hash.birthday) {
        var bday = hash.birthday.split('-');
        hash.birthday = new Date(bday[0], bday[1] - 1, bday[2]).toString();
      }
      return this._super.apply(this, arguments);
    }
  });
});