define('rp-common/services/server-maintenance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var equal = Ember.computed.equal;


  var STATE_DOWN = 'down';
  var STATE_UP = 'up';

  exports.default = Service.extend({
    state: STATE_UP,
    isDown: equal('state', STATE_DOWN),
    isUp: equal('state', STATE_UP),

    serverDown: function serverDown() {
      this.set('state', STATE_DOWN);
    },
    serverUp: function serverUp() {
      this.set('state', STATE_UP);
    }
  });
});