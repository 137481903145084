define('ember-autoresize/ext/text-area', ['ember-autoresize/mixins/autoresize'], function (_autoresize) {
  'use strict';

  var TextArea = Ember.TextArea;
  var isEmpty = Ember.isEmpty;
  var isNone = Ember.isNone;
  var _get = Ember.get;
  var computed = Ember.computed;


  /**
    @element text-area
   */
  TextArea.reopen(_autoresize.default, {

    /**
      By default, textareas only resize
      their height.
       @attribute shouldResizeHeight
      @type Boolean
     */
    shouldResizeHeight: true,

    /**
      Whitespace should be treated as significant
      for text areas.
       @attribute significantWhitespace
      @default true
      @type Boolean
     */
    significantWhitespace: true,

    /**
      Optimistically resize the height
      of the textarea so when users reach
      the end of a line, they will be
      presented with space to begin typing.
       If a placeholder is set,
      it will be used instead of the value.
       @attribute autoResizeText
      @type String
     */
    autoResizeText: computed('value', 'placeholder', {
      get: function get() {
        var placeholder = _get(this, 'placeholder');
        var value = _get(this, 'value');
        var fillChar = '@';

        if (isEmpty(value)) {
          return isEmpty(placeholder) ? fillChar : placeholder + fillChar;
        }

        if (isNone(value)) {
          value = '';
        }

        return value + fillChar;
      }
    })

  });
});