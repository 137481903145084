define('rp-common/models/affinity-score', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    profile: belongsTo('profile'),

    formattedScoreCaregiver: attr(),
    scoreCaregiver: attr(),
    scoreFuture1: attr(),
    scoreFuture2: attr(),
    scoreFuture3: attr(),
    scoreFuture4: attr(),
    scoreFuture5: attr()
  });
});