define('rp-common/utils/qrc-utils', ['exports', 'rp-common/models/list-question', 'rp-common/models/list-shared-link', 'rp-common/models/list-status-update', 'rp-common/models/list-response', 'rp-common/models/list-comment', 'rp-common/models/full-question', 'rp-common/models/full-shared-link', 'rp-common/models/full-status-update', 'rp-common/models/full-response', 'rp-common/models/full-comment'], function (exports, _listQuestion, _listSharedLink, _listStatusUpdate, _listResponse, _listComment, _fullQuestion, _fullSharedLink, _fullStatusUpdate, _fullResponse, _fullComment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FULL_COMMENT = exports.FULL_RESPONSE = exports.FULL_STATUS_UPDATE = exports.FULL_SHARED_LINK = exports.FULL_QUESTION = exports.LIST_COMMENT = exports.LIST_RESPONSE = exports.LIST_STATUS_UPDATE = exports.LIST_SHARED_LINK = exports.LIST_QUESTION = undefined;
  exports.qrcListType = qrcListType;
  exports.qrcFullType = qrcFullType;
  exports.isListQuestion = isListQuestion;
  exports.isListSharedLink = isListSharedLink;
  exports.isListStatusUpdate = isListStatusUpdate;
  exports.isListResponse = isListResponse;
  exports.isListComment = isListComment;
  exports.isFullQuestion = isFullQuestion;
  exports.isFullSharedLink = isFullSharedLink;
  exports.isFullStatusUpdate = isFullStatusUpdate;
  exports.isFullResponse = isFullResponse;
  exports.isFullComment = isFullComment;
  var LIST_QUESTION = exports.LIST_QUESTION = 'list-question';
  var LIST_SHARED_LINK = exports.LIST_SHARED_LINK = 'list-shared-link';
  var LIST_STATUS_UPDATE = exports.LIST_STATUS_UPDATE = 'list-status-update';
  var LIST_RESPONSE = exports.LIST_RESPONSE = 'list-response';
  var LIST_COMMENT = exports.LIST_COMMENT = 'list-comment';
  var FULL_QUESTION = exports.FULL_QUESTION = 'full-question';
  var FULL_SHARED_LINK = exports.FULL_SHARED_LINK = 'full-shared-link';
  var FULL_STATUS_UPDATE = exports.FULL_STATUS_UPDATE = 'full-status-update';
  var FULL_RESPONSE = exports.FULL_RESPONSE = 'full-response';
  var FULL_COMMENT = exports.FULL_COMMENT = 'full-comment';

  function qrcListType(qrc) {
    if (qrc instanceof _listQuestion.default) {
      return LIST_QUESTION;
    } else if (qrc instanceof _listSharedLink.default) {
      return LIST_SHARED_LINK;
    } else if (qrc instanceof _listStatusUpdate.default) {
      return LIST_STATUS_UPDATE;
    } else if (qrc instanceof _listResponse.default) {
      return LIST_RESPONSE;
    } else if (qrc instanceof _listComment.default) {
      return LIST_COMMENT;
    }

    return 'unknown';
  }

  function qrcFullType(qrc) {
    if (qrc instanceof _fullQuestion.default) {
      return FULL_QUESTION;
    } else if (qrc instanceof _fullSharedLink.default) {
      return FULL_SHARED_LINK;
    } else if (qrc instanceof _fullStatusUpdate.default) {
      return FULL_STATUS_UPDATE;
    } else if (qrc instanceof _fullResponse.default) {
      return FULL_RESPONSE;
    } else if (qrc instanceof _fullComment.default) {
      return FULL_COMMENT;
    }

    return 'unknown';
  }

  function isListQuestion(qrc) {
    return qrcListType(qrc) === LIST_QUESTION;
  }

  function isListSharedLink(qrc) {
    return qrcListType(qrc) === LIST_SHARED_LINK;
  }

  function isListStatusUpdate(qrc) {
    return qrcListType(qrc) === LIST_STATUS_UPDATE;
  }

  function isListResponse(qrc) {
    return qrcListType(qrc) === LIST_RESPONSE;
  }

  function isListComment(qrc) {
    return qrcListType(qrc) === LIST_COMMENT;
  }

  function isFullQuestion(qrc) {
    return qrcFullType(qrc) === FULL_QUESTION;
  }

  function isFullSharedLink(qrc) {
    return qrcFullType(qrc) === FULL_SHARED_LINK;
  }

  function isFullStatusUpdate(qrc) {
    return qrcFullType(qrc) === FULL_STATUS_UPDATE;
  }

  function isFullResponse(qrc) {
    return qrcFullType(qrc) === FULL_RESPONSE;
  }

  function isFullComment(qrc) {
    return qrcFullType(qrc) === FULL_COMMENT;
  }

  exports.default = {
    isListQuestion: isListQuestion,
    isListSharedLink: isListSharedLink,
    isListStatusUpdate: isListStatusUpdate,
    isListResponse: isListResponse,
    isListComment: isListComment,
    isFullQuestion: isFullQuestion,
    isFullSharedLink: isFullSharedLink,
    isFullStatusUpdate: isFullStatusUpdate,
    isFullResponse: isFullResponse,
    isFullComment: isFullComment
  };
});