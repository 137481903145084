define('rp-common/models/list-feed-raise-a-glass', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    count_profiles_raised_a_glass: attr(),
    sample_contacts: hasMany('list-profile', { async: true }),

    isOtherFeedItemType: true,
    feedComponentName: 'feed-raise-a-glass'
  });
});