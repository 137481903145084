define('rp-common/components/labeled-checkbox/component', ['exports', 'rp-common/components/labeled-checkbox/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    classNames: ['labeled-checkbox'],
    classNameBindings: ['checked', 'disabled'],
    label: '',
    checked: false,
    disabled: false,

    click: function click() {
      this.toggleProperty('checked');

      if (get(this, 'checked') && this.attrs.onChecked) {
        this.attrs.onChecked();
      }
    }
  });
});