define('rp-common/utils/link-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    generateExternalLink: function generateExternalLink(href, value, config) {
      if (config.APP.APP_MODE === 'web') {
        return '<a href="' + href + '" target="_blank">' + value + '</a>';
      } else {
        return '<a onclick="window.open(\'' + href + '\', \'_system\'); return false;">' + value + '</a>';
      }
    },
    hostFromUrl: function hostFromUrl(url) {
      if (!url) {
        return url;
      }

      var $link = $('<a/>').attr('href', this.makeAbsolute(url));

      return $link.get(0).hostname.replace(/\.$/, '').split('.').slice(-2).join('.');
    },
    makeAbsolute: function makeAbsolute(url) {
      var ssl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!url || /^http/i.test(url)) {
        return url;
      }

      var protocol = 'http' + (ssl ? 's' : '') + '://';

      return '' + protocol + url;
    }
  };
});