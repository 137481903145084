define('rp-common/models/comment', ['exports', 'ember-data', 'rp-common/mixins/models/picturable'], function (exports, _emberData, _picturable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;


  var comment = _emberData.default.Model.extend(_picturable.default, {
    list_response: _emberData.default.belongsTo('list-response', { async: true }),
    profile: _emberData.default.belongsTo('profile', { async: true }),
    body: _emberData.default.attr('string'),
    pictures: _emberData.default.hasMany('picture', { async: true }),
    link_data: _emberData.default.attr(),
    up_voted: _emberData.default.attr('boolean'),
    anonymous: _emberData.default.attr('boolean', { defaultValue: false }),

    rootId: readOnly('list_response.list_parent.id'),

    serializeForRestore: function serializeForRestore() {
      return {
        body: this.get('body'),
        response: this.get('response.id'),
        pictures: this.get('pictures').map(function (p) {
          return { 'id': p.get('id'), 'url': p.get('url') };
        })
      };
    }
  });

  comment.reopenClass({
    clearEmpty: function clearEmpty(store) {
      store.peekAll('comment').forEach(function (comment) {
        if (comment.get('isNew')) {
          comment.unloadRecord();
        }
      });
    }
  });

  exports.default = comment;
});