define('ember-autoresize/ext/text-field', ['ember-autoresize/mixins/autoresize'], function (_autoresize) {
  'use strict';

  var TextField = Ember.TextField;
  var isEmpty = Ember.isEmpty;
  var _get = Ember.get;
  var computed = Ember.computed;


  /**
    @element input
   */
  TextField.reopen(_autoresize.default, {

    /**
      By default, text fields only
      resize their width.
       @attribute shouldResizeWidth
      @default true
      @type Boolean
     */
    shouldResizeWidth: true,

    /**
      Whitespace should be treated as significant
      for text fields.
       @attribute significantWhitespace
      @default true
      @type Boolean
     */
    significantWhitespace: true,

    /**
      This provides a single character
      so users can click into an empty
      text field without it being too small
       If a placeholder is set,
      it will be used instead.
       @attribute autoResizeText
      @type String
     */
    autoResizeText: computed('value', 'placeholder', {
      get: function get() {
        var placeholder = _get(this, 'placeholder');
        var value = _get(this, 'value');

        if (isEmpty(value)) {
          return isEmpty(placeholder) ? '.' : placeholder;
        }

        return value;
      }
    })
  });
});