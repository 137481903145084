define('rp-common/components/feed-raise-a-glass/component', ['exports', 'rp-common/components/feed-raise-a-glass/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['feed-raise-a-glass'],
    layout: _template.default,

    environment: service(),

    raiseAGlassInfo: readOnly('feedItem'),
    count: readOnly('raiseAGlassInfo.count_profiles_raised_a_glass'),
    contactListProfile: readOnly('raiseAGlassInfo.sample_contacts.firstObject'),

    profileLink: computed('contactListProfile.slug', function () {
      return '/profiles/' + get(this, 'contactListProfile.slug');
    })
  });
});