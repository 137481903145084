define('rp-common/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var equal = Ember.computed.equal;


  var User = _emberData.default.Model.extend({
    // relationships
    session: _emberData.default.belongsTo('session', {
      async: false
    }),

    profile: _emberData.default.belongsTo('profile', { async: true }),

    // attributes
    email: _emberData.default.attr('string'),
    current_password: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    military_affiliation: _emberData.default.attr('string'),
    military_email: _emberData.default.attr('string'),
    verified: _emberData.default.attr('boolean'),
    user_type: _emberData.default.attr('string'),
    rank_group: _emberData.default.attr('string'),
    is_irr: _emberData.default.attr('number'),
    is_ing: _emberData.default.attr('number'),
    is_retired: _emberData.default.attr('number'),
    notification_messages: _emberData.default.attr('boolean'),
    notification_pymk: _emberData.default.attr('boolean'),
    notification_profile_views: _emberData.default.attr('boolean'),
    notification_digest: _emberData.default.attr('boolean'),
    notification_acton: _emberData.default.attr('boolean'), // Once turned off, cannot be turned on
    notification_frequency_new_connections: _emberData.default.attr('number'),
    notification_frequency_discussions_responses_to_posts: _emberData.default.attr('number'),
    notification_frequency_discussions_votes_on_posts: _emberData.default.attr('number'),
    notification_frequency_discussions_posts_mentioned_in: _emberData.default.attr('number'),
    has_facebook: _emberData.default.attr('boolean'),
    has_yahoo: _emberData.default.attr('boolean'),
    has_windowslive: _emberData.default.attr('boolean'),
    has_gmail: _emberData.default.attr('boolean'),

    // computed
    notVerified: not('verified'),
    isServiceMember: equal('user_type', 'Servicemember'),
    isVeteran: equal('user_type', 'Veteran')
  });

  User.reopen({
    getToken: function getToken() {
      var adapter = this.store.adapterFor('user');
      return adapter.getToken(this.get('id'));
    },
    updateDeviceToken: function updateDeviceToken(token, platform) {
      var adapter = this.store.adapterFor('user');
      return adapter.updateDeviceToken(this.get('id'), token, platform);
    }
  });

  exports.default = User;
});