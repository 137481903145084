define('rp-common/services/error-handlers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var Logger = Ember.Logger;
  exports.default = Service.extend({
    ui: service(),

    logError: function logError(err) {
      Logger.error('Caught Error:', err);
    },


    // Tell the browser and send report to Bugsnag
    // Should be the go-to option
    reportError: function reportError(err) {
      this.logError(err);
      if (window.Bugsnag) {
        var bse = err instanceof Error ? err : new Error(err);
        window.Bugsnag.notifyException(bse);
      }
    },


    // Tell the browser, send report to bugsnag, show a message to the user.
    // Recommended that you call this separately and write a friendlier message since users will see it.
    notifyUserOfError: function notifyUserOfError(err, friendlyMessage) {
      var heading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Error';
      var reportError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      var ui = get(this, 'ui');
      if (reportError) {
        this.reportError(err);
      }
      if (!ui) {
        this.nativeAlert(friendlyMessage, heading);
      }
    }
  });
});