define('rp-common/serializers/comment', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _application.default.extend({
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      if (relationship.key === 'list_response') {
        json.response_id = record.belongsTo('list_response').id;
      } else {
        this._super(record, json, relationship);
      }
    },
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'pictures') {
        var pictures = record.hasMany('pictures');
        json.images_ids = isArray(pictures) ? pictures.mapBy('id') : [];
      }
    }
  });
});