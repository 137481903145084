define('rp-common/services/popular-jobs', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),

    next: function next() {
      var howMany = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 4;

      return get(this, 'nextFromServer').perform(howMany);
    },


    nextFromServer: (0, _emberConcurrency.task)(_emberConcurrency.enqueue, regeneratorRuntime.mark(function _callee(howMany) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'store').query('list-job', { page: 1, per_page: howMany, sample_top: true });

            case 2:
              return _context.abrupt('return', _context.sent);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  });
});