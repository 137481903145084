define('ember-awesome-macros/is-empty', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key) {
    if (typeOf(key) === 'string') {
      return (0, _emberMacroHelpers.computed)(key, key + '.{length,size}', isEmpty);
    }
    return (0, _emberMacroHelpers.computed)(key, isEmpty);
  };

  var isEmpty = Ember.isEmpty;
  var typeOf = Ember.typeOf;
});