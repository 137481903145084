define('rp-common/serializers/session', ['exports', 'rp-common/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(record, options) {
      options = options || {};
      return this._super(record, options);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload /* , id, requestType */) {
      if (!!payload.data && !!payload.data.profile && !!payload.data.user) {
        payload.data.profile.session_id = payload.data.user.session_id = 'current';
      }
      return this._super.apply(this, arguments);
    },
    pushPayload: function pushPayload(store, payload) {
      payload = payload.data;
      this._super(store, payload);
    }
  });
});