define('rp-common/models/full-response', ['exports', 'ember-data', 'rp-common/mixins/models/picturable'], function (exports, _emberData, _picturable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_picturable.default, {
    list_question: _emberData.default.belongsTo('list_question', { async: true }),
    list_comments: _emberData.default.hasMany('comment', { async: true }),
    list_response: _emberData.default.belongsTo('list_response', { async: true }),
    pictures: _emberData.default.hasMany('picture', { async: true }),

    body: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    link_data: _emberData.default.attr(),
    comment_count: _emberData.default.attr('number'),
    up_voted: _emberData.default.attr('boolean'),
    down_voted: _emberData.default.attr('boolean'),
    net_votes: _emberData.default.attr('number'),
    up_votes: _emberData.default.attr('number'),
    down_votes: _emberData.default.attr('number'),
    pinned: _emberData.default.attr('boolean'),
    high_risk: _emberData.default.attr('boolean'),
    author: _emberData.default.attr('boolean')
  });
});