define('rp-common/mixins/editable-link-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var $ = Ember.$;
  exports.default = Mixin.create({
    editableTextAreaClass: '.ember-text-area',

    apiService: service(),
    urlRegex: /\b(https?:\/\/|www\.)[^\s]*/ig,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this, function () {
        $(get(_this, 'editableTextAreaClass')).focusout(function () {
          _this.checkBodyForLinks(get(_this, 'editable_body'));
        });
      });
    },


    /*
      Observes editable_body, checks for SPACE or ENTER and calls checkBodyForLinks
    */
    editableBodyObserver: function () {
      var SPACE = 32;
      var ENTER = 10;
      var body = get(this, 'editable_body');
      var lastCharCode = null;

      // Same/similar logic is in Rails.
      // Whenever we encounter a space, go to the last word and see if it's a URL
      if (!body) {
        return;
      }
      lastCharCode = body.slice(-1).charCodeAt(0);
      if (lastCharCode !== SPACE && lastCharCode !== ENTER) {
        return;
      }
      this.checkBodyForLinks(body);
    }.observes('editable_body'),

    /*
      Looks for potential links and calls fetchLinkData when one is found
    */
    checkBodyForLinks: function checkBodyForLinks(body) {
      var _this2 = this;

      var forceLastToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!body) {
        return;
      }

      var bodyParts = body.trim().split(' ');
      var bodyLen = bodyParts.length;
      var link = bodyParts[bodyLen - 1];
      var apiService = get(this, 'apiService');

      if ((link.match(get(this, 'urlRegex')) || forceLastToken) && get(this, 'editable_link_data.url') !== link) {
        this.send('onSiteScraperStart');

        apiService.getRequest('site_scrape?url=' + encodeURIComponent(link), 'api').then(function (linkData) {
          _this2.send('onSiteScraperEnd');
          set(_this2, 'editable_link_data', linkData);
        });
      }
    },


    actions: {
      onSiteScraperStart: function onSiteScraperStart() {},
      onSiteScraperEnd: function onSiteScraperEnd() {}
    }
  });
});