define('rp-common/models/session', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    user: belongsTo('user', { async: true }),
    profile: belongsTo('profile', { async: true }),

    email: attr('string'),
    password: attr('string'),
    rp_token: attr('string'),

    social_provider: attr('string'),
    social_uid: attr('string'),
    social_token: attr('string'),

    dfp_age_bucket: attr('string'),
    dfp_educations: attr('string'), // JSON encoded array
    dfp_deployments: attr('string'), // JSON encoded array
    has_suggested_social_connections: attr('boolean')
  });
});