define('rp-common/serializers/educational-preference', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship.key === 'academic_degrees') {
        var degrees = record.hasMany('academic_degrees');
        json.academic_degree_ids = isArray(degrees) ? degrees.mapBy('id') : [];
      }
    }
  });
});