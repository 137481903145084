define('ember-awesome-macros/computed', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _computed.default;
    }
  });
});