define('ember-awesome-macros/promise/-utils', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.wrapPromiseProxy = wrapPromiseProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var resolve = Ember.RSVP.resolve;
  function wrapPromiseProxy(Proxy) {
    var PromiseProxy = Proxy.extend(PromiseProxyMixin);

    return (0, _emberMacroHelpers.curriedComputed)(function (promise) {
      if (promise === undefined) {
        promise = resolve(undefined);
      }

      return PromiseProxy.create({
        promise: promise
      });
    });
  }
});