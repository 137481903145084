define('rp-common/mixins/has-images', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  exports.default = Mixin.create({
    actions: {
      storeAndAddImage: function storeAndAddImage(e, data) {
        var photo = get(this, 'store').push({
          data: {
            id: data.result.image_id,
            type: 'picture',
            attributes: {
              url: data.result.large_src,
              thumb: data.result.src
            }
          }
        });
        // Make sure we update all latest URLs
        photo.reload();
        get(this, 'pictures').pushObject(photo);
      },
      removeImage: function removeImage(picture) {
        get(this, 'pictures').removeObject(picture);
      }
    }
  });
});