define('rp-common/services/environment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var get = Ember.get;
  var not = Ember.computed.not;


  var KEY_ENVIRONMENT = 'environment';
  var KEY_PLATFORM = 'APP.PLATFORM';
  var KEY_ISLANDS = 'APP.IS_ISLANDS';
  var ENV_PRODUCTION = 'production';
  var PLATFORM_DESKTOP = 'desktop';

  exports.default = Service.extend({
    getConfig: function getConfig() {
      var prop = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var config = getOwner(this).resolveRegistration('config:environment');
      return prop ? get(config, prop) : config;
    },
    isProduction: function isProduction() {
      return this.getConfig(KEY_ENVIRONMENT) === ENV_PRODUCTION;
    },


    isDesktop: computed(function () {
      return this.getConfig(KEY_PLATFORM) === PLATFORM_DESKTOP;
    }),

    isMobile: not('isDesktop'),

    isNativeApp: computed(function () {
      var config = getOwner(this).resolveRegistration('config:environment');
      return config.APP.APP_MODE !== 'web';
    }),

    isIsland: computed(function () {
      return this.getConfig(KEY_ISLANDS);
    })
  });
});