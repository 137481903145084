define('rp-common/models/tactical-education', ['exports', 'ember-data', 'rp-common/mixins/models/month-year-date'], function (exports, _emberData, _monthYearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_monthYearDate.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),

    // properties
    tactical_school_id: _emberData.default.attr('string'),
    tactical_school_name: _emberData.default.attr('string')
  });
});