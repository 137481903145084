define('rp-common/services/voting', ['exports', 'rp-common/utils/reputation-library'], function (exports, _reputationLibrary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var MODEL_NAME_TO_PATH = {
    'full-question': 'questions',
    'full-response': 'responses',
    'full-comment': 'comments',
    'question': 'questions',
    'comment': 'comments',
    'response': 'responses',
    'shared-link': 'shared_links',
    'full-shared-link': 'shared_links',
    'status-update': 'status_updates',
    'full-status-update': 'status_updates'
  };

  var MODEL_NAME_TO_VERSION = {
    'full-question': 'api/v1',
    'full-response': 'api/v1',
    'full-comment': 'api/v1',
    'question': 'api/v1',
    'comment': 'api/v1',
    'response': 'api/v1',
    'shared-link': 'api/v2',
    'full-shared-link': 'api/v2',
    'status-update': 'api/v2',
    'full-status-update': 'api/v2'
  };

  exports.default = Service.extend({
    apiService: service(),
    ui: service(),
    i18n: service(),
    flashMessages: service(),

    getModelPath: function getModelPath(model) {
      var modelName = model.get('constructor.modelName') || model.get('content.constructor.modelName');
      return MODEL_NAME_TO_PATH[modelName];
    },
    getModelVersion: function getModelVersion(model) {
      var modelName = model.get('constructor.modelName') || model.get('content.constructor.modelName');
      return MODEL_NAME_TO_VERSION[modelName];
    },
    votePostRequest: function votePostRequest(model, path) {
      var apiService = this.get('apiService');
      var modelId = model.get('id');
      // This can go away once we have a better vote API
      var modelPath = this.getModelPath(model);
      return apiService.postRequest(modelPath + '/' + modelId + '/' + path, {}, this.getModelVersion(model));
    },
    vote: function vote(model, action) {
      return this.votePostRequest(model, 'vote?vote=' + action);
    },
    unvote: function unvote(model) {
      return this.votePostRequest(model, 'unvote');
    },
    reportVoteActionSuccess: function reportVoteActionSuccess() {
      var didUnvote = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'up';

      if (didUnvote) {
        return get(this, 'flashMessages').success(get(this, 'i18n').t('votingFlash.unvote'));
      }
      if (action === 'up') {
        get(this, 'flashMessages').success(get(this, 'i18n').t('votingFlash.voteUp', { points: _reputationLibrary.default.voted_on_QR }));
      } else {
        get(this, 'flashMessages').success(get(this, 'i18n').t('votingFlash.voteDown'));
      }
    },
    reportVoteFailure: function reportVoteFailure(errorMessages) {
      get(this, 'flashMessages').danger(get(this, 'i18n').t('votingFlash.error'));
      var err = typeof errorMessages === 'string' ? errorMessages : errorMessages[0];
      var title = get(this, 'i18n').t('votingNativeAlertTitle');
      var message = get(this, 'i18n').t('votingNativeAlertMessage', { err: err });
      get(this, 'ui').nativeAlert(message, title);
    },
    confirmDownvote: function confirmDownvote() {
      return get(this, 'ui').nativeConfirm(get(this, 'i18n').t('confirmations.bodies.downVote'), {
        title: get(this, 'i18n').t('confirmations.titles.downVote'),
        buttonLabels: [get(this, 'i18n').t('confirmations.cancelButton.downVote'), get(this, 'i18n').t('confirmations.confirmButton.downVote')],
        confirmButtonText: get(this, 'i18n').t('confirmations.confirmButton.downVote')
      });
    }
  });
});