define('rp-common/components/facebook-share-button/component', ['exports', 'rp-common/components/facebook-share-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    fullModel: null,
    sharingUrl: null,
    postClick: null,

    shortLink: readOnly('fullModel.short_group_url'),

    // Adds the protocol and domain to the url if missing, since FB.ui doesn't infer it.
    fullSharingUrl: computed('sharingUrl', function () {
      if (get(this, 'sharingUrl').indexOf('http') > -1) {
        return get(this, 'sharingUrl');
      }
      var origin = location.origin || location.protocol + '//' + location.host;
      var route = get(this, 'sharingUrl');
      return '' + origin + route;
    }),

    click: function click() {
      // For now, fire this regardless of whether user cancels
      tryInvoke(this, 'postClick', ['facebook']);

      window.FB.ui({
        method: 'share',
        href: get(this, 'fullSharingUrl') || get(this, 'shortLink')
      });

      return false;
    }
  });
});