define('rp-common/models/list-feed-groups-listing', ['exports', 'ember-data', 'rp-common/models/list-feed-content'], function (exports, _emberData, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listFeedContent.default.extend({
    isOtherFeedItemType: true,
    feedComponentName: 'feed-suggested-groups',
    list_groups: _emberData.default.hasMany('list-group', { async: false })
  });
});