define('ember-lazy-video/lazy-video-providers/instagram', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = {
    embedUrl: function embedUrl(videoId) {
      return 'https://instagram.com/p/' + videoId + '/embed';
    },
    thumbnailUrl: function thumbnailUrl(videoId) {
      return RSVP.resolve('https://instagram.com/p/' + videoId + '/media/?size=l');
    }
  };
});