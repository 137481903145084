define('rp-common/models/additional-speciality', ['exports', 'ember-data', 'rp-common/mixins/models/year-date'], function (exports, _emberData, _yearDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend(_yearDate.default, {
    // relationships
    profile: _emberData.default.belongsTo('profile', {
      async: false
    }),
    speciality: _emberData.default.belongsTo('speciality', { async: true }),

    // properties
    speciality_name: _emberData.default.attr('string'),

    specialityId: computed('speciality.id', function () {
      return this.get('speciality.id');
    })
  });
});