define('rp-common/models/list-question', ['exports', 'ember-data', 'rp-common/models/list-qrc-root'], function (exports, _emberData, _listQrcRoot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COMMAND_POST_TYPES = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  var get = Ember.get;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  var COMMAND_POST_TYPES = exports.COMMAND_POST_TYPES = ['article', 'qAndA', 'video', 'podcast'];

  exports.default = _listQrcRoot.default.extend({
    list_profile: _emberData.default.belongsTo('list-profile', { async: true }),
    full_question: _emberData.default.belongsTo('full-question', { async: true }),

    title: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    is_private: _emberData.default.attr('boolean', { defaultValue: false }),
    command_post_type: _emberData.default.attr('number'),

    full_model: alias('full_question'),
    isCommandPost: notEmpty('command_post_type'),

    commandPostTypeName: computed('command_post_type', function () {
      return COMMAND_POST_TYPES[get(this, 'command_post_type')];
    }),

    seoTitle: computed('title', function () {
      return get(this, 'title') + ' | RallyPoint';
    }),

    seoMetaDescription: computed('title', 'full_model.body', function () {
      var title = get(this, 'title');
      var body = void 0;
      return get(this, 'full_model').then(function (fullModel) {
        body = get(fullModel, 'body');
        if (body) {
          return title + ': ' + body;
        }
        return title;
      });
    }),

    sharedContentFieldsHash: computed('full_model', 'full_model.list_groups.@each.name', function () {
      var _this = this;

      var structuredData = { '@type': 'Question' };
      return RSVP.all([get(this, 'full_model'), get(this, 'seoMetaDescription'), get(this, 'profileDataFieldsHash')]).then(function (results) {
        var _results = _slicedToArray(results, 3),
            fullModel = _results[0],
            seoMetaDescription = _results[1],
            profileDataFieldsHash = _results[2];

        structuredData['downvoteCount'] = get(fullModel, 'down_votes');
        structuredData['upvoteCount'] = get(fullModel, 'up_votes');
        structuredData['text'] = seoMetaDescription;
        structuredData['author'] = profileDataFieldsHash;
        return get(fullModel, 'list_groups');
      }).then(function (listGroups) {
        structuredData['keywords'] = listGroups.map(function (group) {
          return get(group, 'name');
        });
        return structuredData;
      }).catch(function (error) {
        _this.reportError(error);
      });
    }),

    firstPictureUrl: computed('full_model', 'full_model.pictures.@each.url', function () {
      return get(this, 'full_model').then(function (fullModel) {
        return get(fullModel, 'pictures');
      }).then(function (pictures) {
        return get(pictures, 'firstObject.url');
      });
    }),

    profileDataFieldsHash: computed(function () {
      return get(this, 'list_profile').then(function (profile) {
        return {
          '@type': 'Person',
          'name': get(profile, 'full_name'),
          'url': window.location.host + '/profiles/' + get(profile, 'slug')
        };
      });
    }),

    seoStructuredData: computed(function () {
      var _this2 = this;

      return RSVP.all([get(this, 'full_model'), get(this, 'profileDataFieldsHash'), get(this, 'sharedContentFieldsHash'), get(this, 'firstPictureUrl')]).then(function (resultsArray) {
        var _resultsArray = _slicedToArray(resultsArray, 4),
            fullModel = _resultsArray[0],
            profileDataFieldsHash = _resultsArray[1],
            sharedContentFieldsHash = _resultsArray[2],
            firstPictureUrl = _resultsArray[3];

        return {
          '@context': 'http://schema.org/',
          '@type': 'SocialMediaPosting',
          '@id': get(_this2, 'canonical_url'),
          'headline': get(_this2, 'title'),
          'discussionUrl': get(_this2, 'canonical_url'),
          'datePublished': get(fullModel, 'created_at'),
          'author': profileDataFieldsHash,
          'commentCount': get(fullModel, 'formattedCommentsCount'),
          'image': firstPictureUrl,
          'sharedContent': sharedContentFieldsHash
        };
      });
    })
  });
});