define('rp-common/components/feed-card-close-button/component', ['exports', 'rp-common/components/feed-card-close-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    layout: _template.default,
    classNames: ['feed-card-close-button'],
    classNameBindings: ['menuIsOpenUp'],
    moreMenuVisible: false,
    ignoreBodyClickElements: null,
    menuIsOpenUp: false,

    didInsertElement: function didInsertElement() {
      set(this, 'ignoreBodyClickElements', this.$('.x-icon-menu'));
    },


    actions: {
      toggleMenu: function toggleMenu() {
        if (this.isDestroyed) {
          return;
        }
        this.toggleProperty('moreMenuVisible');
        if (!get(this, 'moreMenuVisible')) {
          return;
        }
        var vh = document.documentElement.clientHeight;
        var elVerticalPosition = this.element.getBoundingClientRect().top;
        set(this, 'menuIsOpenUp', elVerticalPosition > vh * 0.6666);
      }
    }
  });
});