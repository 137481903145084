define('rp-common/services/trackers/desktop-page-view', ['exports', 'rp-common/services/trackers/mobile-page-view'], function (exports, _mobilePageView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DESKTOP = 'desktop_page_view';

  exports.default = _mobilePageView.default.extend({
    category: DESKTOP
  });
});