define('rp-common/models/job-email', ['exports', 'ember-data', 'ember-cp-validations', 'rp-common/services/email-validation'], function (exports, _emberData, _emberCpValidations, _emailValidation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    applicant_email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', { type: 'email', regex: _emailValidation.EMAIL_REGEX, message: 'Invalid email format' })],
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    body: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    applicant_email: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    job_id: _emberData.default.attr('number')
  });
});